import {useCallback, useMemo} from 'react';
import {NotificationManager} from 'react-notifications';

import {useAppContext} from '../../app/context';
import {Button} from '../../components/bootstrap';
import CenteredErrorView from '../../components/CenteredErrorView';
import {Icon, Icons} from '../../components/Icon';
import {CardOrganizationInput, useCardOrganizations} from '../../components/inputs/OrganizationInput';
import Table from '../../components/Table';
import {ConfirmationPromiseModal, ConfirmationResult} from '../../modals/ConfirmationPromiseModal';
import ImportCSVModal, {ImportableField} from '../../modals/ImportCSVModal';
import {useModals} from '../../modals/ModalContext';
import {UserRights} from '../../models/AuthUser';
import {ICardSettingsWithTable} from '../../models/CardSettings';
import {Contract} from '../../models/Contract';
import {RFIDCard, RFIDCardProvider} from '../../models/RFIDCard';
import {isReadOnlyInOrganization, hasPartnerAdminFunctionality} from '../../models/User';
import {AppFeature, hasFeature} from '../../utils/AppParameters';
import {None} from '../../utils/Arrays';
import {useCardLoader} from '../../utils/Hooks';
import {plural, T} from '../../utils/Internationalization';
import {validateAtLeast, validateEmail, validateRFID} from '../../utils/Validation';
import {CardCategory, CardLocationAwareness, CardTypeKey, ICardProps, ICardType} from '../CardType';
import {useUser} from '../CardUtils';
import {CardActions, ColumnChooser} from '../components';
import {ExportCsv, Reload} from '../components/actions';
import {Spring} from '../components/CardActions';
import {CardView, cardViewProps, CustomSettings, ICardState} from '../components/CardView';

import ActivateEMSPModal from './ActivateEMSPModal';
import {getColumns} from './Columns';
import {CreateChargingTokenModal} from './CreateChargingTokenModal';
import {EditChargingTokenModal} from './EditChargingTokenModal';
import {ChargingCardEntry, getChargingCardEntries, getCreateCardError} from './model';
import OrderTokensModal from './OrderTokensModal';

interface PublicChargingTokensSettings extends ICardSettingsWithTable {
  organizationId?: number;
}

const chargingTokenRowKey = (entry: ChargingCardEntry) => entry.id;

function MyCard(props: ICardProps<PublicChargingTokensSettings>) {
  const {fetch, settings, updateSettings} = props;
  const {api} = useAppContext();
  const modals = useModals();
  const me = useUser();

  const [inputOrganizations, updateOrganizationInputQuery, organization] = useCardOrganizations(fetch, settings);

  const readOnly = isReadOnlyInOrganization(me, organization, inputOrganizations.organizations);

  const [chargingTokens, refreshChargingTokens] = useCardLoader<ChargingCardEntry[]>(
    api => {
      if (!organization) return Promise.resolve([]);

      const cards = api.organizations.getPublicChargingTokens(organization.id);
      const invitations = api.organizations.getPublicChargingTokenInvitations(organization.id);
      return Promise.all([cards, invitations]).then(([cards, invitations]) =>
        getChargingCardEntries(cards, invitations)
      );
    },
    [organization],
    plural('publicChargingToken'),
    None
  );

  const handleClickedAdd = () => {
    if (!organization) return;

    const contracts = organization.contracts || None;
    if (contracts.includes(Contract.eMSPBusiness)) {
      modals
        .show(props => <CreateChargingTokenModal organization={organization} {...props} />)
        .then(updated => updated && refreshChargingTokens());
    } else {
      modals
        .show(props => (
          <ActivateEMSPModal
            organizationId={organization.id}
            instantAvailable={contracts.includes(Contract.SEPA) && organization.linkedToOdoo}
            {...props}
          />
        ))
        .then(success => success && NotificationManager.success(T('publicChargingTokens.activate.success')));
    }
  };

  const handleClickedOrder = () => {
    if (!organization) return;

    api.organizations.getById(organization.id).then(organization => {
      const contracts = organization.contracts || None;
      if (contracts.includes(Contract.eMSPBusiness)) {
        modals.show(props => <OrderTokensModal organizationId={organization.id} {...props} />);
      } else {
        modals
          .show(props => (
            <ActivateEMSPModal
              organizationId={organization.id}
              instantAvailable={contracts.includes(Contract.SEPA) && organization.linkedToOdoo}
              {...props}
            />
          ))
          .then(success => success && T('publicChargingTokens.activate.success'));
      }
    });
  };

  const chargingTokenColumns = useMemo(() => {
    const handleClickedEdit = (item: RFIDCard) => {
      if (!organization) return;

      modals
        .show(props => <EditChargingTokenModal organizationId={organization.id} card={item} {...props} />)
        .then(updated => updated && refreshChargingTokens());
    };

    const handleClickedRemove = (item: RFIDCard) => {
      if (!organization) return;

      modals
        .show(props => (
          <ConfirmationPromiseModal
            title={T('publicChargingTokens.remove.title')}
            message={T('publicChargingTokens.remove.message', {
              token: item.value,
              name: item.name
            })}
            {...props}
          />
        ))
        .then(result => {
          if (result === ConfirmationResult.Accept) {
            const id = parseInt(item.id.substring(3));
            api.organizations.deletePublicChargingToken(organization.id, id).then(() => refreshChargingTokens());
          }
        });
    };

    const handleClickedDeleteInvitation = (id: string) => {
      if (!organization) return;

      modals
        .show(props => (
          <ConfirmationPromiseModal
            title={T('publicChargingTokens.deleteInvitation.title')}
            message={T('publicChargingTokens.deleteInvitation.message')}
            {...props}
          />
        ))
        .then(result => {
          if (result === ConfirmationResult.Accept) {
            api.organizations.deletePublicChargingTokenInvitation(organization.id, id).then(() => {
              refreshChargingTokens();
            });
          }
        });
    };

    return getColumns(readOnly, {
      onClickedEdit: handleClickedEdit,
      onClickedRemove: handleClickedRemove,
      onClickedDeleteInvitation: handleClickedDeleteInvitation
    });
  }, [api.organizations, modals, organization, readOnly, refreshChargingTokens]);

  const handleClickedImport = () => {
    if (!organization) return;

    const fields: ImportableField[] = [
      {
        field: 'name',
        label: 'Name',
        required: true,
        validator: validateAtLeast(1)
      },
      {
        field: 'employeeNumber',
        label: 'Employee Number',
        required: false
      },
      {
        field: 'email',
        label: 'Email',
        required: true,
        validator: validateEmail
      },
      {
        field: 'rfid',
        label: 'RFID',
        required: true,
        validator: validateRFID
      }
    ];

    const handleImport = (values: {[key: string]: string}[]) => {
      const createPromises = values.map((value, row) => {
        return api.organizations
          .createPublicChargingToken(organization.id, {
            name: value.name,
            employeeNumber: value.employeeNumber,
            userEmailAddress: value.email,
            value: value.rfid,
            provider: RFIDCardProvider.Smappee
          })
          .then(() => {
            return {status: 'ok', email: value.email};
          })
          .catch((err: unknown) => {
            const [field, message] = getCreateCardError(err, undefined, value.email);
            return {status: 'error', field, message, email: value.email};
          });
      });
      const promises = Promise.all(createPromises).then(results => {
        const successful = results.filter(result => result.status === 'ok').length;
        const errors = results.filter(result => result.status === 'error').length;
        const failedEmails = results.filter(result => result.status === 'error').map(result => result.email);
        if (successful > 0) {
          NotificationManager.success(T('publicChargingTokens.import.success', {entries: successful.toString()}));
        }
        if (errors > 0) {
          NotificationManager.error(
            T('publicChargingTokens.import.error', {entries: errors.toString(), emails: failedEmails.join(', ')})
          );
        }
      });

      return promises.then(() => undefined);
    };

    const skip = (entry: {[key: string]: string}) => {
      return chargingTokens.some(token => token.value === entry.rfid)
        ? T('publicChargingTokens.import.alreadyExists')
        : undefined;
    };

    modals
      .show(props => (
        <ImportCSVModal
          noun="publicChargingToken"
          info=""
          fields={fields}
          importer={handleImport}
          skip={skip}
          {...props}
        />
      ))
      .then(changed => changed && refreshChargingTokens());
  };

  const actions = (state: ICardState) => (
    <CardActions>
      <CardOrganizationInput
        inputOrganizations={inputOrganizations}
        organization={organization}
        updateSettings={updateSettings}
        updateOrganizationInputQuery={updateOrganizationInputQuery}
        style={{marginRight: 8}}
      />
      <Reload onReload={refreshChargingTokens} />
      <ExportCsv
        items={chargingTokens}
        fields={chargingTokenColumns}
        settings={settings.table}
        name="public-charging-tokens"
      />
      <Button variant="secondary_default" onClick={handleClickedImport} className="tw-gap-2">
        {Icons.FileImport} {T('chargingRFIDWhitelist.import')}
      </Button>

      <Spring />
      {(me.isServiceDesk() || me.isPartnerAdmin()) && (
        <>
          <Button onClick={handleClickedAdd}>
            <i className={Icon.Plus} style={{fontSize: 16, padding: 0}} />
            &nbsp;{T('publicChargingTokens.add')}
          </Button>
          <Button onClick={handleClickedOrder} style={{marginLeft: '0.5rem'}}>
            {T('publicChargingTokens.orderCards')}
          </Button>
        </>
      )}
    </CardActions>
  );

  const customSettings: CustomSettings<PublicChargingTokensSettings> = (editingSettings, updateEditingSettings) => {
    return (
      <ColumnChooser
        fields={chargingTokenColumns}
        settings={editingSettings.table}
        updateSettings={table => updateEditingSettings({table})}
      />
    );
  };

  return (
    <CardView actions={actions} customSettings={customSettings} {...cardViewProps(props)}>
      {chargingTokens.length === 0 ? (
        <CenteredErrorView>
          {T('generic.noDataOfType', {
            entity: plural('publicChargingToken')
          })}
          {(me.isServiceDesk() || me.isPartnerAdmin()) && (
            <>
              <br />
              <br />
              <Button onClick={handleClickedAdd} color="primary">
                <i className={Icon.Plus} style={{fontSize: 16, padding: 0}} />
                &nbsp;{T('publicChargingTokens.add')}
              </Button>
            </>
          )}
        </CenteredErrorView>
      ) : (
        <Table
          items={chargingTokens}
          fields={chargingTokenColumns}
          rowKey={chargingTokenRowKey}
          settings={settings.table}
          updateSettings={table => updateSettings({table})}
          noun="publicChargingToken"
        />
      )}
    </CardView>
  );
}

const DefaultSettings: PublicChargingTokensSettings = {
  table: {
    pageSize: 10,
    columns: [
      {name: 'value', visible: true},
      {name: 'name', visible: true},
      {name: 'employeeNumber', visible: true},
      ...(hasFeature(AppFeature.SocialLogin)
        ? [
            {name: 'firstName', visible: true},
            {name: 'lastName', visible: true},
            {name: 'confirmed', visible: true}
          ]
        : []),
      ...(hasFeature(AppFeature.HideUsernames) ? [] : [{name: 'username', visible: true}])
    ]
  }
};

const CARD: ICardType<PublicChargingTokensSettings> = {
  type: CardTypeKey.PublicChargingTokens,
  title: 'publicChargingTokens.title',
  description: 'publicChargingTokens.description',
  locationAware: CardLocationAwareness.Unaware,
  categories: [CardCategory.EV, CardCategory.CONFIGURATION],
  rights: UserRights.User,
  isAvailable: hasPartnerAdminFunctionality,
  width: 2,
  height: 2,
  defaultSettings: DefaultSettings,
  cardClass: MyCard
};
export default CARD;
