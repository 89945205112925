import React, {useState, useEffect, useMemo} from 'react';

import {Label, Input, FormGroup} from '../../components/bootstrap';

import PeriodSelector, {
  PeriodSettings,
  Period,
  getPeriodRangeForTimezone,
  PeriodRoundingMode
} from '../../components/PeriodSelector';

import {IPersistedTableSettings, SortOrder} from '../../components/Table';
import {Checkbox} from '../../components/ui/checkbox';
import {SelectInput} from '../../components/ui/select';
import {UserRights} from '../../models/AuthUser';
import {CardDisplayType} from '../../models/CardSettings';
import {ICardSettings} from '../../models/CardSettings';
import {DeviceType} from '../../models/DeviceType';
import {HarmonicsInterval} from '../../models/Harmonics';
import {IHighLevelConfiguration, PhaseType} from '../../models/HighLevelConfiguration';
import {getLoadName} from '../../models/Load';
import {ILocationSummary} from '../../models/Location';
import {Phase, getPhaseIndex} from '../../models/Phase';
import {getLimitsFromRetention, IRetentionPolicy} from '../../models/RetentionPolicy';
import {ITableField, CalculatedNumberField, TimestampFieldWithTimezone} from '../../models/Table';
import {Interval} from '../../models/UsageValue';
import {mergeSortedArrays, None} from '../../utils/Arrays';
import {useHighLevelConfiguration, useLocationActivationCode, useRetentionPolicy} from '../../utils/FunctionalData';
import {useCardLoader} from '../../utils/Hooks';
import {T, rank} from '../../utils/Internationalization';
import {ICardType, CardCategory, CardTypeKey, CardLocationAwareness, ICardProps} from '../CardType';
import {useCardLocation} from '../CardUtils';
import {CardActions} from '../components';
import {Reload, ExportCsv} from '../components/actions';
import {Spring} from '../components/CardActions';
import {CustomSettings, CardView, cardViewProps, CustomActions} from '../components/CardView';
import {CardTypeSelector} from '../components/settings/CardTypeSelector';

import {HarmonicsStatisticsChart, HarmonicsHistoryChart, HarmonicHistoryColorScheme} from './HarmonicsChart';
import {calculateStatistics, extractLoad} from './HarmonicsStatistics';

const DISPLAY_TYPE_OPTIONS = [CardDisplayType.Statistics, CardDisplayType.Chart];

interface HarmonicsSettings extends ICardSettings, PeriodSettings {
  loadId?: number;
  harmonics?: number[];
  displayType: CardDisplayType;
  showVoltages: boolean;
  showCurrents: boolean;
  colorScheme: ColorScheme;
}

const VoltageColors = {
  [Phase.L1]: '#5F3711', // brown
  [Phase.L2]: '#000000', // black
  [Phase.L3]: '#A9B0B3' // gray
};
const CurrentColors = {
  [Phase.L1]: '#f28f43', // orange
  [Phase.L2]: '#c42525', // red
  [Phase.L3]: '#1aadce' // turquise
};
const HighchartColors = [
  '#7cb5ec',
  '#434348',
  '#90ed7d',
  '#f7a35c',
  '#8085e9',
  '#f15c80',
  '#e4d354',
  '#2b908f',
  '#f45b5b',
  '#91e8e1'
];
const HighchartAdjustedColors = [
  ['#7cb5ec', '#134D85', '#A5CCF2'],
  ['#434348', '#333337', '#76767E'],
  ['#90ed7d', '#3DD81E', '#BAF4AF'],
  ['#f7a35c', '#EA720C', '#FBD1AD'],
  ['#8085e9', '#6067E4', '#9296EC'],
  ['#f15c80', '#EB1549', '#F4829D'],
  ['#e4d354', '#A6961A', '#ECE088'],
  ['#2b908f', '#1B5A59', '#37B8B7'],
  ['#f45b5b', '#DD0F0F', '#F78D8D'],
  ['#91e8e1', '#29C2B5', '#AFEEE9']
];
const DefaultColorScheme: HarmonicHistoryColorScheme = {
  getCurrentTHDColor: phase => undefined,
  getVoltageTHDColor: phase => undefined,
  getCurrentColor: (phase, harmonic, index) => undefined,
  getVoltageColor: (phase, harmonic, index) => undefined
};
const PhaseColorScheme: HarmonicHistoryColorScheme = {
  getCurrentTHDColor: phase => CurrentColors[phase],
  getVoltageTHDColor: phase => VoltageColors[phase],
  getCurrentColor: (phase, harmonic, index) => CurrentColors[phase],
  getVoltageColor: (phase, harmonic, index) => VoltageColors[phase]
};
const HarmonicColorScheme: HarmonicHistoryColorScheme = {
  getCurrentTHDColor: phase => HighchartColors[0],
  getVoltageTHDColor: phase => HighchartColors[0],
  getCurrentColor: (phase, harmonic, index) => HighchartColors[(index + 1) % HighchartColors.length],
  getVoltageColor: (phase, harmonic, index) => HighchartColors[(index + 1) % HighchartColors.length]
};
const ModulatedScheme: HarmonicHistoryColorScheme = {
  getCurrentTHDColor: phase => HighchartAdjustedColors[0][getPhaseIndex(phase)],
  getVoltageTHDColor: phase => HighchartAdjustedColors[0][getPhaseIndex(phase)],
  getCurrentColor: (phase, harmonic, index) =>
    HighchartAdjustedColors[(index + 1) % HighchartColors.length][getPhaseIndex(phase)],
  getVoltageColor: (phase, harmonic, index) =>
    HighchartAdjustedColors[(index + 1) % HighchartColors.length][getPhaseIndex(phase)]
};

enum ColorScheme {
  Default = 'default',
  ByPhase = 'byphase',
  ByHarmonic = 'byharmonic',
  Modulated = 'modulated'
}
function getColorScheme(scheme: ColorScheme): HarmonicHistoryColorScheme {
  switch (scheme) {
    case ColorScheme.ByPhase:
      return PhaseColorScheme;
    case ColorScheme.ByHarmonic:
      return HarmonicColorScheme;
    case ColorScheme.Modulated:
      return ModulatedScheme;
    default:
      return DefaultColorScheme;
  }
}

function extractHarmonic(
  interval: HarmonicsInterval,
  loadId: number,
  harmonic: number,
  currentOrVoltage: 'current' | 'voltage',
  phase: 'phaseA' | 'phaseB' | 'phaseC'
) {
  var load = interval.loads.find(load => load.id === loadId);
  if (!load) return undefined;
  var loadPhase = load[phase];
  if (!loadPhase) return undefined;
  var current = loadPhase[currentOrVoltage];
  if (!current) return undefined;

  var specific = current.find(entry => entry.index === harmonic);
  return specific && specific.value;
}

function getCSVSettingsFromSettings(
  settings: HarmonicsSettings,
  location: ILocationSummary | undefined,
  highLevelConfiguration: IHighLevelConfiguration | undefined,
  availableHarmonics: number[]
): [ITableField<HarmonicsInterval>[], IPersistedTableSettings] {
  const {harmonics = availableHarmonics, showCurrents = true, showVoltages = true, loadId} = settings;

  const columns: ITableField<HarmonicsInterval>[] = [];
  if (location) {
    columns.push(new TimestampFieldWithTimezone('timestamp', 'timestamp', 'timestamp', location.timeZoneId));
  }
  if (loadId !== undefined && highLevelConfiguration !== undefined) {
    const load = highLevelConfiguration.measurements.find(x => x.id === loadId);
    const channels = load ? load.actuals : [];
    const phases = channels.map(channel => channel.phase);
    if (showCurrents) {
      phases.includes(Phase.L1) &&
        columns.push(
          new CalculatedNumberField(`THDL1i`, `Total harmonic distortion L1, current`, interval =>
            extractHarmonic(interval, loadId, 0, 'current', 'phaseA')
          )
        );
      phases.includes(Phase.L2) &&
        columns.push(
          new CalculatedNumberField(`THDL2i`, `Total harmonic distortion L2, current`, interval =>
            extractHarmonic(interval, loadId, 0, 'current', 'phaseB')
          )
        );
      phases.includes(Phase.L3) &&
        columns.push(
          new CalculatedNumberField(`THDL3i`, `Total harmonic distortion L3, current`, interval =>
            extractHarmonic(interval, loadId, 0, 'current', 'phaseC')
          )
        );
    }
    if (showVoltages) {
      phases.includes(Phase.L1) &&
        columns.push(
          new CalculatedNumberField(`THDL1u`, `Total harmonic distortion L1, voltage`, interval =>
            extractHarmonic(interval, loadId, 0, 'voltage', 'phaseA')
          )
        );
      phases.includes(Phase.L2) &&
        columns.push(
          new CalculatedNumberField(`THDL2u`, `Total harmonic distortion L2, voltage`, interval =>
            extractHarmonic(interval, loadId, 0, 'voltage', 'phaseB')
          )
        );
      phases.includes(Phase.L3) &&
        columns.push(
          new CalculatedNumberField(`THDL3u`, `Total harmonic distortion L3, voltage`, interval =>
            extractHarmonic(interval, loadId, 0, 'voltage', 'phaseC')
          )
        );
    }

    harmonics.forEach(harmonic => {
      if (showCurrents) {
        phases.includes(Phase.L1) &&
          columns.push(
            new CalculatedNumberField(`H${harmonic}L1i`, `Harmonic ${harmonic} L1, current`, interval =>
              extractHarmonic(interval, loadId, harmonic, 'current', 'phaseA')
            )
          );
        phases.includes(Phase.L2) &&
          columns.push(
            new CalculatedNumberField(`H${harmonic}L2i`, `Harmonic ${harmonic} L2, current`, interval =>
              extractHarmonic(interval, loadId, harmonic, 'current', 'phaseB')
            )
          );
        phases.includes(Phase.L3) &&
          columns.push(
            new CalculatedNumberField(`H${harmonic}L3i`, `Harmonic ${harmonic} L3, current`, interval =>
              extractHarmonic(interval, loadId, harmonic, 'current', 'phaseC')
            )
          );
      }
      if (showVoltages) {
        phases.includes(Phase.L1) &&
          columns.push(
            new CalculatedNumberField(`H${harmonic}L1u`, `Harmonic ${harmonic} L1, voltage`, interval =>
              extractHarmonic(interval, loadId, harmonic, 'voltage', 'phaseA')
            )
          );
        phases.includes(Phase.L2) &&
          columns.push(
            new CalculatedNumberField(`H${harmonic}L2u`, `Harmonic ${harmonic} L2, voltage`, interval =>
              extractHarmonic(interval, loadId, harmonic, 'voltage', 'phaseB')
            )
          );
        phases.includes(Phase.L3) &&
          columns.push(
            new CalculatedNumberField(`H${harmonic}L3u`, `Harmonic ${harmonic} L3, voltage`, interval =>
              extractHarmonic(interval, loadId, harmonic, 'voltage', 'phaseC')
            )
          );
      }
    });
  }
  const tableSettings: IPersistedTableSettings = {
    pageSize: 10,
    columns: columns.map(c => ({name: c.name, visible: true})),
    sortColumn: 'timestamp',
    sortOrder: SortOrder.DESCENDING
  };
  return [columns, tableSettings];
}

function getStatisticsInterval(retention: IRetentionPolicy, from: number, to: number): Interval {
  if (to - from <= 30 * 24 * 3600 * 1000) {
    const {minTime} = getLimitsFromRetention(Interval.MINUTES_5, retention);
    if (minTime === undefined || from >= minTime.valueOf()) {
      return Interval.MINUTES_5;
    }
  }

  {
    const {minTime} = getLimitsFromRetention(Interval.HOUR, retention);
    if (minTime === undefined || from >= minTime.valueOf()) {
      return Interval.HOUR;
    }
  }

  return Interval.DAY;
}

const Harmonics = (props: ICardProps<HarmonicsSettings>) => {
  const {fetch, settings, updateSettings} = props;

  const {displayType, harmonics, showCurrents, showVoltages, loadId, colorScheme} = settings;

  const location = useCardLocation(settings);
  const locationId = location && location.id;
  const locationTimezone = location && location.timeZoneId;

  const [highLevelConfiguration] = useHighLevelConfiguration(fetch, locationId);
  const [activationCode] = useLocationActivationCode(fetch, locationId);
  const [retentionPolicy] = useRetentionPolicy(fetch, locationId);

  const hasHarmonics =
    activationCode !== undefined &&
    ((activationCode.currentHarmonics || []).length > 0 || (activationCode.voltageHarmonics || []).length > 0);

  const [data, refreshData] = useCardLoader(
    api => {
      if (
        !locationId ||
        !activationCode ||
        !loadId ||
        !hasHarmonics ||
        retentionPolicy === undefined ||
        locationTimezone === undefined
      ) {
        return Promise.resolve(undefined);
      }

      const activePeriod = getPeriodRangeForTimezone(
        settings,
        locationTimezone,
        retentionPolicy,
        PeriodRoundingMode.EXCLUSIVE
      );
      const {from: from2, to: to2} = getPeriodRangeForTimezone(
        settings,
        locationTimezone,
        undefined,
        PeriodRoundingMode.EXCLUSIVE
      );
      const interval =
        settings.displayType === CardDisplayType.Statistics
          ? getStatisticsInterval(retentionPolicy, from2, to2)
          : settings.interval;
      return api.getHarmonicsData(locationId, [loadId], activePeriod.from, activePeriod.to, interval).then(data => {
        const availableHarmonics = mergeSortedArrays(
          activationCode.currentHarmonics || [],
          activationCode.voltageHarmonics || []
        );
        return {
          intervals: data.intervals || None,
          availableHarmonics,
          period: {...activePeriod, interval: data.intervalLength}
        };
      });
    },
    [
      settings.from,
      settings.to,
      settings.period,
      settings.interval,
      settings.displayType,
      activationCode,
      hasHarmonics,
      locationId,
      locationTimezone,
      loadId
    ],
    T('harmonics.data'),
    undefined
  );

  useEffect(() => {
    if (!highLevelConfiguration) return;

    const {measurements: loads} = highLevelConfiguration;
    let load = loads.find(load => load.id === loadId);
    if (!load && loads.length > 0) updateSettings({loadId: loads[0].id});
  }, [loadId, highLevelConfiguration, updateSettings]);

  const handleSelectedLoadChanged = (value: string) => {
    updateSettings({loadId: parseInt(value)});
  };

  const handleChangePeriod = (settings: PeriodSettings) => {
    updateSettings(settings);
  };

  const renderSettings: CustomSettings<HarmonicsSettings> = (settings, updateSettings) => {
    const availableHarmonics = data?.availableHarmonics || None;
    const {harmonics = availableHarmonics, displayType, showVoltages, showCurrents, colorScheme} = settings;

    const handleHarmonicsSwitched = (harmonicIndex: number, checked: boolean) => {
      if (checked) {
        if (!harmonics.includes(harmonicIndex)) {
          const newHarmonics = [...harmonics, harmonicIndex];
          newHarmonics.sort();
          updateSettings({harmonics: newHarmonics});
        }
      } else {
        const index = harmonics.indexOf(harmonicIndex);
        if (index >= 0) {
          const newHarmonics = [...harmonics];
          newHarmonics.splice(index, 1);
          updateSettings({harmonics: newHarmonics});
        }
      }
    };

    const handleVoltagesSwitched = (checked: boolean) => {
      updateSettings({showVoltages: checked});
    };

    const handleCurrentsSwitched = (checked: boolean) => {
      updateSettings({showCurrents: checked});
    };

    const handleEditingCardTypeChanged = (type: CardDisplayType) => {
      updateSettings({displayType: type});
    };

    const handleColorSchemeChanged = (value: string) => {
      updateSettings({colorScheme: value as ColorScheme});
    };

    const harmonicsSelections = data?.availableHarmonics.map(harmonicIndex => (
      <FormGroup key={harmonicIndex}>
        <Checkbox
          id={`harmonic-${harmonicIndex}`}
          name={`harmonic-${harmonicIndex}`}
          label={T('liveHarmonics.xAxisLabel', {rank: rank(harmonicIndex)})}
          checked={harmonics.includes(harmonicIndex)}
          onCheckedChange={checked => handleHarmonicsSwitched(harmonicIndex, checked)}
          wrapperClassName="tw-mb-0"
          testId={`harmonic-${harmonicIndex}`}
        />
      </FormGroup>
    ));
    return (
      <div>
        <CardTypeSelector value={displayType} onChange={handleEditingCardTypeChanged} options={DISPLAY_TYPE_OPTIONS} />
        <FormGroup>
          <Label for="cardType">{T('harmonics.colorScheme')}</Label>
          <SelectInput
            name="colorScheme"
            value={colorScheme}
            onChange={handleColorSchemeChanged}
            options={[
              {value: ColorScheme.Default, label: T('harmonics.colorScheme.default')},
              {value: ColorScheme.ByPhase, label: T('harmonics.colorScheme.byPhase')},
              {value: ColorScheme.ByHarmonic, label: T('harmonics.colorScheme.byHarmonic')},
              {value: ColorScheme.Modulated, label: T('harmonics.colorScheme.modulated')}
            ]}
          />
        </FormGroup>
        <h4>{T('harmonics.chartedHarmonics')}</h4>
        <FormGroup>
          <Checkbox
            id="show-voltages"
            name="show-voltages"
            label={T('liveHarmonics.showVoltages')}
            checked={showVoltages}
            onCheckedChange={checked => handleVoltagesSwitched(checked)}
            testId="show-voltages"
          />
        </FormGroup>
        <FormGroup>
          <Checkbox
            id="switch-current"
            name="switch-current"
            label={T('liveHarmonics.showCurrents')}
            checked={showCurrents}
            onCheckedChange={handleCurrentsSwitched}
            testId="switch-current"
          />
        </FormGroup>
        {displayType === CardDisplayType.Chart && harmonicsSelections}
      </div>
    );
  };

  const {measurements: loads = None, phaseType = PhaseType.Star} = highLevelConfiguration || {};

  const [exportCSVColumns, exportCSVSettings] = getCSVSettingsFromSettings(
    settings,
    location,
    highLevelConfiguration,
    data?.availableHarmonics || None
  );

  const actions: CustomActions = state => (
    <CardActions>
      <PeriodSelector
        settings={settings}
        onChanged={handleChangePeriod}
        retention={retentionPolicy}
        withoutInterval={settings.displayType === CardDisplayType.Statistics}
      />
      <Reload onReload={refreshData} />
      {state.ready && data && (
        <ExportCsv
          fields={exportCSVColumns}
          settings={exportCSVSettings}
          items={data.intervals || []}
          name="harmonics"
          range={settings}
          location={location}
        />
      )}
      <Spring />
      {state.ready && <Label>{T('phasorDisplay.load')}</Label>}
      {state.ready && (
        <SelectInput
          name="load"
          className="tw-w-[200px]"
          value={loadId?.toString() || ''}
          onChange={handleSelectedLoadChanged}
          options={loads
            .filter(load => load.updateChannels)
            .map(load => ({
              value: load.id.toString(),
              label: getLoadName(load)
            }))}
        />
      )}
    </CardActions>
  );

  let error: string | undefined;
  const {serialNumber = undefined, deviceType = undefined} = location || {};
  if (!serialNumber || !deviceType) {
    error = T('harmonics.notInstalled');
  } else if (deviceType !== DeviceType.Genius) {
    error = T('harmonics.notSupported');
  } else if (!hasHarmonics) {
    error = T('harmonics.notEnabledForLocation');
  }

  const filteredData = useMemo(() => {
    if (data === undefined || loadId === undefined) return;

    return extractLoad(data.intervals, loadId, data.availableHarmonics);
  }, [data, loadId]);
  const statistics = useMemo(() => {
    if (data === undefined || loadId === undefined) return undefined;

    return calculateStatistics(data.intervals, loadId);
  }, [data, loadId]);

  const load = highLevelConfiguration && highLevelConfiguration.measurements.find(x => x.id === loadId);

  return (
    <CardView
      ready={data !== undefined}
      actions={actions}
      customSettings={renderSettings}
      error={error}
      {...cardViewProps(props)}
    >
      {data &&
        (displayType === CardDisplayType.Statistics
          ? statistics &&
            load && (
              <HarmonicsStatisticsChart
                phaseType={phaseType}
                load={load}
                showCurrents={showCurrents}
                showVoltages={showVoltages}
                statistics={statistics}
                availableHarmonics={data.availableHarmonics}
              />
            )
          : filteredData &&
            load && (
              <HarmonicsHistoryChart
                phaseType={phaseType}
                load={load}
                showCurrents={showCurrents}
                showVoltages={showVoltages}
                harmonics={filteredData}
                showHarmonics={harmonics || data.availableHarmonics}
                colorScheme={getColorScheme(colorScheme)}
                timezone={locationTimezone || 'UTC'}
              />
            ))}
    </CardView>
  );
};

const DEFAULT_SETTINGS = {
  period: Period.TODAY,
  interval: Interval.MINUTES_5,
  displayType: CardDisplayType.Statistics,
  showVoltages: true,
  showCurrents: true,
  colorScheme: ColorScheme.Modulated
};
const CARD: ICardType<HarmonicsSettings> = {
  type: CardTypeKey.Harmonics,
  title: 'harmonics.title',
  description: 'harmonics.description',
  categories: [CardCategory.ELECTRICITY],
  rights: UserRights.User,
  width: 2,
  height: 2,
  defaultSettings: DEFAULT_SETTINGS,
  locationAware: CardLocationAwareness.RequiresRegular,
  cardClass: Harmonics
};
export default CARD;
