import React, {useState, useMemo, useEffect} from 'react';

import {useAppContext} from '../../app/context';
import {Button as RsButton, Form, FormGroup, Input, Label} from '../../components/bootstrap';
import FormInputGroup from '../../components/inputs/FormInputGroup';
import {SelectInput} from '../../components/ui/select';
import {IAppliance} from '../../models/Appliance';
import {UserRights} from '../../models/AuthUser';
import {Automation} from '../../models/Automation';
import {ICardSettings} from '../../models/CardSettings';
import {IDevice} from '../../models/Device';
import {getDeviceTypeLabelFor} from '../../models/DeviceType';
import {ControllableNodeType} from '../../models/HomeControlDevice';
import {MessageType, IPushMessageContext} from '../../models/PushMessage';
import {AppFeature, hasFeature} from '../../utils/AppParameters';
import {useLocationUsers} from '../../utils/FunctionalData';
import {plural} from '../../utils/Internationalization';
import {ICardType, CardCategory, CardTypeKey, CardLocationAwareness, ICardProps} from '../CardType';
import {useCardLocationId} from '../CardUtils';
import {cardViewProps, CardView} from '../components/CardView';

enum DeviceType {
  SmartDevice = 'SMART_DEVICE',
  ComfortPlug = 'COMFORT_PLUG',
  SmappeeSwitch = 'SMAPPEE_SWITCH',
  CarCharger = 'CAR_CHARGER',
  Thermostat = 'THERMOSTAT',
  Battery = 'BATTERY',
  OutputModuleControl = 'OUTPUT_MODULE_CONTROL'
}

const SendPushMessage = (props: ICardProps<ICardSettings>) => {
  const {fetch, settings} = props;
  const {api} = useAppContext();
  const locationId = useCardLocationId(settings);

  const [message, setMessage] = useState('');
  const [selectedUser, setSelectedUser] = useState('ALL_USERS');
  const [loadingMessage, setLoadingMessage] = useState('');

  const [type, setType] = useState<MessageType>(MessageType.Custom);
  const [value, setValue] = useState('');
  const [valueId, setValueId] = useState('');
  const [serialNumber, setSerialNumber] = useState('');

  const [appliances, setAppliances] = useState<IAppliance[]>();
  const [smartDevices, setSmartDevices] = useState<{id: string; name: string; type: string}[]>();
  const [modules, setModules] = useState<IDevice[]>();
  const [automations, setAutomations] = useState<Automation[]>([]);

  const [locationUsers] = useLocationUsers(fetch, locationId);

  useEffect(() => {
    if (!locationId) return;

    if (type === MessageType.ApplianceDetail) {
      fetch('appliances', api => api.getAppliances(locationId), plural('appliance')).then(appliances => {
        setAppliances(appliances);
        setValueId(appliances.length === 0 ? '' : appliances[0].id);
        setValue(appliances.length === 0 ? '' : appliances[0].type);
      });
    } else if (type === MessageType.SmartCharging || type === MessageType.SmartDeviceDetail) {
      const smartDevices = fetch('smartcharging', api => api.getSmartDevices(locationId), plural('smartDevice'));
      const homeControl = fetch('homecontrol', api => api.getHomeControlDevices(locationId), plural('switchLeaf'));
      Promise.all([smartDevices, homeControl]).then(([devices, homeControl]) => {
        const controllableNodes = homeControl.controllableNodes.map(node => ({
          id: node.id.toString(),
          name: node.name,
          type: node.type === ControllableNodeType.SmappeeSwitch ? DeviceType.SmappeeSwitch : DeviceType.ComfortPlug
        }));
        const batteries = homeControl.batteries.map(battery => ({
          id: battery.id.toString(),
          name: battery.name,
          type: DeviceType.Battery
        }));
        const thermostats = homeControl.thermostats.map(thermostat => ({
          id: thermostat.id.toString(),
          name: thermostat.name,
          type: DeviceType.Thermostat
        }));
        const outputModuleControls = homeControl.outputModuleControls.map(control => ({
          id: control.id.toString(),
          name: control.name,
          type: DeviceType.OutputModuleControl
        }));
        const smartDevices = devices.map(device => ({
          id: device.id,
          name: device.name,
          type: device.type.category as unknown as DeviceType
        }));
        const allDevices = smartDevices.concat(controllableNodes, batteries, thermostats, outputModuleControls);
        allDevices.sort((a, b) => a.name.localeCompare(b.name));

        setSmartDevices(allDevices);
        setValueId(allDevices.length === 0 ? '' : allDevices[0].id);
      });
    } else if (type === MessageType.AutomationDetail || type === MessageType.SceneDetail) {
      fetch('automations', api => api.getAutomations(locationId), plural('automation')).then(automations => {
        setAutomations(automations);
        setValueId(automations.length === 0 ? '' : automations[0].id.toString());
      });
    } else if (type === MessageType.InfinityModuleDetail) {
      fetch('infinity', api => api.locations.getModules(locationId), plural('module')).then(modules => {
        setModules(modules);
        setSerialNumber(modules.length === 0 ? '' : modules[0].serialNumber);
      });
    }
  }, [locationId, type, fetch]);

  const handleUserChange = (value: string) => {
    setSelectedUser(value);
  };

  const handleMessageChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
    setMessage(e.currentTarget.value);
  };

  const handleTypeChanged = (value: string) => {
    setType(value as MessageType);
  };

  const handleSelectValueChanged = (value: string) => {
    setValue(value);
  };

  const handleValueChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
    setValue(e.currentTarget.value);
  };

  const handleValueIdChanged = (value: string) => {
    setValueId(value);

    // move to effect?
    if (type === MessageType.ApplianceDetail) {
      const appliance = (appliances || []).find(appliance => appliance.id === valueId);
      if (appliance) {
        setValueId(appliance.id);
        setValue(appliance.type);
      }
    } else if (type === MessageType.SmartDeviceDetail) {
      const smartDevice = (smartDevices || []).find(device => device.id === valueId);
      if (smartDevice) {
        setValue(smartDevice.type);
      }
    }
  };

  const handleSerialNumberChanged = (value: string) => {
    setSerialNumber(value);
  };

  const isValid = message.trim().length !== 0;

  const handleClickedSend = () => {
    setLoadingMessage('Sending message...');

    if (!locationId) return;

    let context: IPushMessageContext | undefined;
    if (type !== MessageType.Custom) {
      context = {
        notificationType: type,
        notificationValue: value,
        notificationValueId: valueId,
        notificationSerialNumber: serialNumber
      };
    }

    api
      .sendInAppMessage(locationId, message, selectedUser === 'ALL_USERS' ? undefined : selectedUser, context)
      .then(() => {
        setLoadingMessage('Message sent');
        setMessage('');
      });
  };

  const userOptions = useMemo(() => {
    return [
      {label: 'All service location users', value: 'ALL_USERS'},
      ...locationUsers.map(user => ({
        label: hasFeature(AppFeature.SocialLogin) ? user.emailAddress : user.userName,
        value: hasFeature(AppFeature.SocialLogin) ? user.id.toString() : user.userName
      }))
    ];
  }, [locationUsers]);
  return (
    <CardView {...cardViewProps(props)}>
      <Form style={{overflowY: 'scroll'}}>
        <FormInputGroup name="user" label="User">
          <SelectInput value={selectedUser} onChange={handleUserChange} options={userOptions} />
        </FormInputGroup>

        <FormInputGroup name="context" label="Context">
          <SelectInput
            value={type}
            onChange={handleTypeChanged}
            options={[
              {label: 'No context', value: MessageType.Custom},
              {label: 'Dashboard', value: MessageType.Dashboard},
              {label: 'Survey', value: MessageType.Survey},
              {label: 'Usages', value: MessageType.Usages},
              {label: 'Tariffs', value: MessageType.Tariffs},
              {label: 'Appliances', value: MessageType.Appliances},
              {label: 'Appliance detail', value: MessageType.ApplianceDetail},
              {label: 'Control', value: MessageType.Control},
              {label: 'Smart devices', value: MessageType.SmartDevices},
              {label: 'Smart device detail', value: MessageType.SmartDeviceDetail},
              {label: 'Smart charging', value: MessageType.SmartCharging},
              {label: 'Automations', value: MessageType.Automations},
              {label: 'Automation detail', value: MessageType.AutomationDetail},
              {label: 'Events', value: MessageType.Events},
              {label: 'Notifications', value: MessageType.Notifications},
              {label: 'Scoreboard', value: MessageType.Scoreboard},
              {label: 'Location detail', value: MessageType.LocationDetail},
              {label: 'App store', value: MessageType.AppStore},
              {label: 'Subscription', value: MessageType.Subscription},
              {label: 'Load configuration', value: MessageType.LoadConfiguration},
              {label: 'Infinity modules', value: MessageType.InfinityModules},
              {label: 'Infinity module detail', value: MessageType.InfinityModuleDetail},
              {label: 'Scenes', value: MessageType.Scenes},
              {label: 'Scene detail', value: MessageType.SceneDetail}
            ]}
          />
        </FormInputGroup>

        {type === MessageType.ApplianceDetail && (
          <FormInputGroup name="appliance" label="Appliance">
            <SelectInput
              value={valueId}
              onChange={handleValueIdChanged}
              options={(appliances || []).map(appliance => ({
                label: appliance.name,
                value: appliance.id
              }))}
            />
          </FormInputGroup>
        )}
        {type === MessageType.SmartDeviceDetail && (
          <FormInputGroup name="smart-device" label="Smart device">
            <SelectInput
              value={valueId}
              onChange={handleValueIdChanged}
              options={(smartDevices || []).map(device => ({
                label: device.name,
                value: device.id
              }))}
            />
          </FormInputGroup>
        )}
        {type === MessageType.InfinityModuleDetail && (
          <FormInputGroup name="module" label="Module">
            <SelectInput
              value={serialNumber}
              onChange={handleSerialNumberChanged}
              options={(modules || []).map(module => ({
                label: `${module.serialNumber} (${getDeviceTypeLabelFor(module.type)})`,
                value: module.id as any
              }))}
            />
          </FormInputGroup>
        )}
        {type === MessageType.Usages && (
          <FormInputGroup name="kind" label="Kind">
            <SelectInput
              value={value}
              onChange={handleSelectValueChanged}
              options={[
                {label: 'Gas', value: 'GAS'},
                {label: 'Water', value: 'WATER'},
                {label: 'Electricity', value: 'ELECTRICITY'},
                {label: 'Solar', value: 'SOLAR'}
              ]}
            />
          </FormInputGroup>
        )}
        {type === MessageType.Notifications && (
          <FormInputGroup name="url" label="URL to open when clicked">
            <Input type="text" value={value} onChange={handleValueChanged} />
          </FormInputGroup>
        )}
        {type === MessageType.AutomationDetail && (
          <FormInputGroup name="automation" label="Automation">
            <SelectInput
              value={valueId}
              onChange={handleValueIdChanged}
              options={automations.map(automation => ({
                label: automation.name,
                value: automation.id.toString()
              }))}
            />
          </FormInputGroup>
        )}

        <FormInputGroup name="message" label="Message">
          <Input
            type="textarea"
            name="message"
            rows="6"
            cols="64"
            maxLength={1000}
            value={message}
            onChange={handleMessageChanged}
          />
        </FormInputGroup>

        <FormGroup>
          <Label />
          <RsButton onClick={handleClickedSend} disabled={!isValid}>
            Send in-app message
          </RsButton>
        </FormGroup>
        {loadingMessage && <div>{loadingMessage}</div>}
      </Form>
    </CardView>
  );
};

const CARD: ICardType<ICardSettings> = {
  type: CardTypeKey.SendPushMessage,
  title: 'sendPushMessage.title',
  description: 'sendPushMessage.description',
  categories: [CardCategory.SERVICEDESK],
  rights: UserRights.ServiceDesk,
  width: 2,
  height: 2,
  defaultSettings: {},
  locationAware: CardLocationAwareness.Aware,
  cardClass: SendPushMessage
};
export default CARD;
