import {useMemo, useState} from 'react';

import {NotificationManager} from 'react-notifications';

import {useFluviusCampaigns} from '../../../api/FluviusAPI';
import {useAppContext} from '../../../app/context';
import {Button as RsButton} from '../../../components/bootstrap';
import Table, {migrateTableSettings} from '../../../components/Table';
import {SelectInput} from '../../../components/ui/select';
import {ConfirmationPromiseModal, ConfirmationResult} from '../../../modals/ConfirmationPromiseModal';
import {useModals} from '../../../modals/ModalContext';
import {UserRights} from '../../../models/AuthUser';
import {ICardSettingsWithTable} from '../../../models/CardSettings';
import {
  FluviusCampaignConfiguration,
  FluviusCampaignPDFRequest,
  FluviusCampaignStatus,
  IFluviusCampaign,
  IFluviusCampaignFields
} from '../../../models/FluviusMeasurementCampaign';
import {IMeasuringCase, IOrganizationRegion} from '../../../models/Organization';
import {useLoader} from '../../../utils/Hooks';
import {plural} from '../../../utils/Internationalization';
import {showBlobInBrowser} from '../../../utils/OpenBlob';
import {CardCategory, CardLocationAwareness, CardTypeKey, ICardProps, ICardType} from '../../CardType';
import {useUser} from '../../CardUtils';
import {CardActions, ColumnChooser} from '../../components';
import {Reload} from '../../components/actions';
import {CardView, cardViewProps, CustomActions, CustomSettings} from '../../components/CardView';

import {getTableColumns, rowKey} from './Columns';
import {CreateFluviusMeasurementCampaign} from './CreateFluviusMeasurementCampaign';

export type IFluviusMeasurementCampaignCardSettings = ICardSettingsWithTable;

const NoRegions: IOrganizationRegion[] = [];
const NoMeasuringCases: IMeasuringCase[] = [];

const FluviusMeasurementCampaigns = (props: ICardProps<IFluviusMeasurementCampaignCardSettings>) => {
  const {fetch, settings, updateSettings} = props;
  const {api} = useAppContext();
  const me = useUser();

  const modals = useModals();
  const [filterStatus, setFilterStatus] = useState(FluviusCampaignStatus.Inactive);
  const [filterRegion, setFilterRegion] = useState<number>();
  const [campaigns, refreshCampaigns] = useFluviusCampaigns(fetch, filterStatus);

  const [regions = NoRegions] = useLoader(
    () => fetch('regions', api => api.fluvius.getRegions().catch(() => []), plural('region')),
    [fetch]
  );
  const [measuringCases = NoMeasuringCases] = useLoader(
    () => fetch('sets', api => api.fluvius.getMeasuringCases(), plural('measuringSet')),
    [fetch]
  );

  const fields = useMemo(() => {
    const handleClickedView = async (item: IFluviusCampaign) => {
      modals.show<FluviusCampaignConfiguration | undefined>(props => (
        <CreateFluviusMeasurementCampaign
          regions={regions}
          measuringCases={measuringCases}
          readOnly={true}
          existing={item.configuration}
          {...props}
        />
      ));
    };

    const startCampaign = async (item: IFluviusCampaign) => {
      const activatePromise = api.fluvius.activateMeasurementCampaign(item.id);
      const generatePDFPromise = generatePDF(item);
      try {
        const [campaign, pdfBlob] = await Promise.all([activatePromise, generatePDFPromise]);
        if (!campaign || !pdfBlob) return;

        const locationIds = campaign.serviceLocationIds;
        if (!locationIds) return;

        refreshCampaigns();
        try {
          const files = await api.uploadBlob(locationIds[0], pdfBlob, 'setup.pdf');
          const file = files[0];
          locationIds.forEach(id =>
            api.attachCustomFileToLocation(id, {
              url: file.fileName,
              tag: 'Meetcampagne'
            })
          );
          NotificationManager.success('De meetcampagne werd gestart');
        } catch {
          NotificationManager.warning('De meetcampagne is gestart, maar de registratie van het PDF bestand is mislukt');
        }
      } catch {
        NotificationManager.error('Kon de meetcampagne niet starten. Probeer later opnieuw');
      }
    };

    const handleClickedStart = async (item: IFluviusCampaign) => {
      if (item.confirmActivation) {
        const confirmed = await modals.show(props => (
          <ConfirmationPromiseModal
            title="Meetcampagne starten"
            message="Bent u zeker dat u deze meetcampagne wenst te starten? Hierdoor zal een vorige meetcampagne met deze meetset of meetkoffer worden afgesloten."
            acceptLabel="Start"
            rejectLabel="Annuleer"
            {...props}
          />
        ));
        if (confirmed === ConfirmationResult.Accept) startCampaign(item);
      } else {
        startCampaign(item);
      }
    };

    const deleteCampaign = async (campaign: IFluviusCampaign) => {
      try {
        await api.fluvius.deleteMeasurementCampaign(campaign.id);
        refreshCampaigns();
        NotificationManager.success('De meetcampagne werd verwijderd');
      } catch {
        NotificationManager.error('De meetcampagne kon niet worden verwijderd. Probeer later opnieuw');
      }
    };

    const handleClickedRemove = async (item: IFluviusCampaign) => {
      const confirmed = await modals.show(props => (
        <ConfirmationPromiseModal
          title="Meetcampagne verwijderen"
          message="Bent u zeker dat u deze meetcampagne wenst te verwijderen?"
          acceptLabel="Verwijder"
          rejectLabel="Annuleer"
          {...props}
        />
      ));
      if (confirmed === ConfirmationResult.Accept) deleteCampaign(item);
    };

    const handleClickedViewPDF = (item: IFluviusCampaign) => {
      generatePDF(item).then(result => {
        if (result === undefined) return;

        showBlobInBrowser(result, 'measurements-setup.pdf');
      });
    };

    const generatePDF = (campaign: IFluviusCampaign): Promise<Blob | undefined> => {
      const {configuration} = campaign;
      const region = regions.find(x => x.id === configuration.region);
      const set = measuringCases.find(x => x.id === configuration.measuringCase);
      if (!region || !set) return Promise.resolve(undefined);

      const pdfRequest: FluviusCampaignPDFRequest = {
        type: configuration.type,
        region: {
          id: region.id,
          name: region.name || (region.names || {}).en
        },
        measuringCase: {id: set.id, code: set.name || ''},
        name: configuration.name,
        vpkId: configuration.vpkId,
        cabinId: configuration.cabinId,
        city: configuration.city,
        address: configuration.address,
        reason: configuration.reason,
        notes: configuration.notes,
        geniusA: configuration.geniusA,
        geniusB: configuration.geniusB
      };
      const url = '/fluvius-campaign-pdf';
      return window
        .fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=utf-8'
          },
          body: JSON.stringify(pdfRequest)
        })
        .then(resp => resp.blob());
    };

    return getTableColumns(regions, measuringCases, {
      onClickedView: handleClickedView,
      onClickedViewPDF: handleClickedViewPDF,
      onClickedStart: handleClickedStart,
      onClickedRemove: handleClickedRemove
    });
  }, [api, modals, regions, measuringCases, refreshCampaigns]);

  const handleClickedCreate = async () => {
    const request = await modals.show<FluviusCampaignConfiguration | undefined>(props => (
      <CreateFluviusMeasurementCampaign
        regions={regions}
        measuringCases={measuringCases}
        readOnly={false}
        existing={undefined}
        {...props}
      />
    ));
    if (request === undefined) return;

    const campaign: IFluviusCampaignFields = {
      name: request.name,
      configuration: request
    };
    try {
      const saved = await api.fluvius.createMeasurementCampaign(campaign);
      if (!saved) return;

      refreshCampaigns();
      NotificationManager.success('De meetcampagne werd aangemaakt');
    } catch {
      NotificationManager.error('De meetcampagne kon niet worden aangemaakt. Probeer later opnieuw');
    }
  };

  const handleFilterRegionChanged = (value: string) => {
    setFilterRegion(value === '_' ? undefined : parseInt(value));
  };

  const handleFilterStatusChanged = (value: string) => {
    setFilterStatus(value as FluviusCampaignStatus);
  };

  const renderSettings: CustomSettings<IFluviusMeasurementCampaignCardSettings> = (settings, updateSettings) => (
    <ColumnChooser fields={fields} settings={settings.table} updateSettings={table => updateSettings({table})} />
  );

  const isServiceDesk = me.isServiceDesk();

  const actions: CustomActions = () => (
    <CardActions>
      <SelectInput
        className="tw-flex-1"
        value={filterRegion === undefined ? '_' : filterRegion.toString()}
        onChange={handleFilterRegionChanged}
        placeholder="(Alle regios)"
        options={[
          {label: '(Alle regios)', value: '_'},
          ...regions.map(region => ({label: region.name || '', value: region.id.toString()}))
        ]}
      />
      {isServiceDesk && (
        <SelectInput
          className="tw-flex-1"
          value={filterStatus}
          onChange={handleFilterStatusChanged}
          options={[
            {label: 'Alle', value: FluviusCampaignStatus.All},
            {label: 'Inactief', value: FluviusCampaignStatus.Inactive},
            {label: 'Actief', value: FluviusCampaignStatus.Active}
          ]}
        />
      )}
      <Reload onReload={refreshCampaigns} />
      {/* <Spring /> */}
      <RsButton onClick={handleClickedCreate}>Nieuwe campagne</RsButton>
    </CardActions>
  );

  let filteredCampaigns = campaigns;
  if (filterRegion) {
    filteredCampaigns = filteredCampaigns.filter(x => x.configuration.region === filterRegion);
  }

  return (
    <CardView actions={actions} customSettings={renderSettings} {...cardViewProps(props)}>
      <Table
        fields={fields}
        settings={settings.table}
        items={filteredCampaigns}
        rowKey={rowKey}
        updateSettings={table => updateSettings({table})}
        noun="fluviusMeasurementCampaign"
      />
    </CardView>
  );
};

const DEFAULT_SETTINGS = {
  table: {
    pageSize: 10,
    columns: [
      {name: 'timestamp', visible: true},
      {name: 'region', visible: true},
      {name: 'set', visible: true}
    ]
  }
};
const CARD: ICardType<ICardSettingsWithTable> = {
  type: CardTypeKey.FluviusMeasurementCampaigns,
  title: 'fluviusmeasurementcampaign.title',
  description: 'fluviusmeasurementcampaign.description',
  categories: [CardCategory.CONFIGURATION],
  rights: UserRights.User,
  isAvailable: (user, organizations) =>
    user.isServiceDesk() || organizations.find(org => org.name.toLowerCase() === 'fluvius') !== undefined,
  width: 4,
  height: 2,
  defaultSettings: DEFAULT_SETTINGS,
  locationAware: CardLocationAwareness.Unaware,
  cardClass: FluviusMeasurementCampaigns,
  upgradeSettings: migrateTableSettings('table', DEFAULT_SETTINGS.table)
};
export default CARD;
