import React, {useEffect, useState} from 'react';

import {SingleActionModal, FormGroup, Input, Button as RsButton, Label} from '../../../components/bootstrap';
import {Icon} from '../../../components/Icon';
import {SelectInput} from '../../../components/ui/select';
import {OrganizationLanguage} from '../../../models/Organization';
import {T} from '../../../utils/Internationalization';
import {TranslationKey} from '../../../utils/TranslationTerms';

interface EditTranslationProps {
  title: string;
  isOpen: boolean;
  type?: 'text' | 'textarea';
  onToggle: () => void;

  values: {[key: string]: string};
  onChange: (values: {[key: string]: string}) => void;
}

interface Translation {
  language: OrganizationLanguage;
  value: string;
}

function mapToTranslations(translations: {[key: string]: string}) {
  const result: Translation[] = [];
  if (!translations.en) {
    result.push({
      language: OrganizationLanguage.English,
      value: ''
    });
  }
  for (var key in translations) {
    result.push({
      language: key as OrganizationLanguage,
      value: translations[key]
    });
  }
  return result;
}

function translationsToMap(translations: Translation[]): {
  [key: string]: string;
} {
  return translations.reduce(
    (result, translation) => {
      result[translation.language] = translation.value;
      return result;
    },
    {} as {[key: string]: string}
  );
}

export const EditTranslationModal = (props: EditTranslationProps) => {
  const {title, isOpen, type = 'text', onToggle, onChange} = props;

  const [values, setValues] = useState<Translation[]>([]);
  useEffect(() => setValues(mapToTranslations(props.values)), [props.values]);

  const handleClickedSave = async () => {
    onChange(translationsToMap(values));
  };

  const handleLanguageSelected = (value: string) => {
    const language = value as OrganizationLanguage;
    setValues(values => [...values, {language, value: ''}]);
  };

  const handleDelete = (index: number) => {
    setValues(values => values.filter((_, i) => i !== index));
  };

  const handleUpdate = (index: number, value: string) => {
    setValues(values =>
      values.map((translation, i) => (i === index ? {language: translation.language, value} : translation))
    );
  };

  return (
    <SingleActionModal
      isOpen={isOpen}
      onToggle={onToggle}
      title={title}
      actionText="OK"
      action={handleClickedSave}
      cancelText="Cancel"
    >
      {values.map((translation, index) => (
        <FormGroup>
          <Label>
            {T(`language.${translation.language}` as TranslationKey)} translation{' '}
            <RsButton
              size="sm"
              color="link"
              withoutPadding
              title="Remove translation"
              style={{marginRight: 10}}
              onClick={() => handleDelete(index)}
            >
              <span className={Icon.SolidTimesCircle} />
            </RsButton>
          </Label>
          <Input value={translation.value} type={type} onChange={e => handleUpdate(index, e.currentTarget.value)} />
        </FormGroup>
      ))}
      <div style={{display: 'flex', justifyContent: 'flex-end'}}>
        <SelectInput
          className="tw-w-[180px]"
          onChange={handleLanguageSelected}
          value="__"
          options={[
            {label: T('organizations.addLanguage'), value: '__'},
            {label: T('language.en'), value: OrganizationLanguage.English},
            {label: T('language.nl'), value: OrganizationLanguage.Dutch},
            {label: T('language.fr'), value: OrganizationLanguage.French},
            {label: T('language.de'), value: OrganizationLanguage.German},
            {label: T('language.it'), value: OrganizationLanguage.Italian},
            {label: T('language.es'), value: OrganizationLanguage.Spanish},
            {label: T('language.pt'), value: OrganizationLanguage.Portuguese},
            {label: T('language.tr'), value: OrganizationLanguage.Turkish}
          ]}
        />
      </div>
    </SingleActionModal>
  );
};
