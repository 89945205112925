import {ActivePeriod} from '../components/PeriodSelector';
import {IBessReading} from '../models/BessReading';
import {None} from '../utils/Arrays';

import {APIClient} from './APIClient';

export class BessDataApi {
  client: APIClient;

  constructor(client: APIClient) {
    this.client = client;
  }

  async getBessEnergyData(
    locationId: number,
    deviceUUID: string,
    timeIndicator: 'LAST' | number
  ): Promise<IBessReading[]> {
    const url = `/api/v10/servicelocation/${locationId}/batteries/bess/${deviceUUID}/values/${timeIndicator}`;
    const data = await this.client.doGet<IBessReading[]>(url, None);
    return data || [];
  }

  async getPeriodicalBessEnergyData(
    locationId: number,
    deviceUUID: string,
    period: ActivePeriod
  ): Promise<IBessReading[]> {
    const url = `/api/v10/servicelocation/${locationId}/batteries/bess/${deviceUUID}/rangevalues?from=${period.from}&to=${period.to}`;
    const data = await this.client.doGet<IBessReading[]>(url, None);
    return data || [];
  }
}
