import {ILocationSummary} from './Location';
import {IOrganization} from './Organization';

export interface Invitation {
  id: string;
  inviter: InvitationUser;
  invitee: InvitationUser;
  expirationTimestamp: number;
  expired: boolean;
  shareLink: string;
  purpose: InvitationPurpose;
  organization?: IOrganization;
  used?: boolean;
}

export interface LocationInvitation extends Invitation {
  type: InvitationType;
  serviceLocation: ILocationSummary;
}

export function isLocationInvitation(invitation: Invitation): invitation is LocationInvitation {
  return invitation.purpose === InvitationPurpose.ShareLocation;
}

export interface InvitationUser {
  id: number;
  emailAddress: string;
  firstName: string;
  lastName: string;
}

export const enum InvitationType {
  ShareReadWrite = 'SHARE_READWRITE',
  ShareReadOnly = 'SHARE_READONLY'
}

export const enum InvitationPurpose {
  ShareLocation = 'SHARE_SERVICELOCATION_WITH_USER',
  AddUserToOrganization = 'ADD_USER_TO_ORGANIZATION',
  OfferRFIDCard = 'HAND_OUT_RFIDCARD_TO_USER'
}

export interface OrganizationInvitation extends Invitation {
  role: string;
  organization: IOrganization;
}

export interface ChargingCardInvitation extends Invitation {
  rfid: {
    value: string;
    name: string;
    employeeNumber: string;
  };
}

export interface EmailChangeInviteDetails {
  expirationTimestamp: number;
  expired: boolean;
  id: string;
  invitee: {
    id: number | string;
    firstName: string;
    lastName: string;
  };
  parameters: {
    newEmail: string;
  };
  purpose: string;
}

export interface VerifyEmailInviteDetails {
  expirationTimestamp: number;
  expired: boolean;
  id: string;
  invitee: {
    id: number | string;
    firstName: string;
    lastName: string;
  };
  parameters: {
    emailAddress: string;
    username: string;
  };
  purpose: string;
}
