import React, {useContext} from 'react';

import HighPieChart from '../../../components/HighPieChart';
import {Icons} from '../../../components/Icon';
import PeriodSelector, {PeriodSettings} from '../../../components/PeriodSelector';
import {BessEnergyColors, IBatteryEnergyData} from '../../../models/BessReading';
import {T} from '../../../utils/Internationalization';
import {BessAccessContext} from '../BessAccessContext';
import BatteryEnergyChart from '../charts/BatteryEnergyChart';
import GridEnergyChart from '../charts/GridEnergyChart';

type HistoricSectionProps = {
  data: IBatteryEnergyData[] | null;
  period: PeriodSettings;
  onPeriodChange: (newPeriod: PeriodSettings) => void;
};

export default function HistoricSection({data, period, onPeriodChange}: HistoricSectionProps) {
  const [loading, setLoading] = React.useState(false);
  const isReadOnlyUser = useContext(BessAccessContext);
  const handlePeriodChange = async (newPeriod: PeriodSettings) => {
    setLoading(true);
    onPeriodChange(newPeriod);
    setLoading(false);
  };

  const pieChartData = React.useMemo(() => {
    if (!data) return null;

    const totalValues = {G2B: 0, S2B: 0, B2G: 0, B2L: 0, G2L: 0, S2G: 0};
    data.forEach(item => {
      totalValues.G2B += item.G2BData || 0;
      totalValues.S2B += item.S2BData || 0;
      totalValues.B2G += item.B2GData || 0;
      totalValues.B2L += item.B2LData || 0;
      totalValues.G2L += item.B2LData || 0;
      totalValues.S2G += item.S2GData || 0;
    });

    const chargeTotal = totalValues.G2B + totalValues.S2B;
    const dischargeTotal = totalValues.B2G + totalValues.B2L;
    const gridImportTotal = totalValues.B2G + totalValues.S2G;
    const gridExportTotal = totalValues.G2B + totalValues.G2L;

    return {
      bessDataCharge: [
        {name: T('bess.tab.historic.solarToBattery'), y: (totalValues.S2B / chargeTotal) * 100 || 0},
        {name: T('bess.tab.historic.gridToBattery'), y: (totalValues.G2B / chargeTotal) * 100 || 0}
      ],
      bessDataDischarge: [
        {name: T('bess.tab.historic.batteryToLoad'), y: (totalValues.B2L / dischargeTotal) * 100 || 0},
        {name: T('bess.tab.historic.batteryToGrid'), y: (totalValues.B2G / dischargeTotal) * 100 || 0}
      ],
      gridImportData: [
        {name: T('bess.tab.historic.batteryToGrid'), y: (totalValues.B2G / gridImportTotal) * 100 || 0},
        {name: T('bess.tab.historic.solarToGrid'), y: (totalValues.S2G / gridImportTotal) * 100 || 0}
      ],
      gridExportData: [
        {name: T('bess.tab.historic.gridToLoad'), y: (totalValues.G2L / gridExportTotal) * 100 || 0},
        {name: T('bess.tab.historic.gridToBattery'), y: (totalValues.G2B / gridExportTotal) * 100 || 0}
      ]
    };
  }, [data]);

  return (
    <div className="tw-grid tw-grid-cols-5 tw-gap-4">
      <div className="tw-col-span-4 tw-border tw-p-4">
        <div className="tw-flex tw-flex-col">
          <div className="tw-flex tw-flex-row tw-items-center">
            <span className="tw-mr-2">{T('periodSelector.field.period')}:</span>
            <PeriodSelector settings={period} activeInterval={period.interval} onChanged={handlePeriodChange} />
            {loading && (
              <p className="tw-text-center">
                {T('modal.loading')}
                <br />
                {Icons.Loading}
              </p>
            )}
          </div>
          <BatteryEnergyChart dataSeries={data || []} />
        </div>
      </div>
      <div className="tw-col-span-1 tw-border tw-p-4">
        <div>
          <HighPieChart
            title={T('bess.tab.historic.chart.batteryCharge.title')}
            seriesName={T('bess.tab.historic.chart.batteryCharge.series.title')}
            data={pieChartData?.bessDataCharge || []}
            tooltipFormat="{series.name}: <b>{point.percentage:.1f}%</b>"
            showDataLabels={false}
            colors={[BessEnergyColors.SolarToBattery, BessEnergyColors.GridToBattery]}
          />
        </div>
        <div>
          <HighPieChart
            title={T('bess.tab.historic.chart.batteryDischarge.title')}
            seriesName={T('bess.tab.historic.chart.batteryDischarge.series.title')}
            data={pieChartData?.bessDataDischarge || []}
            tooltipFormat="{series.name}: <b>{point.percentage:.1f}%</b>"
            showDataLabels={false}
            colors={[BessEnergyColors.BatteryToLoad, BessEnergyColors.BatteryToGrid]}
          />
        </div>
      </div>
      <div className="tw-col-span-4 tw-border tw-p-4">
        <GridEnergyChart dataSeries={data || []} />
      </div>
      <div className="tw-col-span-1 tw-border tw-p-4">
        <div>
          <HighPieChart
            title={T('bess.tab.historic.pieChart.gridImport')}
            seriesName={T('generic.import')}
            data={pieChartData?.gridImportData || []}
            tooltipFormat="{series.name}: <b>{point.percentage:.1f}%</b>"
            showDataLabels={false}
            colors={[BessEnergyColors.BatteryToGrid, BessEnergyColors.SolarToGrid]}
          />
        </div>
        <div>
          <HighPieChart
            title={T('bess.tab.historic.pieChart.gridExport')}
            seriesName={T('generic.export')}
            data={pieChartData?.gridExportData || []}
            tooltipFormat="{series.name}: <b>{point.percentage:.1f}%</b>"
            showDataLabels={false}
            colors={[BessEnergyColors.GridToLoad, BessEnergyColors.GridToBattery]}
          />
        </div>
      </div>
    </div>
  );
}
