import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Handles, Rail, Slider, Ticks, Tracks} from 'react-compound-slider';

import {noop} from '..';
import {Checkbox} from '../../../components/ui/checkbox';
import {Label} from '../../../components/ui/label';
import BatteryIcon from '../../../components/ui-lib/icons/large/BatteryIcon';
import {FormProvider} from '../../../utils/FormContext';
import {T} from '../../../utils/Internationalization';
import {BessAccessContext} from '../BessAccessContext';
import {BessModeType, BessTreshold} from '../models/BessUnitConfiguration.model';
import {Handle} from '../slider-components/Handle';
import './SliderStyles.css';
// eslint-disable-next-line import/order
import {SliderRail} from '../slider-components/SliderRail';
import {Tick} from '../slider-components/Tick';
import {Track} from '../slider-components/Track';

export type UsageConfigurationSectionProps = {
  data: {
    localProductionAvailable: boolean;
    modes: BessModeType[] | [] | undefined;
    treshold: BessTreshold;
  };
  onChange: (
    field: keyof UsageConfigurationSectionProps['data'],
    value: boolean | BessModeType[] | BessTreshold
  ) => void;
  markFormDirty: () => void;
};

export const BESS_SPEC_NAME_TRANSLATIONS: {[key: string]: string} = {
  'c.s.s.bess.mode': T('bess.usageConfig.supportedModes'),
  'c.s.s.bess.capacity': T('bess.usageConfig.supportedModes'),
  'c.s.s.bess.max.charge.speed': T('bess.property.maximumChargeSpeed'),
  'c.s.s.bess.max.discharge.speed': T('bess.property.maximumDischargeSpeed'),
  'c.s.s.bess.max.state.of.charge': '',
  'c.s.s.bess.max.surplus.reserve.state.of.charge': '',
  'c.s.s.bess.min.state.of.charge': '',
  'c.s.s.bess.min.peak.shaving.state.of.charge': ''
};

const sliderStyle = {
  position: 'relative',
  height: '400px',
  marginLeft: '286px',
  touchAction: 'none'
};

export default function UsageConfigurationSection(props: UsageConfigurationSectionProps) {
  const {data, onChange, markFormDirty} = props;
  const isReadOnlyUser = useContext(BessAccessContext);
  const [modes, setModes] = React.useState(data?.modes || []);
  const [treshold, setTreshold] = React.useState<BessTreshold>(data?.treshold);
  const [values, setValues] = useState<number[]>([
    data.treshold.upperHealthStatus
      ? Math.min(data.treshold.upperHealthStatus, 95) // constrain to 95
      : 95,
    data.treshold.surplusReserve ?? 75,
    data.treshold.peakShavingSafety ?? 25,
    data.treshold.lowerHealthStatus
      ? Math.max(data.treshold.lowerHealthStatus, 5) // constrain to 5
      : 5
  ]);
  const [sliderTooltips, setSliderTooltips] = React.useState<string[]>([]);

  // Set sliderValues and sliderTooltips according to selected modes
  useEffect(() => {
    if (!modes) return;
    const newValues = [treshold.upperHealthStatus ?? 95, treshold.lowerHealthStatus ?? 5]; // Default to only health limits
    const newTooltips = [T('bess.property.upperHealthLimit'), T('bess.property.lowerHealthLimit')];

    // Add surplusReserve value if local production is available and optimizer mode is selected
    if (data.localProductionAvailable) {
      newValues.splice(1, 0, treshold.surplusReserve ?? 75); // Insert surplus reserve before lower health
      newTooltips.splice(1, 0, T('bess.property.surplusReserve'));
    }

    // Add peakShavingSafety value if local production is available and peak shaving mode is selected
    if (data.localProductionAvailable && Object.values(modes).includes(BessModeType.PeakShaving as BessModeType)) {
      const insertIndex = newValues.length === 2 ? 1 : 2; // Depending on if surplus reserve is present
      newValues.splice(insertIndex, 0, treshold.peakShavingSafety ?? 25); // Insert peak shaving safety
      newTooltips.splice(insertIndex, 0, T('bess.usageConfig.peakShavingSafety'));
    }

    setValues(newValues);
    setSliderTooltips(newTooltips);
  }, [treshold, modes, data.localProductionAvailable]);

  const handleBessModeTypeChecked = (
    field: keyof UsageConfigurationSectionProps['data'],
    type: BessModeType,
    checked: boolean
  ) => {
    if (checked) {
      setModes([...modes, type]);
      const update = [...modes, type];
      markFormDirty();
      onChange(field, update);
    } else {
      setModes(modes?.filter(modeType => modeType !== type));
      const update = modes?.filter(modeType => modeType !== type);
      markFormDirty();
      onChange(field, update);
    }
  };
  const onUpdate = useCallback((newValues: ReadonlyArray<number>) => {
    setValues([...newValues]);
  }, []);

  const onSliderChange = useCallback(
    (field: keyof UsageConfigurationSectionProps['data'], newValues: ReadonlyArray<number>) => {
      const constraineddUpperHealthStatus = Math.min(newValues[0], 95);
      const constrainedLowerHealthStatus = Math.max(newValues[newValues.length - 1], 5);
      const update: BessTreshold = {
        upperHealthStatus: constraineddUpperHealthStatus,
        lowerHealthStatus: constrainedLowerHealthStatus // Last value will always be lower health
      };

      // Depending on modes, set other values
      if (data.localProductionAvailable) {
        update.surplusReserve = newValues[1];
      }

      if (data.localProductionAvailable && modes?.includes(BessModeType.PeakShaving)) {
        const peakShavingIndex = 2;
        update.peakShavingSafety = newValues[peakShavingIndex]; // Adjust based on mode presence
      }

      setTreshold(update);
      markFormDirty();
      onChange(field, update);
    },
    [data.localProductionAvailable, modes, markFormDirty, onChange]
  );

  return (
    <div className="tw-w-full tw-relative">
      <FormProvider>
        <div className="tw-mb-4">
          <Checkbox
            id="localProductionAvailable"
            name="localProductionAvailable"
            label={T('bess.usageConfig.localProductionAvailable')}
            checked={data.localProductionAvailable}
            readOnly
            disabled={isReadOnlyUser}
            // onCheckedChange={checked => onChange('localProductionAvailable', checked)}
          />
        </div>
        <div className="tw-flex tw-gap-4">
          <Label className="tw-font-bold">{T('bess.usageConfig.supportedModes')}:</Label>
          <div className="tw-flex tw-flex-col">
            <Checkbox
              id="peak-shaving"
              name="peak-shaving"
              label={T('bess.usageConfig.mode.peakShaving')}
              checked={modes?.length ? modes?.includes(BessModeType.PeakShaving) : false}
              readOnly={isReadOnlyUser}
              disabled={isReadOnlyUser}
              onCheckedChange={
                isReadOnlyUser ? noop : checked => handleBessModeTypeChecked('modes', BessModeType.PeakShaving, checked)
              }
              testId="bess-mode-peak-shaving"
              wrapperClassName="tw-mb-4"
            />
            <Checkbox
              id="optimizer"
              name="optimizer"
              label={T('bess.usageConfig.mode.optimizer')}
              checked={modes?.length ? modes?.includes(BessModeType.Optimizer) : false}
              readOnly={isReadOnlyUser}
              disabled={isReadOnlyUser}
              onCheckedChange={
                isReadOnlyUser ? noop : checked => handleBessModeTypeChecked('modes', BessModeType.Optimizer, checked)
              }
              testId="bess-mode-optimizer"
              wrapperClassName="tw-mb-4"
            />
            <Checkbox
              id="imbalance"
              name="imbalance"
              label={T('bess.usageConfig.mode.imbalance')}
              checked={modes?.length ? modes?.includes(BessModeType.Imbalance) : false}
              readOnly={isReadOnlyUser}
              disabled={isReadOnlyUser}
              onCheckedChange={
                isReadOnlyUser ? noop : checked => handleBessModeTypeChecked('modes', BessModeType.Imbalance, checked)
              }
              testId="bess-mode-imbalance"
              wrapperClassName="tw-mb-4"
            />
          </div>
        </div>
        <div className="tw-w-full">
          <Label className="tw-font-bold tw-mb-5">{T('bess.usageConfig.tresholds')}:</Label>
          <div className="tw-w-[35.75rem] tw-grid tw-grid-cols-3 tw-gap-4">
            <div className="tw-w-[11.25rem] tw-h-40 tw-relative">
              {/* Disable this layout temporary */}
              {/* <label className="tw-text-sm tw-leading-[1.25rem] tw-mt-10 tw-mb-4">
                {`${T('bess.usageConfig.upperHealthStatus')}: ${values[0]}%`}
              </label>
              <label className="tw-text-sm tw-leading-[1.25rem] tw-m-0 tw-mb-4">
                {`${T('bess.property.surplusReserve')}: ${values[1]}%`}
              </label>
              <label className="tw-text-sm tw-leading-[1.25rem] tw-m-0 tw-mb-4">
                {`${T('bess.usageConfig.peakShavingSafety')}: ${values[2]}%`}
              </label>
              <label className="tw-text-sm tw-leading-[1.25rem] tw-m-0 tw-mb-4">
                {`${T('bess.usageConfig.lowerHealthStatus')}: ${values[3]}%`}
              </label> */}
            </div>
            <div className="tw-flex tw-w-[11.25rem] tw-relative">
              <BatteryIcon width={180} height={468} />
            </div>
            <div className="tw-w-[11.25rem] tw-h-40 tw-relative">{/* Free space */}</div>
          </div>
        </div>
      </FormProvider>
      <div style={{height: 488, width: '100%', marginTop: '-428px'}}>
        <Slider
          domain={[0, 100]}
          values={values}
          vertical
          reversed
          mode={2} // Mode 2: prevent crossing
          step={1} // Step per procent
          disabled={isReadOnlyUser}
          onUpdate={isReadOnlyUser ? noop : onUpdate}
          onChange={isReadOnlyUser ? noop : () => onSliderChange('treshold', values)}
          rootStyle={sliderStyle}
          className={isReadOnlyUser ? '!tw-cursor-not-allowed' : ''}
        >
          <Rail>{({getRailProps}) => <SliderRail getRailProps={getRailProps} />}</Rail>
          <Handles>
            {({handles, getHandleProps}) => (
              <div className="slider-handles">
                {handles.map((handle, index) => (
                  <Handle
                    key={handle.id}
                    handle={handle}
                    domain={[0, 100]}
                    getHandleProps={getHandleProps}
                    tooltip={sliderTooltips[index]} // Pass the tooltip based on index
                    className={isReadOnlyUser ? '!tw-cursor-not-allowed' : ''}
                  />
                ))}
              </div>
            )}
          </Handles>
          <Tracks left={false} right={false}>
            {({tracks, getTrackProps}) => (
              <div className="slider-tracks">
                {tracks.map(({id, source, target}) => (
                  <Track key={id} source={source} target={target} getTrackProps={getTrackProps} />
                ))}
              </div>
            )}
          </Tracks>
          <Ticks count={10}>
            {({ticks}) => (
              <div className="slider-ticks">
                {ticks.map(tick => (
                  <Tick key={tick.id} tick={tick} />
                ))}
              </div>
            )}
          </Ticks>
        </Slider>
      </div>
    </div>
  );
}
