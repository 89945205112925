import numeral from 'numeral';
import React from 'react';

import {Subscript} from '../../components/Styling';
import Table, {IPersistedTableSettings} from '../../components/Table';
import {KW_DIGITS_AFTER_COMMA} from '../../core/constants';
import {ChannelSensorType, IChannel} from '../../models/Channel';
import {CT_TYPE_LABEL_CLOSED, getCTTypeLabel} from '../../models/CTType';
import {PhaseType} from '../../models/HighLevelConfiguration';
import {ILocationSummary, isBigConsumer} from '../../models/Location';
import {Phase, getPhaseLabel} from '../../models/Phase';
import {
  ITableField,
  CalculatedStringField,
  NumberField,
  CalculatedNumberField,
  IFieldOptions,
  DEFAULT_OPTIONS,
  CalculatedIntegerField,
  ITableCellProps
} from '../../models/Table';

import {getPowerFactor} from '../../utils/Calculations';

import {T} from '../../utils/Internationalization';

import {ILiveLowLevelData} from './Data';

import {LoadNameField} from './LoadNameField';

const VOLTAGE_BETWEEN_PHASES = {
  [Phase.L1]: T('liveElectricityValues.tooltip.u12'),
  [Phase.L2]: T('liveElectricityValues.tooltip.u23'),
  [Phase.L3]: T('liveElectricityValues.tooltip.u31')
};

const VOLTAGE_BETWEEN_PHASES_DELTA = {
  [Phase.L1]: T('liveElectricityValues.tooltip.u31'),
  [Phase.L2]: T('liveElectricityValues.tooltip.u23'),
  [Phase.L3]: T('liveElectricityValues.tooltip.u12')
};

class LineVoltageField implements ITableField<ILiveLowLevelData> {
  name: string;
  label: string;
  options: IFieldOptions;
  phaseType: PhaseType;

  constructor(phaseType: PhaseType) {
    this.name = 'lineVoltage';
    this.label = 'ULL';
    this.options = {
      ...DEFAULT_OPTIONS,
      header: (props: ITableCellProps) => (
        <th {...props}>
          U<Subscript>LL</Subscript>
        </th>
      ),
      title: (
        <span>
          U<Subscript>LL</Subscript> - {T('liveElectricityValues.table.lineVoltage')}
        </span>
      ),
      unit: 'V'
    };
    this.phaseType = phaseType;
  }

  renderCellContent(item: ILiveLowLevelData) {
    const value = item.lineVoltage;
    if (value === undefined || isNaN(value)) return undefined;

    // Round to closest significant number
    /*const tooltip =
      this.phaseType === PhaseType.Delta
        ? VOLTAGE_BETWEEN_PHASES_DELTA[item.channel.phase]
        : VOLTAGE_BETWEEN_PHASES[item.channel.phase];*/
    const tooltip = VOLTAGE_BETWEEN_PHASES[item.channel.phase];

    return (
      <span
        style={{
          whiteSpace: 'nowrap',
          borderBottom: '1px dashed #999',
          display: 'inline'
        }}
        title={tooltip}
      >
        {numeral(value).format('0.0')}
      </span>
    );
  }

  getExportCSVValue(item: ILiveLowLevelData) {
    return item.lineVoltage === undefined ? '' : numeral(item.lineVoltage).format('0.0');
  }

  getExportExcelValue(item: ILiveLowLevelData) {
    return item.lineVoltage === undefined ? '' : item.lineVoltage;
  }

  sort(a: ILiveLowLevelData, b: ILiveLowLevelData) {
    if (!a.lineVoltage || !b.lineVoltage) return 0;

    return a.lineVoltage - b.lineVoltage;
  }
}

interface InfinityLowLevelProps {
  location: ILocationSummary;
  phaseType: PhaseType;
  channels: IChannel[];
  items: ILiveLowLevelData[];
  tableSettings: IPersistedTableSettings;
  updateTableSettings: (settings: IPersistedTableSettings) => void;
  serviceDesk: boolean;
}
class InfinityLowLevel extends React.Component<InfinityLowLevelProps, {}> {
  static getTableColumns(
    channels: IChannel[],
    phaseType: PhaseType,
    location: ILocationSummary,
    serviceDesk: boolean
  ): ITableField<ILiveLowLevelData>[] {
    const bigConsumer = isBigConsumer(location);
    const powerMultiplier = bigConsumer ? 0.001 : 1;
    const powerDigits = bigConsumer ? KW_DIGITS_AFTER_COMMA : 0;
    const columns: ITableField<ILiveLowLevelData>[] = [
      new CalculatedIntegerField('index', '#', row => row.index + 1, {
        alwaysVisible: true,
        autoInsert: 'start'
      }),
      new LoadNameField('name', T('liveElectricityValues.table.channel'), row => row.channel.name, {width: 250}),
      new CalculatedStringField('phase', T('liveElectricityValues.table.phase'), row =>
        getPhaseLabel(phaseType, row.channel.phase)
      ),
      new CalculatedStringField('location', T('liveElectricityValues.table.location'), row =>
        row.channel.sensor === undefined
          ? undefined
          : `${row.channel.sensor.serialNumber} ${row.channel.sensor.location}`
      ),
      new CalculatedStringField('ctType', T('liveElectricityValues.table.ctType'), row => {
        if (row.channel.sensor && row.channel.sensor.type === ChannelSensorType.Closed) {
          return CT_TYPE_LABEL_CLOSED;
        }
        return getCTTypeLabel(row.channel.ctType);
      }),
      new CalculatedNumberField(
        'active',
        'P',
        row => (row.active === undefined ? undefined : row.active * powerMultiplier),
        {
          title: T('liveElectricityValues.table.activePower'),
          unit: bigConsumer ? 'kW' : 'W',
          tooltip: T('liveElectricityValues.tooltip.activePower'),
          digitsAfterComma: powerDigits
        }
      ),
      new CalculatedNumberField(
        'reactive',
        'Q',
        row => (row.reactive === undefined ? undefined : row.reactive * powerMultiplier),
        {
          title: T('liveElectricityValues.table.reactivePower'),
          unit: bigConsumer ? 'kvar' : 'var',
          tooltip: T('liveElectricityValues.tooltip.reactivePower'),
          digitsAfterComma: powerDigits
        }
      ),
      new CalculatedNumberField('powerFactor', 'PF', row => getPowerFactor(row.active, row.reactive), {
        title: T('liveElectricityValues.table.powerFactor'),
        tooltip: T('liveElectricityValues.tooltip.powerFactor'),
        digitsAfterComma: 2
      }),
      new CalculatedNumberField(
        'apparent',
        'S',
        row => {
          if (row.active === undefined || row.reactive === undefined) {
            return undefined;
          }

          return Math.sqrt(row.active * row.active + row.reactive * row.reactive) * powerMultiplier;
        },
        {
          title: T('liveElectricityValues.table.apparentPower'),
          unit: bigConsumer ? 'kVA' : 'VA',
          digitsAfterComma: powerDigits
        }
      )
    ];

    if (channels.length > 0) {
      columns.push(
        new NumberField('current', 'I', {
          title: T('liveElectricityValues.table.current'),
          unit: 'A',
          digitsAfterComma: 2
        })
      );
      columns.push(
        new NumberField('phaseVoltage', 'ULN', {
          header: props => (
            <th {...props}>
              U<Subscript>LN</Subscript>
            </th>
          ),
          title: (
            <span>
              U<Subscript>LN</Subscript> - {T('liveElectricityValues.table.phaseVoltage')}
            </span>
          ),
          unit: 'V',
          digitsAfterComma: 1
        })
      );

      if (phaseType !== PhaseType.Single) columns.push(new LineVoltageField(phaseType));
    }

    columns.push(
      new CalculatedStringField('reversed', T('liveElectricityValues.configuration.reversed'), row => {
        if (row.channel.sensor === undefined) return undefined;

        return T.generic.yesNo(row.channel.sensor.reversed);
      })
    );

    if (serviceDesk) {
      columns.push(new CalculatedIntegerField('publishIndex', 'Publish index', item => item.channel.publishIndex));
    }

    return columns;
  }

  rowKey = (row: ILiveLowLevelData) => row.channel.id;
  render() {
    const {channels, items, location, tableSettings, updateTableSettings, phaseType, serviceDesk} = this.props;
    return (
      <Table
        fields={InfinityLowLevel.getTableColumns(channels, phaseType, location, serviceDesk)}
        items={items}
        rowKey={this.rowKey}
        hasPaging={true}
        noun="channel"
        settings={tableSettings}
        updateSettings={updateTableSettings}
      />
    );
  }
}

export default InfinityLowLevel;
