import React, {useMemo, useState} from 'react';

import {NotificationManager} from 'react-notifications';

import {useAppContext} from '../../app/context';
import {Alert, FormGroup, Input, Label, SingleActionModal} from '../../components/bootstrap';
import {SelectInput} from '../../components/ui/select';
import {IPromiseModalProps, usePromiseModal} from '../../modals/PromiseModal';
import {ILocationSummary} from '../../models/Location';
import {None} from '../../utils/Arrays';
import {Fetcher} from '../../utils/Fetcher';
import {useLocationSwitches, useSensors} from '../../utils/FunctionalData';
import {T} from '../../utils/Internationalization';
import {useUser} from '../CardUtils';

enum ClearType {
  ALL = 'ALL',
  ELECTRICITY = 'ELECTRICITY',
  GAS_WATER = 'GAS_WATER',
  SWITCH = 'SWITCH',
  APPLIANCES = 'APPLIANCES'
}

interface ClearDataModalProps extends IPromiseModalProps<void> {
  fetch: Fetcher;
  location: ILocationSummary;
}

export default (props: ClearDataModalProps) => {
  const {fetch, location} = props;
  const {api} = useAppContext();
  const me = useUser();
  const [isOpen, resolve] = usePromiseModal(props);
  const handleToggle = () => resolve();

  const [type, setType] = useState<ClearType>(ClearType.ELECTRICITY);
  const [switchId, setSwitchId] = useState('all');
  const [sensorId, setSensorId] = useState('all');
  const [confirmation, setConfirmation] = useState('');

  const [switches] = useLocationSwitches(fetch, location.id);
  const sensors = useSensors(fetch, location.id) || None;

  const sensorOptions = useMemo(
    () =>
      sensors.map(sensor => ({
        label: sensor.serialNumber,
        value: sensor.id.toString()
      })),
    [sensors]
  );

  const switchOptions = useMemo(
    () =>
      switches
        .filter(switch_ => switch_.serialNumber !== undefined)
        .map(switch_ => ({
          label: switch_.name,
          value: switch_.monitorId?.toString() ?? ''
        })),
    [switches]
  );

  const handleSensorIdChanged = (value: string) => {
    setSensorId(value);
  };

  const handleSwitchIdChanged = (value: string) => {
    setSwitchId(value);
  };

  const handleClearTypeChanged = (value: string) => {
    setType(value as ClearType);
  };

  const handleConfirmationChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
    setConfirmation(e.currentTarget.value);
  };

  const isValidInput = confirmation === me.username;

  const clearData = async () => {
    const {id} = location;

    switch (type) {
      case ClearType.ALL:
        api
          .deleteAllData(id)
          .then(() => {
            handleToggle();
            NotificationManager.success(T('locationConfiguration.clearData.all.success'));
          })
          .catch(() => {
            NotificationManager.error(T('locationConfiguration.clearData.all.failed'));
          });
        return;
      case ClearType.ELECTRICITY:
        api
          .deleteElectricityData(id)
          .then(() => {
            handleToggle();
            NotificationManager.success(T('locationConfiguration.clearData.electricity.success'));
          })
          .catch(() => {
            NotificationManager.error(T('locationConfiguration.clearData.electricity.failed'));
          });
        return;
      case ClearType.APPLIANCES:
        api
          .deleteApplianceData(id)
          .then(() => {
            handleToggle();
            NotificationManager.success(T('locationConfiguration.clearData.appliances.success'));
          })
          .catch(() => {
            NotificationManager.error(T('locationConfiguration.clearData.appliances.failed'));
          });
        return;
      case ClearType.GAS_WATER:
        if (sensorId === 'all') {
          api
            .deleteAllGasWaterData(id)
            .then(() => {
              handleToggle();
              NotificationManager.success(T('locationConfiguration.clearData.gasWater.all.success'));
            })
            .catch(() => {
              NotificationManager.error(T('locationConfiguration.clearData.gasWater.all.failed'));
            });
        } else {
          const sensorIdInt = parseInt(sensorId);
          const sensor = sensors.find(x => x.id === sensorIdInt);
          const serial = sensor ? sensor.serialNumber : sensorId;
          api
            .deleteGasWaterData(id, sensorIdInt)
            .then(() => {
              handleToggle();
              NotificationManager.success(
                T('locationConfiguration.clearData.gasWater.specific.success', {
                  serial
                })
              );
            })
            .catch(() => {
              NotificationManager.error(
                T('locationConfiguration.clearData.gasWater.specific.failed', {
                  serial
                })
              );
            });
        }
        return;
      case ClearType.SWITCH:
        if (switchId === 'all') {
          api
            .deleteAllSwitchData(id)
            .then(() => {
              handleToggle();
              NotificationManager.success(T('locationConfiguration.clearData.switch.success'));
            })
            .catch(() => {
              NotificationManager.success(T('locationConfiguration.clearData.switch.failed'));
            });
        } else {
          api
            .deleteSwitchData(id, parseInt(switchId))
            .then(() => {
              handleToggle();
              NotificationManager.success(T('locationConfiguration.clearData.switch.success'));
            })
            .catch(() => {
              NotificationManager.success(T('locationConfiguration.clearData.switch.failed'));
            });
        }
    }
  };

  const {username} = me;
  const hasSwitches = Array.isArray(switches) && switches.length > 0;

  return (
    <SingleActionModal
      isOpen={isOpen}
      onToggle={handleToggle}
      title={T('locationConfiguration.clearData.title')}
      actionText={T('locationConfiguration.clearData.confirm')}
      action={clearData}
      disabled={!isValidInput}
      cancelText={T('locationConfiguration.clearData.cancel')}
    >
      <FormGroup>
        <Label for="type">{T('locationConfiguration.clearData.dataToClear')}</Label>
        <SelectInput
          name="type"
          value={type}
          onChange={handleClearTypeChanged}
          options={[
            {value: ClearType.ALL, label: T('locationConfiguration.clearData.allData')},
            {value: ClearType.ELECTRICITY, label: T('locationConfiguration.clearData.electricity')},
            {value: ClearType.APPLIANCES, label: T('locationConfiguration.clearData.appliances')},
            {value: ClearType.GAS_WATER, label: T('locationConfiguration.clearData.gasWaterData')},
            ...(hasSwitches ? [{value: ClearType.SWITCH, label: T('locationConfiguration.clearData.switchData')}] : [])
          ]}
        />
      </FormGroup>

      {/* Render gas, water or Switch selections */}
      {type === ClearType.GAS_WATER && (
        <FormGroup>
          <SelectInput
            name="sensorId"
            value={sensorId}
            onChange={handleSensorIdChanged}
            options={[{value: 'all', label: T('locationConfiguration.clearData.all')}, ...sensorOptions]}
          />
        </FormGroup>
      )}
      {type === ClearType.SWITCH && (
        <FormGroup>
          <SelectInput
            name="switchId"
            value={switchId}
            onChange={handleSwitchIdChanged}
            options={[{value: 'all', label: T('locationConfiguration.clearData.all')}, ...switchOptions]}
          />
        </FormGroup>
      )}

      <FormGroup>
        <Label for="confirmation">Confirmation code</Label>
        <Alert fixed={false}>
          <div
            dangerouslySetInnerHTML={{
              __html: T('locationConfiguration.clearData.alert', {username})
            }}
          />
        </Alert>
        <Input type="text" name="confirmation" onChange={handleConfirmationChanged} />
      </FormGroup>
    </SingleActionModal>
  );
};
