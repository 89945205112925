import {IChargingRule} from '../../models/ChargingPriorities';
import {
  ChargingStationPaymentType,
  IChargingSession,
  translateChargingStationPaymentType
} from '../../models/ChargingStation';
import {
  ITableField,
  TimestampField,
  CalculatedStringField,
  NumberField,
  StringField,
  IntegerField,
  CurrencyField,
  CheckboxField,
  CalculatedCurrencyField
} from '../../models/Table';
import {AppFeature, hasFeature} from '../../utils/AppParameters';
import {T} from '../../utils/Internationalization';

export function getTableColumns(
  whitelist: IChargingRule[],
  serviceDesk: boolean = false,
  selected?: IChargingSession[],
  onChecked?: (session: IChargingSession, checked: boolean) => void
): ITableField<IChargingSession>[] {
  const result: ITableField<IChargingSession>[] = [];

  if (serviceDesk) {
    result.push(new IntegerField('id', 'ID'));
  }

  const isChecked = (row: IChargingSession) => {
    return selected === undefined ? false : selected.some(item => item.id === row.id);
  };

  result.push(
    new TimestampField('from', T('chargingSession.from'), {
      format: 'YYYY-MM-DD HH:mm'
    }),
    new TimestampField('to', T('chargingSession.to'), {
      format: 'YYYY-MM-DD HH:mm'
    }),
    new CalculatedStringField(
      'duration',
      T('chargingSession.duration'),
      session => {
        if (!session.to) return T('chargingSession.ongoing');

        const duration = session.to - session.from;
        const totalMinutes = Math.round(duration / 60000);
        const minutes = totalMinutes % 60;
        const hours = (totalMinutes / 60) | 0;
        return `${hours}:${minutes.toString().padStart(2, '0')}`;
      },
      {unit: 'h:mm'}
    ),
    new NumberField('energy', T('chargingSession.consumption'), {digitsAfterComma: 2}, 'kWh'),
    new CalculatedStringField('paymentType', T('chargingSession.paymentType'), session =>
      translateChargingStationPaymentType(session.paymentType, !!session.splitBillingAgreement)
    ),
    new CalculatedStringField('address', T('chargingSession.address'), session => {
      const address = session.address;
      if (address === undefined) return undefined;

      return `${address.streetAndNumber}, ${address.postalCode} ${address.city}, ${address.countryCode}`;
    }),
    new CalculatedCurrencyField(
      'cost',
      T('chargingSession.cost'),
      session =>
        session.paymentType === ChargingStationPaymentType.SplitBilling ? session.splitBillingRefund : session.cost,
      session => session.currency || 'EUR',
      {
        tooltip: T('chargingSession.cost.info')
      }
    ),
    new CalculatedStringField('rfid', T('chargingSession.rfid'), item => {
      const rule = whitelist.find(x => (x.rfid ? x.rfid.value === item.rfid : false));
      return (rule && rule.rfid!.comment) || item.rfid;
    })
  );

  if (selected !== undefined && onChecked !== undefined) {
    result.splice(
      0,
      0,
      new CheckboxField('selected', '', isChecked, onChecked, {
        autoInsert: 'start',
        width: 20
      })
    );
  }

  if (!hasFeature(AppFeature.HideUsernames)) {
    result.push(new StringField('userName', T('chargingSession.userName')));
  }
  if (hasFeature(AppFeature.SocialLogin)) {
    result.push(
      new CalculatedStringField('emailAddress', T('chargingSession.emailAddress'), item => {
        return item.user?.emailAddress;
      })
    );
  }

  return result;
}
