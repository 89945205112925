import Highcharts from 'highcharts';

import {useMemo} from 'react';

import {Chart} from '../../../components/Chart';
import {BessEnergyColors, IBatteryEnergyData} from '../../../models/BessReading';
import {T} from '../../../utils/Internationalization';
import {useCardContext} from '../../CardContext';

interface BatteryEnergyChartProps {
  dataSeries: IBatteryEnergyData[];
}

const BatteryEnergyChart: React.FC<BatteryEnergyChartProps> = ({dataSeries}) => {
  const {cardDimensions} = useCardContext();

  // Create chart series
  const chartSeries: Highcharts.SeriesOptionsType[] = useMemo(
    () => [
      {
        name: T('bess.tab.historic.gridToBattery'),
        type: 'column',
        data: dataSeries.map(item => [item.timestamp, item.G2BData]),
        tooltip: {
          valueSuffix: ' Wh'
        }
      },
      {
        name: T('bess.tab.historic.batteryToGrid'),
        type: 'column',
        data: dataSeries.map(item => [item.timestamp, item.B2GData * -1]),
        tooltip: {
          valueSuffix: ' Wh'
        }
      },
      {
        name: T('bess.tab.historic.solarToBattery'),
        type: 'column',
        data: dataSeries.map(item => [item.timestamp, item.S2BData]),
        tooltip: {
          valueSuffix: ' Wh'
        }
      },
      {
        name: T('bess.tab.historic.batteryToLoad'),
        type: 'column',
        data: dataSeries.map(item => [item.timestamp, item.B2LData * -1]),
        tooltip: {
          valueSuffix: ' Wh'
        }
      },
      {
        name: T('bess.tab.historic.currentSoC'),
        type: 'spline',
        yAxis: 1,
        data: dataSeries.map(item => [item.timestamp, item.SoCData]),
        tooltip: {
          valueSuffix: ' %'
        },
        marker: {
          symbol: 'circle',
          fillColor: BessEnergyColors.PrimaryText,
          enabled: true,
          radius: 2.5
        }
      }
    ],
    [dataSeries]
  );

  // Highcharts configuration
  const chartConfig: Highcharts.Options = useMemo(
    () => ({
      credits: {enabled: false},
      rangeSelector: {enabled: false},
      chart: {
        type: 'column',
        zooming: {
          type: 'x'
        }
      },
      colors: [
        BessEnergyColors.GridToBattery,
        BessEnergyColors.BatteryToGrid,
        BessEnergyColors.SolarToBattery,
        BessEnergyColors.BatteryToLoad,
        BessEnergyColors.StateOfCharge
      ],
      title: {
        text: T('bess.tab.historic.chart.batteryEnergy'),
        align: 'left',
        style: {
          fontSize: '0.8125rem'
        }
      },
      xAxis: {
        type: 'datetime'
      },
      yAxis: [
        {
          // Primary yAxis
          title: {
            text: T('bess.tab.historic.chart.energy')
          }
        },
        {
          // Secondary yAxis
          title: {
            text: T('bess.tab.historic.currentSoCwithUnit'),
            style: {
              color: BessEnergyColors.StateOfCharge
            }
          }
        }
      ],
      tooltip: {
        shared: true,
        split: false,
        formatter: function (): string {
          const pointsDetails = this.points
            ?.map((point: any) => {
              if (point.series.name === 'Current SoC') {
                return `<span style="color:${point.color}">\u25CF</span> ${point.series.name}: ${point.y.toFixed(2)} %`;
              } else {
                return `<span style="color:${point.color}">\u25CF</span> ${point.series.name}: ${(point.y / 1000).toFixed(2)} kWh`;
              }
            })
            .join('<br/>');

          return `
          ${T('bess.tab.historic.chart.timestamp')}: ${Highcharts.dateFormat('%Y-%m-%d %H:%M:%S', this.x as number)}<br/>
          ${pointsDetails || ''}
        `;
        }
      },
      accessibility: {
        enabled: false
      },
      plotOptions: {
        column: {
          stacking: 'normal', // Use 'normal' for stacked or remove for grouped
          groupPadding: 0.1,
          dataLabels: {
            enabled: false
          }
        },
        series: {
          animation: false
        }
      },
      series: chartSeries,
      navigator: {
        enabled: true
      }
    }),
    [chartSeries]
  );
  return <Chart config={chartConfig} isHighstock={true} cardDimensions={cardDimensions} />;
};

export default BatteryEnergyChart;
