import React from 'react';

import {Button as RsButton} from '../../../components/bootstrap';

import {Button} from '../../../components/ui/button';
import {SelectInput} from '../../../components/ui/select';
import {Edit} from '../../../components/ui-lib/icons/small';
import {LocationType} from '../../../models/Location';
import {T} from '../../../utils/Internationalization';
import {TranslationKey} from '../../../utils/TranslationTerms';

interface LocationTypeEditorProps {
  value: LocationType;
  update: (value: LocationType) => Promise<any>;
  readOnly: boolean;
}
interface LocationTypeEditorState {
  editing: boolean;
  editingValue: string;
  loading: boolean;
}
class LocationTypeEditor extends React.Component<LocationTypeEditorProps, LocationTypeEditorState> {
  static OPTIONS = [
    {value: LocationType.Residential, label: 'locationType.residential'},
    {value: LocationType.Commercial, label: 'locationType.commercial'},
    {value: LocationType.Industrial, label: 'locationType.industrial'}
  ] as {value: LocationType; label: TranslationKey}[];
  static OPTIONS_BY_CODE = LocationTypeEditor.OPTIONS.reduce(
    (map, value) => {
      map[value.value] = value.label;
      return map;
    },
    {} as {[key: string]: TranslationKey}
  );

  options: {value: LocationType; label: string}[];

  constructor(props: LocationTypeEditorProps) {
    super(props);

    this.state = {
      editing: false,
      loading: false,
      editingValue: ''
    };

    this.options = LocationTypeEditor.OPTIONS.map(option => ({
      value: option.value,
      label: T(option.label)
    }));
  }

  handleChange = (value: string) => {
    this.setState({editingValue: value});
  };

  handleClickedEdit = () => {
    this.setState({editing: true, editingValue: this.props.value});
  };

  handleClickedSave = () => {
    this.setState({loading: true});
    this.props
      .update(this.state.editingValue as LocationType)
      .then(() => this.setState({editing: false, loading: false}));
  };

  handleClickedCancel = () => {
    this.setState({editing: false});
  };

  render() {
    const {value, readOnly} = this.props;
    const {editing, editingValue, loading} = this.state;

    if (editing) {
      return (
        <div style={{display: 'flex'}}>
          <SelectInput
            value={editingValue}
            onChange={this.handleChange}
            options={this.options}
            className="tw-w-[200px]"
          />
          <RsButton color="primary" onClick={this.handleClickedSave} style={{marginLeft: '0.3em'}}>
            {T('locationConfiguration.field.save')}
            {loading && <i style={{marginLeft: '0.3em'}} className="fas fa-circle-notch fa-spin" />}
          </RsButton>
          <RsButton onClick={this.handleClickedCancel} style={{marginLeft: '0.3em'}}>
            {T('locationConfiguration.field.cancelEdit')}
          </RsButton>
        </div>
      );
    } else {
      return (
        <span>
          {T(LocationTypeEditor.OPTIONS_BY_CODE[value])}
          &nbsp;
          {!readOnly && (
            <Button variant="ghost_action_btn" size="icon" onClick={this.handleClickedEdit}>
              <Edit />
            </Button>
          )}
        </span>
      );
    }
  }
}

export default LocationTypeEditor;
