import {useEffect, useMemo} from 'react';

import {Label} from 'reactstrap';

import {FormGroup} from '../../components/bootstrap';
import PeriodSelector, {
  getPeriodRangeForTimezone,
  Period,
  PeriodRoundingMode,
  PeriodSettings
} from '../../components/PeriodSelector';
import Table, {SortOrder} from '../../components/Table';
import {SelectInput} from '../../components/ui/select';
import {CardDisplayType, ICardSettingsWithTable} from '../../models/CardSettings';
import {IGasWaterDevice, SensorChannelType} from '../../models/Location';
import {ISensorReading, ISensorReadingList, SensorReadingType} from '../../models/SensorReadings';
import {ITableField} from '../../models/Table';
import {getUnitLabel, Interval, UsageUnit} from '../../models/UsageValue';
import {None} from '../../utils/Arrays';
import {useRetentionPolicy, useSensors} from '../../utils/FunctionalData';
import {useAutoRefresh, useCardLoader} from '../../utils/Hooks';
import {plural, T} from '../../utils/Internationalization';
import * as NumberUtils from '../../utils/NumberUtils';
import {ICardProps} from '../CardType';
import {useCardLocation} from '../CardUtils';
import {CardActions} from '../components';
import {ExportCsv, Reload} from '../components/actions';
import {CardView, cardViewProps, CustomActions, CustomSettings} from '../components/CardView';

import {CardTypeSelector} from '../components/settings/CardTypeSelector';

import {GasOrWaterChart} from './GasOrWaterChart';

export interface IGasOrWaterSettings extends ICardSettingsWithTable, PeriodSettings {
  cardType: CardDisplayType;
  sensorId?: number;
}

interface GasOrWaterCardProps extends ICardProps<IGasOrWaterSettings> {
  available: boolean;
  type: SensorReadingType;
  gasWaterType: SensorChannelType;
  getTableColumns: (precision: number, unit: UsageUnit, timezone: string | undefined) => ITableField<ISensorReading>[];
  seriesLabel: string;
  seriesColor: string;
}

function fillZeroes(items: ISensorReading[], lastReading: number | undefined, standalone: boolean) {
  if (!standalone) lastReading = undefined;

  items.forEach(item => {
    if (item.value === undefined && (lastReading === undefined || item.timestamp <= lastReading)) {
      item.value = 0;
    }
  });
}

function processReadings(usages: ISensorReadingList[], sensor: IGasWaterDevice | undefined, type: SensorReadingType) {
  const data = usages.find(item => item.type === type);
  if (!data || data.intervals.length === 0) return [];

  const lastReading = sensor && sensor.lastReading.timestamp;
  const standalone = sensor ? sensor.standAlone : true;
  const items = data.intervals;
  fillZeroes(items, lastReading, standalone);
  return items;
}

const rowKey = (row: ISensorReading) => row.timestamp;

export function GasOrWaterCard(props: GasOrWaterCardProps) {
  const {fetch, available, type, gasWaterType, settings, updateSettings, seriesLabel, seriesColor, getTableColumns} =
    props;
  const {sensorId, cardType} = settings;

  const location = useCardLocation(settings);
  const locationId = available ? location && location.id : undefined;
  const locationTimezone = location && location.timeZoneId;

  const sensorsRaw = useSensors(fetch, locationId);
  const [retentionPolicy] = useRetentionPolicy(fetch, locationId);

  const sensors = useMemo(() => {
    const whitelist = (sensorsRaw || None)
      .filter(sensor => sensor.id !== undefined)
      .filter(sensor => sensor.inputChannels.some(channel => channel.type === gasWaterType));
    return whitelist;
  }, [sensorsRaw, gasWaterType]);

  const sensor = useMemo(() => sensors.find(sensor => sensor.id === sensorId), [sensors, sensorId]);

  useEffect(() => {
    if (sensorId !== undefined && !sensor) {
      const newSensorId = sensors.length > 0 ? sensors[0].id : undefined;
      if (newSensorId !== sensorId) updateSettings({sensorId: newSensorId});
    }
  }, [updateSettings, sensors, sensorId, sensor]);

  const [data, refreshData] = useCardLoader(
    api => {
      if (locationId === undefined || locationTimezone === undefined || retentionPolicy === undefined) {
        return Promise.resolve(undefined);
      }

      const actualPeriod = getPeriodRangeForTimezone(
        settings,
        locationTimezone,
        retentionPolicy,
        PeriodRoundingMode.EXCLUSIVE
      );
      const graphPeriod = getPeriodRangeForTimezone(
        settings,
        locationTimezone,
        retentionPolicy,
        PeriodRoundingMode.ROUND_DOWN_GRAPH
      );
      return api
        .getSensorReadings(
          [type],
          locationId,
          actualPeriod.from,
          actualPeriod.to,
          actualPeriod.interval,
          settings.sensorId
        )
        .then(readings => ({
          period: {...actualPeriod, interval: readings.intervalLength},
          graphPeriod: {...graphPeriod, interval: readings.intervalLength},
          readings: readings.usages,
          usages: readings.usages.find(item => item.type === type)
        }));
    },
    [
      type,
      locationId,
      locationTimezone,
      settings.period,
      settings.interval,
      settings.from,
      settings.to,
      settings.sensorId,
      retentionPolicy
    ],
    plural('sensorReading'),
    undefined
  );

  useAutoRefresh(refreshData);

  const defaultUnit = useMemo(() => {
    if (sensor === undefined) return UsageUnit.Unknown;

    const channel = sensor.inputChannels.find(channel => channel.type === gasWaterType);
    return channel === undefined ? UsageUnit.Unknown : channel.unit;
  }, [sensor, gasWaterType]);

  const unit = data?.usages?.total?.unit || defaultUnit;
  const precision = useMemo(
    () => (data && data.usages ? NumberUtils.determinePrecisionForData(data.usages.intervals, item => item.value) : 0),
    [data]
  );
  const items = useMemo(() => data && processReadings(data.readings, sensor, type), [data, sensor, type]);

  const sensorOptions = useMemo(
    () =>
      sensors
        .filter(sensor => sensor.id !== undefined)
        .map(sensor => {
          const {id, serialNumber, name} = sensor;
          return {
            label: `${serialNumber} ${name && `- ${name}`}`,
            value: id.toString()
          };
        }),
    [sensors]
  );

  const fields = useMemo(
    () => getTableColumns(precision, unit, locationTimezone),
    [getTableColumns, precision, unit, locationTimezone]
  );

  const handleChangeSensor = (value: string) => {
    const sensorId = value === 'all' ? undefined : parseInt(value);
    updateSettings({sensorId});
  };

  const handleChangePeriod = (settings: PeriodSettings) => {
    updateSettings(settings);
  };

  const actions: CustomActions = state => (
    <CardActions>
      <SelectInput
        className="tw-flex-1"
        name="sensorId"
        value={sensorId === undefined ? 'all' : sensorId.toString()}
        onChange={handleChangeSensor}
        disabled={sensorOptions.length === 0}
        options={[{label: T('gasOrWaterCard.allSensors'), value: 'all'}, ...sensorOptions]}
      />

      <PeriodSelector settings={settings} activeInterval={data?.period?.interval} onChanged={handleChangePeriod} />
      <Reload onReload={refreshData} />
      {state.ready && (
        <ExportCsv
          name={state.title}
          fields={fields}
          items={items || []}
          range={settings}
          location={location}
          settings={settings.table}
        />
      )}
    </CardActions>
  );

  let error: string | undefined;
  if (!available) {
    error = type === SensorReadingType.Gas ? T('gasValues.error.noGasData') : T('waterValues.noWaterData');
  } else if (sensor && sensor.standAlone && data && data.period.interval === Interval.MINUTES_5) {
    error = T('gasOrWaterCard.error.no5MinuteValues');
  } else if (items === undefined || items.length === 0) {
    error = T('gasOrWaterCard.error.noData');
  }

  const renderSettings: CustomSettings<IGasOrWaterSettings> = (settings, updateSettings) => {
    const cardType = settings.cardType;
    const sensorId = settings.sensorId;
    const hasOptions = sensorOptions.length > 0;
    const placeholder = hasOptions ? '' : T('gasOrWaterCard.error.noSensors');

    const handleEditingSensorIdChanged = (value: string) => {
      const sensorId = value === 'all' ? undefined : parseInt(value);
      updateSettings({sensorId});
    };

    const handleEditingCardTypeChanged = (cardType: CardDisplayType) => {
      updateSettings({cardType});
    };
    return (
      <div>
        <CardTypeSelector value={cardType} onChange={handleEditingCardTypeChanged} />
        <FormGroup>
          <Label for="sensorId">{T('gasOrWaterCard.sensor.label')}</Label>
          <SelectInput
            name="sensorId"
            value={sensorId === undefined ? 'all' : sensorId.toString()}
            onChange={handleEditingSensorIdChanged}
            disabled={!hasOptions}
            placeholder={placeholder}
            options={[{label: T('gasOrWaterCard.allSensors'), value: 'all'}, ...sensorOptions]}
          />
        </FormGroup>
      </div>
    );
  };

  const renderTable = () => {
    return (
      <Table
        fields={fields}
        items={items || []}
        rowKey={rowKey}
        noun="reading"
        settings={settings.table}
        updateSettings={table => updateSettings({table})}
      />
    );
  };

  const renderChart = () => {
    if (!location || data === undefined) return null;

    return (
      <GasOrWaterChart
        items={items || []}
        precision={precision}
        unit={getUnitLabel(unit)}
        period={data.graphPeriod}
        label={seriesLabel}
        color={seriesColor}
      />
    );
  };

  return (
    <CardView
      ready={sensorsRaw !== undefined}
      actions={actions}
      customSettings={renderSettings}
      error={error}
      {...cardViewProps(props)}
    >
      {cardType === CardDisplayType.Chart ? renderChart() : renderTable()}
    </CardView>
  );
}

export const DEFAULT_GASWATER_SETTINGS: IGasOrWaterSettings = {
  cardType: CardDisplayType.Chart,
  period: Period.DAYS_7,
  interval: Interval.HOUR,
  table: {
    pageSize: 10,
    sortColumn: 'timestamp',
    sortOrder: SortOrder.DESCENDING,
    columns: [
      {name: 'timestamp', visible: true},
      {name: 'value', visible: true}
    ]
  }
};
