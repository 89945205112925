import React, {useState, useEffect, useMemo} from 'react';

import {
  Button as RsButton,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  FormGroup
} from '../../components/bootstrap';
import {SelectInput} from '../../components/ui/select';
import {IPromiseModalProps, usePromiseModal} from '../../modals/PromiseModal';
import {Device, IFirmwareVersion} from '../../models/Device';
import {DeviceType, getDeviceTypeInfo} from '../../models/DeviceType';
import {T, pluralize} from '../../utils/Internationalization';

const RESTORE_FIRMWARE = 'RESTORE';

export interface IUpdateFirmwareResult {
  firmware: string;
  restart: boolean;
}
interface IUpdateFirmwareModalProps extends IPromiseModalProps<IUpdateFirmwareResult | undefined> {
  type: DeviceType;
  modules: Device[];
  firmwareVersions: IFirmwareVersion[];
}

export const UpdateFirmwareModal = (props: IUpdateFirmwareModalProps) => {
  const {type, modules, firmwareVersions: firmwareVersionsProp} = props;
  const [isOpen, resolve] = usePromiseModal(props);
  const handleClose = () => resolve(undefined);

  const [targetFirmware, setTargetFirmware] = useState<string>('');
  const currentFirmware = useMemo(
    () => modules.reduce<string | undefined>((result, module) => result || module.firmware.current, undefined),
    [modules]
  );

  const firmwareVersions = useMemo(() => {
    // Add current firmware and sort
    const currentFirmwareVersion = firmwareVersionsProp.find(version => version.version === currentFirmware);

    const firmwareVersions = [...firmwareVersionsProp];
    if (currentFirmware && !currentFirmwareVersion) {
      firmwareVersions.push({version: currentFirmware, isDefault: false});
    }

    return firmwareVersions.sort((a, b) => b.version.localeCompare(a.version));
  }, [currentFirmware, firmwareVersionsProp]);

  const serials = useMemo(() => modules.map(module => module.serialNumber), [modules]);

  useEffect(() => {
    let defaultFirmware = '';
    for (let firmware of firmwareVersions) {
      const {version, isDefault} = firmware;
      if (isDefault) defaultFirmware = version;
    }
    if (defaultFirmware === '') defaultFirmware = firmwareVersions[0].version;

    let moduleTargetFirmware = defaultFirmware;
    for (let module of modules) {
      moduleTargetFirmware = module.firmware.target || moduleTargetFirmware;
    }

    setTargetFirmware(moduleTargetFirmware);
  }, [firmwareVersions, modules]);

  const options = useMemo(() => {
    const deviceType = getDeviceTypeInfo(type);
    const options = deviceType.restorable
      ? [{label: T('firmware.reinstallCurrentFirmware'), value: RESTORE_FIRMWARE}]
      : [];

    // Render options
    for (let firmware of firmwareVersions) {
      const {version, isDefault} = firmware;
      options.push({
        label: `${version}${isDefault ? ` (${T('firmware.defaultFirmware')})` : ''}`,
        value: version
      });
    }
    return options;
  }, [firmwareVersions, type]);

  const handleUpdatedTargetFirmware = (value: string) => {
    setTargetFirmware(value);
  };

  const handleSave = () => {
    resolve({firmware: targetFirmware, restart: false});
  };

  const handleSaveAndRestart = () => {
    resolve({firmware: targetFirmware, restart: true});
  };

  const label = Device.getTypeLabelFor(type);
  const isRebootable = Device.isRebootable(type);

  return (
    <Modal isOpen={isOpen} toggle={handleClose}>
      <ModalHeader toggle={handleClose}>{T('firmware.editFirmware.title', {label})}</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label>{pluralize('serialNumber', serials.length)}</Label>
          <Input type="text" plaintext readOnly value={serials.join(', ')} />
        </FormGroup>
        <FormGroup>
          <Label>{T('firmware.editFirmware.currentFirmware')}</Label>
          <Input
            type="text"
            plaintext
            readOnly
            value={currentFirmware || T('firmware.editFirmware.currentFirmwareUnknown')}
          />
        </FormGroup>
        <FormGroup>
          <Label>{T('firmware.editFirmware.targetFirmware')}</Label>
          <SelectInput
            name="targetFirmware"
            value={targetFirmware}
            onChange={handleUpdatedTargetFirmware}
            options={options}
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <RsButton color={isRebootable ? 'link' : 'primary'} onClick={handleSave}>
          {T('firmware.editFirmware.apply')}
        </RsButton>
        {isRebootable && (
          <RsButton color="primary" onClick={handleSaveAndRestart}>
            {T('firmware.editFirmware.applyAndRestart')}
          </RsButton>
        )}
      </ModalFooter>
    </Modal>
  );
};
