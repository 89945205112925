import {SelectInput} from '../../../components/ui/select';
import {CTType, CT_TYPES, CT_TYPE_LABEL_CLOSED, getCTTypeLabel} from '../../../models/CTType';
import {IDevice} from '../../../models/Device';
import {DeviceType} from '../../../models/DeviceType';

interface CTSelectorProps {
  readOnly: boolean;
  value: CTType;
  device: IDevice;
  onChange: (value: string) => void;
}

export const CTSelector = (props: CTSelectorProps) => {
  const {readOnly, value, onChange, device} = props;

  if (device && device.type === DeviceType.ClosedCTHub) {
    return (
      <SelectInput name="ctType" value="3" disabled={true} options={[{label: CT_TYPE_LABEL_CLOSED, value: '3'}]} />
    );
  }

  return (
    <SelectInput
      name="ctType"
      value={value.toString()}
      disabled={readOnly}
      options={CT_TYPES.map(type => ({label: getCTTypeLabel(type), value: type.toString()}))}
      onChange={onChange}
    />
  );
};
