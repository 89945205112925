import React, {useCallback, useContext, useEffect} from 'react';

import {noop} from '..';
import {useAppContext} from '../../../app/context';
import {NumberValue} from '../../../components/inputs/NumberInput';
import {TextInputGroup} from '../../../components/inputs/TextInput';
import {Label} from '../../../components/ui/label';
import {RadioGroup, RadioGroupItem} from '../../../components/ui/radio-group';
import {Slider} from '../../../components/ui/slider';
import {SpotPriceMarket, TariffType} from '../../../models/Tariff';
import {None} from '../../../utils/Arrays';
import {FormProvider} from '../../../utils/FormContext';
import {T} from '../../../utils/Internationalization';
import {useObjectState} from '../../../utils/ObjectState';
import {DynamicRateConfiguration, TariffSettings} from '../../LocationConfiguration/fields/TariffModal';
import {BessAccessContext} from '../BessAccessContext';
import {BessGeoLocation, BessHealthLimit, BessRate} from '../models/BessUnitConfiguration.model';

type SpecificationSectionProps = {
  data: {
    serialNumber: string | undefined;
    geographicLocation: BessGeoLocation;
    maxCapacity: number | undefined;
    maxDischargeSpeed: number | undefined;
    maxChargeSpeed: number | undefined;
    maxInverterPower: number | undefined;
    maxStateOfCharge: number | undefined;
    healthLimits: BessHealthLimit;
    maxCurrent: number | undefined;
    maxPeakPower: number | undefined;
    capacityMaximumPower: number | undefined;
    minStateOfCharge: number | undefined;
    gridFee: number | undefined;
    rate: BessRate;
    customRate?: number | undefined;
    fixedTariff: number | undefined;
    multiplicator?: number | undefined;
    taxMultiplicator?: number | undefined;
  };
  onChange: (field: keyof SpecificationSectionProps['data'], value: any) => void;
  markFormDirty: () => void;
};
interface TariffFormState {
  market?: SpotPriceMarket;
  cost: NumberValue;
  multiplier: NumberValue;
  taxMultiplier: NumberValue;
}

export default function SpecificationSection(props: SpecificationSectionProps) {
  const {data, onChange, markFormDirty} = props;
  const [markets, setMarkets] = React.useState<SpotPriceMarket[]>(None);
  const isReadOnlyUser = useContext(BessAccessContext);

  // @todo: map this later to the correct typings as in LocationConfiguration
  const tariffSettings: any =
    data.rate === 'DYNAMIC_RATE'
      ? {
          type: TariffType.Dynamic,
          dynamic: {
            market: {
              id: 1,
              market: '',
              currency: 'EUR',
              country: 'Belgium',
              name: 'EPEX Belgium'
            }
          }
        }
      : {type: data.rate, flat: None};
  const [tariffFormState, updateTariffFormState] = useObjectState<TariffFormState>(getFormState(tariffSettings));
  const {api} = useAppContext();

  useEffect(() => {
    api.getSpotPriceMarkets().then(markets => {
      markets.sort((a, b) => a.name.localeCompare(b.name));
      setMarkets(markets);
    });
  }, [api]);

  const handleInputChange = useCallback(
    (field: keyof SpecificationSectionProps['data'], value: any) => {
      if (field === 'maxPeakPower') {
        const isNumberValue = typeof value === 'number';
        const receivedValue = !isNumberValue ? Number(value) : value;
        const update = (receivedValue * 3 * 230) / 1000;
        markFormDirty();
        onChange(field, update);
      } else if (field === 'healthLimits') {
        const lowerHealthLimitUpdate = value.lowerHealthStatus < 5 ? 5 : value.lowerHealthStatus;
        const upperHealthLimitUpdate = value.upperHealthStatus > 95 ? 95 : value.upperHealthStatus;
        const update = {
          upperHealthStatus: upperHealthLimitUpdate,
          lowerHealthStatus: lowerHealthLimitUpdate
        };
        markFormDirty();
        onChange(field, update);
      } else {
        markFormDirty();
        onChange(field, value);
      }
    },
    [markFormDirty, onChange]
  );

  function getFormState(settings: TariffSettings): TariffFormState {
    return settings.type === TariffType.Dynamic
      ? {
          market: settings.dynamic.market,
          cost: NumberValue.create(settings.dynamic.cost),
          multiplier: NumberValue.create(settings.dynamic.multiplier),
          taxMultiplier: NumberValue.create(settings.dynamic.taxMultiplier)
        }
      : {
          cost: NumberValue.create(0),
          multiplier: NumberValue.create(),
          taxMultiplier: NumberValue.create()
        };
  }

  // Styles
  const labelStyle = 'tw-font-bold tw-text-base tw-mb-0';
  const radioField = 'tw-flex tw-items-center tw-space-x-2 tw-py-1';
  const radioLabel = 'tw-font-normal tw-self-stretch';
  const fieldStyle = 'tw-flex tw-flex-col tw-mb-4';

  return (
    <div className="tw-w-full tw-relative">
      <FormProvider>
        <div className="tw-grid tw-grid-cols-1 lg:tw-grid-cols-2 lg:tw-gap-8">
          <div>
            <h3 className="">{T('bess.specifications.title')}</h3>
            <TextInputGroup
              name="serialNumber"
              label={T('bess.property.serialNumber')}
              className={fieldStyle}
              readOnly
              disabled={isReadOnlyUser}
              value={data.serialNumber!}
              onChange={noop}
            />
            <TextInputGroup
              name="geographicLocation"
              label={T('bess.property.geographicLocation')}
              className={fieldStyle}
              readOnly
              disabled={isReadOnlyUser}
              value={`(${data.geographicLocation.latitude?.toFixed(6)}, ${data.geographicLocation.longitude?.toFixed(6)})`}
              onChange={noop}
            />
            <TextInputGroup
              type="number"
              name="maximumCapacity"
              label={T('bess.property.maximumCapacity')}
              className={fieldStyle}
              readOnly={isReadOnlyUser}
              disabled={isReadOnlyUser}
              value={data.maxCapacity?.toString() ?? ''}
              suffix="Wh"
              onChange={isReadOnlyUser ? noop : e => handleInputChange('maxCapacity', Number(e))}
            />
            <TextInputGroup
              name="maximumDischargeSpeed"
              type="number"
              label={T('bess.property.maximumDischargeSpeed')}
              className={fieldStyle}
              readOnly={isReadOnlyUser}
              disabled={isReadOnlyUser}
              value={data.maxDischargeSpeed?.toString() ?? ''}
              suffix="W"
              onChange={isReadOnlyUser ? noop : e => handleInputChange('maxDischargeSpeed', Number(e))}
            />
            <TextInputGroup
              type="number"
              name="maximumChargeSpeed"
              label={T('bess.property.maximumChargeSpeed')}
              className={fieldStyle}
              readOnly={isReadOnlyUser}
              disabled={isReadOnlyUser}
              value={data.maxChargeSpeed?.toString() ?? ''}
              suffix="W"
              onChange={isReadOnlyUser ? noop : e => handleInputChange('maxChargeSpeed', Number(e))}
            />
            <TextInputGroup
              type="number"
              name="maxInverterPower"
              label={T('bess.property.maxInverterPower')}
              className={fieldStyle}
              readOnly={isReadOnlyUser}
              disabled={isReadOnlyUser}
              value={data.maxInverterPower?.toString() ?? ''}
              suffix="W"
              onChange={isReadOnlyUser ? noop : e => handleInputChange('maxInverterPower', Number(e))}
            />
            <TextInputGroup
              type="number"
              name="upperHealthLimit"
              label={T('bess.property.upperHealthLimit')}
              className={fieldStyle}
              value={data.healthLimits.upperHealthStatus!.toString()}
              suffix="%"
              readOnly={isReadOnlyUser}
              disabled={isReadOnlyUser}
              onChange={
                isReadOnlyUser
                  ? noop
                  : e => handleInputChange('healthLimits', {...data.healthLimits, upperHealthStatus: Number(e)})
              }
            />
            <TextInputGroup
              type="number"
              name="lowerHealthLimit"
              label={T('bess.property.lowerHealthLimit')}
              className={fieldStyle}
              readOnly={isReadOnlyUser}
              disabled={isReadOnlyUser}
              value={data.healthLimits.lowerHealthStatus?.toString() ?? ''}
              suffix="%"
              onChange={
                isReadOnlyUser
                  ? noop
                  : e => handleInputChange('healthLimits', {...data.healthLimits, lowerHealthStatus: Number(e)})
              }
            />
          </div>
          <div>
            <h3>{T('bess.gridSpecifications.title')}</h3>
            <TextInputGroup
              name="maximumCurrent"
              type="number"
              label={T('bess.property.maximumCurrent')}
              className={fieldStyle}
              readOnly={isReadOnlyUser}
              disabled={isReadOnlyUser}
              value={data.maxCurrent?.toString() ?? ''}
              suffix="A"
              onChange={isReadOnlyUser ? noop : e => handleInputChange('maxCurrent', Number(e))}
            />
            <div className={fieldStyle}>
              <Label htmlFor="maximumPeakPower" className={labelStyle}>
                {`${T('bess.property.maximumPeakPower')}: `}
                <span className="tw-font-normal">{`${data.capacityMaximumPower ? +data.capacityMaximumPower.toFixed(2) : '0.00'} kW`}</span>
              </Label>
              <div className="w-72 sm:w-76 md:w-92 lg:w-124 mb-2">
                <Slider
                  id="capacityMaximumPower"
                  name="capacityMaximumPower"
                  className="tw-my-2"
                  value={[data?.capacityMaximumPower ? data?.capacityMaximumPower : 0]}
                  max={data?.maxPeakPower ?? 100}
                  step={1}
                  onValueChange={isReadOnlyUser ? noop : value => handleInputChange('capacityMaximumPower', value[0])}
                />
              </div>
            </div>
            <TextInputGroup
              name="gridFee"
              type="number"
              label={T('bess.property.gridFee')}
              className={fieldStyle}
              readOnly={isReadOnlyUser}
              disabled={isReadOnlyUser}
              value={data.gridFee?.toString() ?? ''}
              suffix="€/MWh"
              onChange={isReadOnlyUser ? noop : e => handleInputChange('gridFee', Number(e))}
            />
            <div className={fieldStyle}>
              <Label htmlFor="rates" className={labelStyle}>
                {T('bess.property.rates')}
              </Label>
              <RadioGroup
                value={data.rate}
                onValueChange={isReadOnlyUser ? noop : value => handleInputChange('rate', value)}
                disabled={isReadOnlyUser}
              >
                <div className={radioField}>
                  <RadioGroupItem value={BessRate.FixedRate} id="fixedRate" />
                  <Label htmlFor="fixedRate" className={radioLabel}>
                    {T('bess.property.rates.fixedRate')}
                  </Label>
                </div>
                <div className={radioField}>
                  <RadioGroupItem value={BessRate.DynamicRate} id="dynamicRate" />
                  <Label htmlFor="dynamicRate" className={radioLabel}>
                    {T('bess.property.rates.dynamicRate')}
                  </Label>
                </div>
                {/* Hide ‘custom rate’ option radio button for now */}
                {/* <div className={radioField}>
                  <RadioGroupItem value={BessRate.CustomRate} id="customRate" />
                  <Label htmlFor="customRate" className={radioLabel}>
                    {T('bess.property.rates.customRate')}
                  </Label>
                </div> */}
              </RadioGroup>
            </div>
            {data.rate === 'DYNAMIC_RATE' && (
              <DynamicRateConfiguration markets={markets} settings={tariffFormState} update={updateTariffFormState} />
            )}
            {data.rate === 'FIXED_RATE' && (
              <TextInputGroup
                name="fixedTariff"
                type="number"
                label={T('bess.property.fixedTariff')}
                className={fieldStyle}
                readOnly={isReadOnlyUser}
                disabled={isReadOnlyUser}
                value={data.fixedTariff?.toString() ?? ''}
                suffix="EUR"
                onChange={isReadOnlyUser ? noop : e => handleInputChange('fixedTariff', Number(e))}
              />
            )}
            {data.rate === 'DYNAMIC_RATE' && (
              <div>
                <h6>{T('bess.property.multiplicators.title')}</h6>
                <p className="tw-text-sm">{T('bess.property.multiplicators.body')}</p>
              </div>
            )}
            {data.rate === 'DYNAMIC_RATE' && (
              <TextInputGroup
                name="multiplicator"
                label={T('bess.property.multiplicator')}
                className={fieldStyle}
                readOnly={isReadOnlyUser}
                disabled={isReadOnlyUser}
                value={data.multiplicator?.toString() ?? ''}
                onChange={isReadOnlyUser ? noop : e => handleInputChange('multiplicator', Number(e))}
              />
            )}
            {data.rate === 'DYNAMIC_RATE' && (
              <TextInputGroup
                name="taxMultiplicator"
                label={T('bess.property.taxMultiplicator')}
                className={fieldStyle}
                readOnly={isReadOnlyUser}
                disabled={isReadOnlyUser}
                value={data.taxMultiplicator?.toString() ?? ''}
                onChange={isReadOnlyUser ? noop : e => handleInputChange('taxMultiplicator', Number(e))}
              />
            )}
          </div>
        </div>
      </FormProvider>
    </div>
  );
}
