import {SelectInput} from '../../../components/ui/select';
import {LOAD_TYPES, LoadType, getLoadTypeLabel} from '../../../models/Load';

interface HighLevelTypeSelectorProps {
  readOnly: boolean;
  value: LoadType;
  invalid: boolean;
}

export const HighLevelTypeSelector = (props: HighLevelTypeSelectorProps) => {
  const {value, invalid} = props;

  return (
    <SelectInput
      name="type"
      value={value}
      disabled={true}
      options={LOAD_TYPES.map(type => ({label: getLoadTypeLabel(type), value: type}))}
    />
  );
};
