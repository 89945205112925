import Highcharts from 'highcharts';

import {useMemo} from 'react';

import {Chart} from '../../../components/Chart';
import {BessEnergyColors, IBatteryEnergyData} from '../../../models/BessReading';
import {T} from '../../../utils/Internationalization';
import {useCardContext} from '../../CardContext';

interface GridEnergyChartProps {
  dataSeries: IBatteryEnergyData[];
}

const GridEnergyChart: React.FC<GridEnergyChartProps> = ({dataSeries}) => {
  const {cardDimensions} = useCardContext();

  // Create chart series
  const chartSeries: Highcharts.SeriesColumnOptions[] = useMemo(
    () => [
      {
        name: T('bess.tab.historic.gridToLoad'),
        type: 'column',
        data: dataSeries.map(item => [item.timestamp, item.G2LData]),
        tooltip: {
          valueSuffix: ' Wh'
        }
      },
      {
        name: T('bess.tab.historic.gridToBattery'),
        type: 'column',
        data: dataSeries.map(item => [item.timestamp, item.G2BData]),
        tooltip: {
          valueSuffix: ' Wh'
        }
      },
      {
        name: T('bess.tab.historic.solarToGrid'),
        type: 'column',
        data: dataSeries.map(item => [item.timestamp, item.S2GData! * -1]),
        tooltip: {
          valueSuffix: ' Wh'
        }
      },
      {
        name: T('bess.tab.historic.batteryToGrid'),
        type: 'column',
        data: dataSeries.map(item => [item.timestamp, item.B2GData * -1]),
        tooltip: {
          valueSuffix: ' Wh'
        }
      }
    ],
    [dataSeries]
  );

  // Highcharts configuration
  const chartConfig: Highcharts.Options = useMemo(
    () => ({
      credits: {enabled: false},
      rangeSelector: {enabled: false},
      chart: {
        type: 'column',
        zooming: {
          type: 'x'
        }
      },
      // Grid to Load, Grid to Battery, Solar to Grid, Battery to Grid
      colors: [
        BessEnergyColors.GridToLoad,
        BessEnergyColors.GridToBattery,
        BessEnergyColors.SolarToGrid,
        BessEnergyColors.BatteryToGrid
      ],
      title: {
        text: T('bess.tab.historic.chart.gridEnergy'),
        align: 'left',
        style: {
          fontSize: '0.8125rem'
        }
      },
      xAxis: {
        type: 'datetime'
      },
      yAxis: {
        title: {
          text: T('bess.tab.historic.chart.energy')
        }
      },
      tooltip: {
        shared: true,
        split: false,
        formatter: function (): string {
          const pointsDetails = this.points
            ?.map(
              (point: any) =>
                `<span style="color:${point.color}">\u25CF</span> ${point.series.name}: ${(point.y / 1000).toFixed(2)} kWh`
            )
            .join('<br/>');

          return `
          ${T('bess.tab.historic.chart.timestamp')}: ${Highcharts.dateFormat('%Y-%m-%d %H:%M:%S', this.x as number)}<br/>
          ${pointsDetails || ''}
        `;
        }
      },
      accessibility: {
        enabled: false
      },
      plotOptions: {
        column: {
          stacking: 'normal', // Use 'normal' for stacked or remove for grouped
          groupPadding: 0.1,
          dataLabels: {
            enabled: false
          }
        },
        series: {
          animation: false
        }
      },
      series: chartSeries,
      navigator: {
        enabled: true
      }
    }),
    [chartSeries]
  );
  return <Chart config={chartConfig} isHighstock={true} cardDimensions={cardDimensions} />;
};

export default GridEnergyChart;
