import {Checkbox} from '../../../components/ui/checkbox';
import {SelectInput} from '../../../components/ui/select';
import {IApplianceType} from '../../../models/ApplianceType';
import {CIRCUIT_TYPES, getCircuitTypeLabel} from '../../../models/Channel';
import {ApplianceLoad, CircuitLoad, Load, LoadType, ProductionLoad} from '../../../models/Load';
import {T} from '../../../utils/Internationalization';

interface HighLevelOptionsSelectorProps {
  readOnly: boolean;
  load: Load;
  applianceTypes: IApplianceType[];
  onSelectChange: (value: string) => void;
  onCheckboxChange: (checked: boolean) => void;
}
export const HighLevelOptionsSelector = (props: HighLevelOptionsSelectorProps) => {
  const {readOnly, load, applianceTypes, onSelectChange, onCheckboxChange} = props;
  const options = [];
  let name = 'unknown';
  let value = null;

  if (load.type === LoadType.Production) {
    name = 'withHybridInverter';
    value = (load as ProductionLoad).production.withHybridInverter;

    return (
      <Checkbox
        id={name}
        label={T('loadType.production.hybridInverter')}
        name={name}
        checked={value || false}
        onCheckedChange={onCheckboxChange}
        disabled={readOnly}
        testId={name}
      />
    );
  }

  if (load.type === LoadType.Appliance) {
    name = 'applianceType';
    value = (load as ApplianceLoad).appliance.type;

    for (let {id, translation} of applianceTypes) {
      options.push({
        label: translation,
        value: id
      });
    }
  } else if (load.type === LoadType.Subcircuit) {
    name = 'circuitType';
    value = (load as CircuitLoad).subcircuit.type;

    for (let circuitType of CIRCUIT_TYPES) {
      options.push({
        label: getCircuitTypeLabel(circuitType),
        value: circuitType
      });
    }
  }

  return (
    <SelectInput
      name={name}
      value={value || ''}
      onChange={onSelectChange}
      placeholder={T('generic.noOptions')}
      disabled={value === null || readOnly}
      options={options}
    />
  );
};
