import React, {useState, useMemo} from 'react';

import {Input, Form} from 'reactstrap';

import {SplitPane} from '../../components/SplitPane';
import Table, {IPersistedTableSettings} from '../../components/Table';
import {SelectInput} from '../../components/ui/select';
import {UserRights} from '../../models/AuthUser';
import {ICardSettings} from '../../models/CardSettings';
import {IDeviceIncident} from '../../models/DeviceIncident';
import {ITableField, StringField, TimestampField} from '../../models/Table';
import {useDeviceIncidents} from '../../utils/FunctionalData';
import {useAutoRefresh} from '../../utils/Hooks';
import {T} from '../../utils/Internationalization';
import {CardCategory, ICardType, CardTypeKey, CardLocationAwareness, ICardProps} from '../CardType';
import {useCardLocation} from '../CardUtils';
import {CardActions} from '../components';
import {Reload} from '../components/actions';
import {cardViewProps, CardView, CustomActions} from '../components/CardView';

interface IDeviceIncidentWithTimestamp extends IDeviceIncident {
  timestampMillis: number;
}

interface DeviceIncidentsSettings extends ICardSettings {
  incidentsTable: IPersistedTableSettings;
  detailsTable: IPersistedTableSettings;
  detailMode: DetailMode;
}

enum DetailMode {
  STATISTICS = 'statistics',
  CUSTOM_PROPERTIES = 'custom_properties',
  LOG = 'log'
}

const incidentsRowKey = (item: IDeviceIncident, index: number) => index;
const detailsRowKey = (item: {first: string; second: string}) => item.first;

const DeviceIncidents = (props: ICardProps<DeviceIncidentsSettings>) => {
  const {fetch, settings, updateSettings} = props;

  const location = useCardLocation(settings);
  const serialNumber = location && location.serialNumber;
  const {incidentsTable, detailsTable, detailMode} = settings;

  const [filter, setFilter] = useState('');
  const [incidents, refreshIncidents] = useDeviceIncidents(fetch, serialNumber);
  const [selectedIncident, setSelectedIncident] = useState<IDeviceIncident>();
  useAutoRefresh(refreshIncidents);

  const incidentsColumns: ITableField<IDeviceIncidentWithTimestamp>[] = useMemo(
    () => [
      new TimestampField('timestampMillis', T('deviceIncidents.field.timestamp'), {
        alwaysVisible: true,
        autoInsert: 'start',
        format: 'YYYY-MM-DD HH:mm:ss'
      }),
      new StringField('serialNumber', T('deviceIncidents.field.serialNumber')),
      new StringField('reason', T('deviceIncidents.field.reason'))
    ],
    []
  );

  const detailColumns: ITableField<{first: string; second: string}>[] = useMemo(
    () => [
      new StringField('first', T('deviceIncidents.field.key')),
      new StringField('second', T('deviceIncidents.field.value'))
    ],
    []
  );

  const details = useMemo(() => {
    if (selectedIncident === undefined) return [];

    const getDetails = () => {
      if (detailMode === DetailMode.CUSTOM_PROPERTIES) {
        return selectedIncident.customProperties.keyValues;
      } else if (detailMode === DetailMode.STATISTICS) {
        return selectedIncident.statistics.keyValues;
      } else {
        return [];
      }
    };
    const details = getDetails();
    if (filter === '') return details;

    const filterLower = filter.toLowerCase();
    return details.filter(item => item.first.toLowerCase().includes(filterLower));
  }, [selectedIncident, detailMode, filter]);

  const handleDetailModeChanged = (value: string) => {
    const detailMode = value as DetailMode;
    updateSettings({detailMode});
  };

  const handleFilterChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
    setFilter(e.currentTarget.value);
  };

  const actions: CustomActions = state => (
    <CardActions>
      <Reload onReload={refreshIncidents} />
      {state.ready && (
        <Form inline style={{position: 'absolute', top: 18, left: '50%'}}>
          <SelectInput
            className="tw-w-auto"
            value={detailMode}
            onChange={handleDetailModeChanged}
            options={[
              {label: T('deviceIncidents.detail.log'), value: DetailMode.LOG},
              {label: T('deviceIncidents.detail.statistics'), value: DetailMode.STATISTICS},
              {label: T('deviceIncidents.detail.customProperties'), value: DetailMode.CUSTOM_PROPERTIES}
            ]}
          />
          <Input type="text" value={filter} onChange={handleFilterChanged} />
        </Form>
      )}
    </CardActions>
  );

  return (
    <CardView actions={actions} {...cardViewProps(props)}>
      <SplitPane>
        <Table
          items={incidents}
          fields={incidentsColumns}
          rowKey={incidentsRowKey}
          settings={incidentsTable}
          updateSettings={table => updateSettings({incidentsTable: table})}
          onClickedRow={setSelectedIncident}
          noDefaultSort={true}
        />
        {detailMode !== DetailMode.LOG ? (
          <Table
            items={details}
            fields={detailColumns}
            rowKey={detailsRowKey}
            settings={detailsTable}
            updateSettings={table => updateSettings({detailsTable: table})}
          />
        ) : (
          <pre style={{height: '100%'}}>
            {selectedIncident ? selectedIncident.log : T('deviceIncidents.noIncidentSelected')}
          </pre>
        )}
      </SplitPane>
    </CardView>
  );
};

const DEFAULT_SETTINGS: DeviceIncidentsSettings = {
  incidentsTable: {
    pageSize: 10,
    columns: [
      {name: 'serialNumber', visible: false},
      {name: 'reason', visible: true}
    ]
  },
  detailsTable: {
    pageSize: 10,
    columns: [
      {name: 'first', visible: true},
      {name: 'second', visible: true}
    ]
  },
  detailMode: DetailMode.STATISTICS
};
const CARD: ICardType<DeviceIncidentsSettings> = {
  type: CardTypeKey.DeviceIncidents,
  title: 'deviceIncidents.title',
  description: 'deviceIncidents.description',
  categories: [CardCategory.SERVICEDESK],
  rights: UserRights.ServiceDesk,
  width: 4,
  height: 2,
  defaultSettings: DEFAULT_SETTINGS,
  locationAware: CardLocationAwareness.RequiresRegular,
  cardClass: DeviceIncidents
};
export default CARD;
