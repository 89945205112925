import React, {useMemo, useEffect, useCallback, PropsWithChildren} from 'react';

import {Form, FormGroup, Input, Col, ModalHeader, Modal, ModalBody, FormFeedback} from 'reactstrap';

import {Label, Button} from '../../../components/bootstrap';

import {SearchableSelectInput} from '../../../components/SearchableSelectInput';
import {SelectInput} from '../../../components/ui/select';
import {usePromiseModal, IPromiseModalProps} from '../../../modals/PromiseModal';
import {
  FluviusCampaignTemplate,
  FluviusMeasurementCampaignType,
  FluviusMeasurementTemplateCode,
  FluviusCampaignInput,
  FluviusCampaignConfiguration
} from '../../../models/FluviusMeasurementCampaign';
import {IOrganizationRegion, IMeasuringCase} from '../../../models/Organization';

import {Phase} from '../../../models/Phase';

import {useObjectState} from '../../../utils/ObjectState';

import {BoardNameInputForm} from './BoardNameInputForm';

import {CabinTemplateForm} from './CabinTemplateForm';
import {defaultFormErrors, defaultFormState, useMeasurementCampaignFormState} from './CampaignFormState';

import {
  FLUVIUS_CAMPAIGN_TEMPLATES,
  FLUVIUS_CAMPAIGN_REASONS,
  FLUVIUS_CAMPAIGN_CITIES,
  FLUVIUS_TEMPLATE_NETONLY,
  FLUVIUS_TEMPLATE_NET2X3F,
  FLUVIUS_TEMPLATE_NET8X1F,
  FLUVIUS_CAMPAIGN_ALL_TEMPLATES,
  FLUVIUS_TEMPLATE_3FN_NET,
  FLUVIUS_TEMPLATE_3FN_VERTREK,
  FLUVIUS_TEMPLATE_4X1F,
  isBoardNameAvailable,
  validateFormState
} from './FluviusModels';
import {TemplateSubmeter, Template, TemplateSubmeterType} from './Models';

const CITY_OPTIONS = FLUVIUS_CAMPAIGN_CITIES.map(city => ({
  value: city,
  label: city
}));

interface CreateFluviusMeasurementCampaignProps extends IPromiseModalProps<FluviusCampaignConfiguration | undefined> {
  regions: IOrganizationRegion[];
  measuringCases: IMeasuringCase[];

  existing?: FluviusCampaignConfiguration;
  readOnly: boolean;
}

function getSetType(set: IMeasuringCase): FluviusMeasurementCampaignType {
  return set.name.startsWith('M') ? FluviusMeasurementCampaignType.Cabine : FluviusMeasurementCampaignType.Voetpadkast;
}

function getNamePlaceholder(type: FluviusMeasurementCampaignType) {
  switch (type) {
    case FluviusMeasurementCampaignType.Cabine:
      return 'Cabine nr + cabine naam';
    case FluviusMeasurementCampaignType.Voetpadkast:
      return 'VPK nr + straat';
    default:
      return '';
  }
}

function getSecondaryTemplateOptions(primary: Template | undefined): Template[] {
  if (primary === undefined) return [];
  if (
    primary.code === FluviusMeasurementTemplateCode.Net4x3F ||
    primary.code === FluviusMeasurementTemplateCode.Net16x1F
  ) {
    return [FLUVIUS_TEMPLATE_NETONLY];
  } else if (
    primary.code === FluviusMeasurementTemplateCode.Net2x3F ||
    primary.code === FluviusMeasurementTemplateCode.Net8x1F
  ) {
    return [FLUVIUS_TEMPLATE_NET2X3F, FLUVIUS_TEMPLATE_NET8X1F];
  } else return [];
}

function readTemplateType(
  set: IMeasuringCase | undefined,
  configuration: FluviusCampaignConfiguration,
  template: FluviusCampaignTemplate
): FluviusMeasurementTemplateCode {
  if (template.template !== FluviusMeasurementTemplateCode.THREE_PHASE || set === undefined) {
    return template.template;
  }

  const setType = getSetType(set);
  if (setType === FluviusMeasurementCampaignType.Cabine) {
    return FluviusMeasurementTemplateCode.Net;
  } else if (configuration.type === FluviusMeasurementCampaignType.Cabine) {
    if (template.inputs[0].grid) {
      return FluviusMeasurementTemplateCode.THREE_PHASE_N;
    } else {
      return FluviusMeasurementTemplateCode.THREE_PHASE_V;
    }
  } else {
    return FluviusMeasurementTemplateCode.THREE_PHASE;
  }
}

function readSubmeter(template: Template, index: number, input: FluviusCampaignInput): TemplateSubmeter {
  const templateInput = template.submeters[index];
  return {
    definition: templateInput,
    enabled: input.enabled,
    number: input.nr ? input.nr.toString() : '',
    name: input.name || '',
    phase: input.phase || '_'
  };
}

function generateSubmetersFromTemplate(template: Template): TemplateSubmeter[] {
  return template.submeters.map(submeter => ({
    definition: submeter,
    enabled: true,
    name: '',
    number: '',
    phase: Phase.L2
  }));
}

function normalizeTemplateType(type: FluviusMeasurementTemplateCode) {
  switch (type) {
    case FluviusMeasurementTemplateCode.THREE_PHASE_N:
    case FluviusMeasurementTemplateCode.THREE_PHASE_V:
    case FluviusMeasurementTemplateCode.Net:
      return FluviusMeasurementTemplateCode.THREE_PHASE;
    default:
      return type;
  }
}

function ValidationMessage({children}: PropsWithChildren<{}>) {
  return <p className="tw-text-xs tw-text-[#dc3545] tw-mt-1">{children}</p>;
}

export const CreateFluviusMeasurementCampaign = (props: CreateFluviusMeasurementCampaignProps) => {
  const {existing, regions, measuringCases, readOnly} = props;
  const [isOpen, resolve] = usePromiseModal(props);

  const [state, stateActor] = useMeasurementCampaignFormState(defaultFormState);
  const [errors, updateErrors] = useObjectState(defaultFormErrors);

  useEffect(() => {
    const set = existing && measuringCases.find(x => x.id === existing.measuringCase);
    const reason = existing && FLUVIUS_CAMPAIGN_REASONS.find(r => r.name === existing.reason);
    const templateACode = existing && readTemplateType(set, existing, existing.geniusA);
    const templateBCode = existing && existing.geniusB && readTemplateType(set, existing, existing.geniusB);
    const templateA = FLUVIUS_CAMPAIGN_ALL_TEMPLATES.find(t => t.code === templateACode);
    const templateB = FLUVIUS_CAMPAIGN_ALL_TEMPLATES.find(t => t.code === templateBCode);

    if (existing) {
      stateActor.set({
        name: existing.name,
        type: existing.type,
        cabinId: existing.cabinId || '',
        vpkId: existing.vpkId || '',
        set,
        region: regions.find(x => x.id === existing.region),
        city: existing.city,
        streetAndNumber: existing.address,
        reason: reason ? reason.code : '__',
        notes: existing.notes,
        voltage: '400V',
        transfoAId: existing.geniusA.transfoId || '',
        transfoBId: (existing.geniusB && existing.geniusB.transfoId) || '',
        templateA: FLUVIUS_CAMPAIGN_ALL_TEMPLATES.find(t => t.code === templateACode),
        templateABoardNumber: existing.geniusA.boardNr || '',
        templateABoardName: (existing && existing.geniusA.boardName) || '',
        templateASubmeters: ((templateA && existing.geniusA.inputs) || []).map((input, index) =>
          readSubmeter(templateA!, index, input)
        ),
        templateB: FLUVIUS_CAMPAIGN_ALL_TEMPLATES.find(t => t.code === templateBCode),
        templateBBoardNumber: (existing.geniusB && existing.geniusB.boardNr) || '',
        templateBBoardName: (existing.geniusB && existing.geniusB.boardName) || '',
        templateBSubmeters: ((templateB && existing && existing.geniusB && existing.geniusB.inputs) || []).map(
          (input, index) => readSubmeter(templateB!, index, input)
        )
      });
    } else {
      stateActor.set(defaultFormState);
    }
  }, [existing, measuringCases, regions, stateActor]);

  const handleNameChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
    stateActor.update({name: e.currentTarget.value});
    updateErrors({name: undefined});
  };

  const handleTypeChanged = (value: string) => {
    stateActor.update({
      type: value as FluviusMeasurementCampaignType,
      templateA: undefined,
      templateB: undefined
    });
    updateErrors({typeRequired: false});
  };

  const handleCabinIdChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
    stateActor.update({cabinId: e.currentTarget.value});
    updateErrors({cabinId: undefined});
  };

  const handleTransfoAIdChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
    stateActor.update({transfoAId: e.currentTarget.value});
    updateErrors({transfoAId: undefined});
  };

  const handleTransfoBIdChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
    stateActor.update({transfoBId: e.currentTarget.value});
    updateErrors({transfoBId: undefined});
  };

  const handleVPKIdChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
    stateActor.update({vpkId: e.currentTarget.value});
    updateErrors({vpkId: undefined});
  };

  const handleSetChanged = (value: string) => {
    const selectedId = parseInt(value);
    const selectedSet = measuringCases.find(c => c.id === selectedId);
    if (!state.set || (selectedSet && getSetType(state.set) !== getSetType(selectedSet))) {
      stateActor.update({
        set: selectedSet,
        templateA: undefined,
        templateB: undefined
      });
    } else {
      stateActor.update({set: selectedSet});
    }
  };

  const handleRegionChanged = (value: string) => {
    const selectedId = parseInt(value);
    const region = regions.find(region => region.id === selectedId);
    stateActor.update({region});
    updateErrors({regionRequired: false});
  };

  const handleCityChanged = useCallback(
    (value: string) => {
      stateActor.update({city: value});
      updateErrors({cityRequired: false});
    },
    [stateActor, updateErrors]
  );

  const handleStreetAndNumberChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
    stateActor.update({streetAndNumber: e.currentTarget.value});
    updateErrors({streetAndNumber: undefined});
  };

  const handleReasonChanged = (value: string) => {
    stateActor.update({reason: value});
    updateErrors({reasonRequired: false});
  };

  const handleNotesChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
    stateActor.update({notes: e.currentTarget.value});
    updateErrors({notes: undefined});
  };

  const handleTemplateASubmeterChanged = (index: number, submeter: TemplateSubmeter) => {
    const submeters = [...state.templateASubmeters];
    submeters[index] = submeter;
    stateActor.update({templateASubmeters: submeters});

    const submeterErrors = [...errors.templateASubmeters];
    submeterErrors[index] = {phaseInvalid: false};
    updateErrors({templateASubmeters: submeterErrors});
  };

  const handleTemplateBSubmeterChanged = (index: number, submeter: TemplateSubmeter) => {
    const submeters = [...state.templateBSubmeters];
    submeters[index] = submeter;
    stateActor.update({templateBSubmeters: submeters});

    const submeterErrors = [...errors.templateBSubmeters];
    submeterErrors[index] = {phaseInvalid: false};
    updateErrors({templateBSubmeters: submeterErrors});
  };

  const handleTemplateAChanged = (value: string) => {
    const code = value;
    const template = FLUVIUS_CAMPAIGN_ALL_TEMPLATES.find(t => t.code === code);
    let submeters: TemplateSubmeter[] = [];
    if (template) submeters = generateSubmetersFromTemplate(template);

    stateActor.update({
      templateA: template,
      templateASubmeters: submeters
    });
    updateErrors({templateASubmeters: []});

    const availableSecondaries = getSecondaryTemplateOptions(template);
    if (state.templateB && availableSecondaries.find(t => t.code === state.templateB!.code) === undefined) {
      stateActor.update({
        templateB: undefined,
        templateBSubmeters: []
      });
      updateErrors({templateASubmeters: []});
    }
  };

  const handleTemplateBChanged = (value: string) => {
    const code = value;
    const template = FLUVIUS_CAMPAIGN_ALL_TEMPLATES.find(t => t.code === code);
    let submeters: TemplateSubmeter[] = [];
    if (template) submeters = generateSubmetersFromTemplate(template);

    stateActor.update({
      templateB: template,
      templateBSubmeters: submeters
    });
    updateErrors({templateBSubmeters: []});
  };

  const handleTemplateABoardNumberChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
    stateActor.update({templateABoardNumber: e.currentTarget.value});
    updateErrors({templateABoardNumber: undefined});
  };

  const handleTemplateBBoardNumberChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
    stateActor.update({templateBBoardNumber: e.currentTarget.value});
    updateErrors({templateBBoardNumber: undefined});
  };

  const handleTemplateABoardNameChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
    stateActor.update({templateABoardName: e.currentTarget.value});
    updateErrors({templateABoardName: undefined});
  };

  const handleTemplateBBoardNameChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
    stateActor.update({templateBBoardName: e.currentTarget.value});
    updateErrors({templateBBoardName: undefined});
  };

  const handleClickedSave = () => {
    const [valid, errors] = validateFormState(state);
    updateErrors(errors);
    if (!valid) return;

    const {region, set, templateA, templateB} = state;
    if (!templateA || !region || !set) return;

    const templateARequest: FluviusCampaignTemplate = {
      template: normalizeTemplateType(templateA.code),
      boardNr: state.templateABoardNumber,
      boardName: state.templateABoardName,
      transfoId: state.transfoAId,
      inputs: state.templateASubmeters.map((item, index) => {
        const input = templateA.submeters[index];
        return {
          grid: input.main || false,
          enabled: item.enabled,
          nr: input.main ? undefined : item.number,
          name: input.main ? 'Net' : item.name,
          phase:
            item.definition.type === TemplateSubmeterType.Phase3
              ? undefined
              : item.phase === '_'
                ? Phase.L2
                : item.phase
        };
      })
    };

    let templateBRequest: FluviusCampaignTemplate | undefined;
    if (templateB) {
      templateBRequest = {
        template: normalizeTemplateType(templateB.code),
        boardNr: state.templateBBoardNumber,
        boardName: state.templateBBoardName,
        transfoId: state.transfoBId,
        inputs: state.templateBSubmeters.map((item, index) => {
          const input = templateB.submeters[index];
          return {
            grid: input.main || false,
            enabled: item.enabled,
            nr: input.main ? undefined : item.number,
            name: input.main ? 'Net' : item.name,
            phase:
              item.definition.type === TemplateSubmeterType.Phase3
                ? undefined
                : item.phase === '_'
                  ? Phase.L2
                  : item.phase
          };
        })
      };
    }

    const request: FluviusCampaignConfiguration = {
      type: state.type,
      region: region.id,
      measuringCase: set.id,
      name: state.name,
      vpkId: state.vpkId,
      cabinId: state.cabinId,
      city: state.city,
      address: state.streetAndNumber,
      reason: FLUVIUS_CAMPAIGN_REASONS.find(r => r.code === state.reason)!.name,
      notes: state.notes,
      geniusA: templateARequest,
      geniusB: templateBRequest
    };
    resolve(request);
  };

  const availableSets = useMemo(() => {
    let result = measuringCases.filter(x => x.region === undefined || state.region?.id === x.region.id);
    if (state.type === FluviusMeasurementCampaignType.Voetpadkast) {
      result = result.filter(x => getSetType(x) === FluviusMeasurementCampaignType.Voetpadkast);
    }

    return result;
  }, [measuringCases, state.region, state.type]);

  const handleClose = () => resolve(undefined);

  const labelWidth = 4;
  const inputWidth = 8;
  let primaryTemplateOptions: {label: string; value: string}[] = [];
  let secondaryTemplateOptions: {label: string; value: string}[] = [];
  if (state.set) {
    const setType = getSetType(state.set);
    if (
      state.type === FluviusMeasurementCampaignType.Cabine &&
      setType === FluviusMeasurementCampaignType.Voetpadkast
    ) {
      primaryTemplateOptions = [FLUVIUS_TEMPLATE_3FN_NET, FLUVIUS_TEMPLATE_3FN_VERTREK, FLUVIUS_TEMPLATE_4X1F].map(
        value => ({
          label: value.name,
          value: value.code
        })
      );
    } else {
      primaryTemplateOptions = FLUVIUS_CAMPAIGN_TEMPLATES.filter(t => t.type === setType).map(value => ({
        label: value.name,
        value: value.code
      }));
    }
    secondaryTemplateOptions = getSecondaryTemplateOptions(state.templateA).map(value => ({
      label: value.name,
      value: value.code
    }));
  }

  const form = (
    <Form
      style={{
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 10,
        height: '100%',
        overflowX: 'hidden',
        overflowY: 'auto',
        minHeight: 800
      }}
    >
      <div style={{maxWidth: 500}}>
        <FormGroup row>
          <Label sm={labelWidth}>Regio</Label>
          <Col sm={inputWidth}>
            <SelectInput
              options={regions.map(region => ({
                label: region.name ?? '',
                value: region.id.toString()
              }))}
              value={state.region === undefined ? undefined : state.region.id.toString()}
              onChange={handleRegionChanged}
              invalid={errors.regionRequired}
              disabled={readOnly}
              placeholder="(Kies regio)"
            />
            {errors.regionRequired && <ValidationMessage>Gelieve een regio op te geven</ValidationMessage>}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={labelWidth}>Type meting</Label>
          <Col sm={inputWidth}>
            <SelectInput
              options={[
                {label: 'Cabine', value: FluviusMeasurementCampaignType.Cabine},
                {label: 'Voetpadkast', value: FluviusMeasurementCampaignType.Voetpadkast}
              ]}
              value={state.type === FluviusMeasurementCampaignType.__unset__ ? undefined : state.type}
              onChange={handleTypeChanged}
              invalid={errors.typeRequired}
              disabled={readOnly}
              placeholder="(Kies optie)"
            />
            {errors.typeRequired && <ValidationMessage>Gelieve een type te selecteren</ValidationMessage>}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={labelWidth}>Naam</Label>
          <Col sm={inputWidth}>
            <Input
              type="text"
              value={state.name}
              onChange={handleNameChanged}
              invalid={errors.name !== undefined}
              placeholder={getNamePlaceholder(state.type)}
              disabled={readOnly}
            />
            {errors.name && <FormFeedback>{errors.name}</FormFeedback>}
          </Col>
        </FormGroup>
        {state.type === FluviusMeasurementCampaignType.Cabine && (
          <FormGroup row>
            <Label sm={labelWidth}>Cabine ID</Label>
            <Col sm={inputWidth}>
              <Input
                type="text"
                value={state.cabinId}
                onChange={handleCabinIdChanged}
                invalid={errors.cabinId !== undefined}
                disabled={readOnly}
              />
              {errors.cabinId && <FormFeedback>{errors.cabinId}</FormFeedback>}
            </Col>
          </FormGroup>
        )}
        {state.type === FluviusMeasurementCampaignType.Voetpadkast && (
          <FormGroup row>
            <Label sm={labelWidth}>Voetpadkast ID</Label>
            <Col sm={inputWidth}>
              <Input
                type="text"
                value={state.vpkId}
                onChange={handleVPKIdChanged}
                invalid={errors.vpkId !== undefined}
                disabled={readOnly}
              />
              {errors.vpkId && <FormFeedback>{errors.vpkId}</FormFeedback>}
            </Col>
          </FormGroup>
        )}
        <FormGroup row>
          <Label sm={labelWidth}>Gemeente</Label>
          <Col sm={inputWidth}>
            <SearchableSelectInput
              value={state.city}
              onChange={handleCityChanged}
              invalid={errors.cityRequired}
              options={CITY_OPTIONS}
              placeholder="(Kies een gemeente)"
              disabled={readOnly}
            />
            {errors.cityRequired && <FormFeedback>Gelieve een gemeente in te geven</FormFeedback>}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={labelWidth}>Straat + huisnummer</Label>
          <Col sm={inputWidth}>
            <Input
              type="text"
              value={state.streetAndNumber}
              onChange={handleStreetAndNumberChanged}
              invalid={errors.streetAndNumber !== undefined}
              disabled={readOnly}
            />
            {errors.streetAndNumber && <FormFeedback>{errors.streetAndNumber}</FormFeedback>}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={labelWidth}>Reden</Label>
          <Col sm={inputWidth}>
            <SelectInput
              value={state.reason === '__' ? undefined : state.reason}
              onChange={handleReasonChanged}
              invalid={errors.reasonRequired}
              disabled={readOnly}
              options={FLUVIUS_CAMPAIGN_REASONS.filter(r => r.code !== '__').map(reason => ({
                label: reason.name,
                value: reason.code
              }))}
              placeholder="(Kies reden)"
            />
            {errors.reasonRequired && <ValidationMessage>{errors.reasonRequired}</ValidationMessage>}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={labelWidth}>Opmerking</Label>
          <Col sm={inputWidth}>
            <Input
              type="textarea"
              value={state.notes}
              onChange={handleNotesChanged}
              invalid={errors.notes !== undefined}
              disabled={readOnly}
            />
            {errors.notes && <FormFeedback>{errors.notes}</FormFeedback>}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={labelWidth}>
            {state.type === FluviusMeasurementCampaignType.Voetpadkast ? 'Meetset' : 'Meetkoffer'}
          </Label>
          <Col sm={inputWidth}>
            <SelectInput
              value={state.set === undefined ? undefined : state.set.id.toString()}
              onChange={handleSetChanged}
              disabled={readOnly}
              invalid={errors.setRequired}
              placeholder="(Kies meetkoffer)"
              options={availableSets.map(set => ({
                label: set.name,
                value: set.id.toString()
              }))}
            />
            {errors.setRequired && <ValidationMessage>Gelieve een meetset in te geven</ValidationMessage>}
          </Col>
        </FormGroup>
      </div>
      {state.set && <hr />}
      {state.set && (
        <div style={{maxWidth: 500}}>
          <FormGroup row>
            {getSetType(state.set) === FluviusMeasurementCampaignType.Cabine && (
              <Label sm={labelWidth}>
                <strong>Template gateway 1.0</strong>
              </Label>
            )}
            {getSetType(state.set) === FluviusMeasurementCampaignType.Voetpadkast && (
              <Label sm={labelWidth}>
                <strong>Template</strong>
              </Label>
            )}
            <Col sm={inputWidth}>
              <SelectInput
                value={state.templateA ? state.templateA.code : undefined}
                onChange={handleTemplateAChanged}
                disabled={readOnly}
                placeholder="(Selecteer template)"
                options={primaryTemplateOptions}
              />
            </Col>
          </FormGroup>
          {state.templateA && state.type === FluviusMeasurementCampaignType.Cabine && (
            <FormGroup row>
              <Label sm={labelWidth}>Transfo ID</Label>
              <Col sm={inputWidth}>
                <Input
                  type="text"
                  value={state.transfoAId}
                  onChange={handleTransfoAIdChanged}
                  invalid={errors.transfoAId !== undefined}
                  disabled={readOnly}
                />
                {errors.transfoAId && <FormFeedback>{errors.transfoAId}</FormFeedback>}
              </Col>
            </FormGroup>
          )}
        </div>
      )}
      {state.templateA && isBoardNameAvailable(state.templateA) && (
        <BoardNameInputForm
          number={state.templateABoardNumber}
          name={state.templateABoardName}
          numberError={errors.templateABoardNumber}
          nameError={errors.templateABoardName}
          onNumberChanged={handleTemplateABoardNumberChanged}
          onNameChanged={handleTemplateABoardNameChanged}
          readOnly={readOnly}
        />
      )}
      {state.templateA && (
        <CabinTemplateForm
          submeters={state.templateASubmeters}
          errors={errors.templateASubmeters}
          onSubmeterChanged={handleTemplateASubmeterChanged}
          readOnly={readOnly}
        />
      )}
      {state.set && getSetType(state.set) === FluviusMeasurementCampaignType.Cabine && <hr />}
      {state.set && getSetType(state.set) === FluviusMeasurementCampaignType.Cabine && (
        <div style={{maxWidth: 500}}>
          <FormGroup row>
            <Label sm={labelWidth}>
              <strong>Template gateway 2.0</strong>
            </Label>
            <Col sm={inputWidth}>
              <SelectInput
                value={state.templateB ? state.templateB.code : undefined}
                onChange={handleTemplateBChanged}
                disabled={readOnly}
                placeholder="(Selecteer template)"
                options={secondaryTemplateOptions}
              />
            </Col>
          </FormGroup>
          {state.templateB && state.type === FluviusMeasurementCampaignType.Cabine && (
            <FormGroup row>
              <Label sm={labelWidth}>Transfo ID</Label>
              <Col sm={inputWidth}>
                <Input
                  type="text"
                  value={state.transfoBId}
                  onChange={handleTransfoBIdChanged}
                  invalid={errors.transfoBId !== undefined}
                  disabled={readOnly}
                />
                {errors.transfoBId && <FormFeedback>{errors.transfoBId}</FormFeedback>}
              </Col>
            </FormGroup>
          )}
        </div>
      )}
      {state.templateB && (
        <BoardNameInputForm
          number={state.templateBBoardNumber}
          name={state.templateBBoardName}
          numberError={errors.templateBBoardNumber}
          nameError={errors.templateBBoardName}
          onNumberChanged={handleTemplateBBoardNumberChanged}
          onNameChanged={handleTemplateBBoardNameChanged}
          readOnly={readOnly}
        />
      )}
      {state.templateB && (
        <CabinTemplateForm
          submeters={state.templateBSubmeters}
          errors={errors.templateBSubmeters}
          onSubmeterChanged={handleTemplateBSubmeterChanged}
          readOnly={readOnly}
        />
      )}
      {!readOnly && (
        <div style={{maxWidth: 500}}>
          <FormGroup row>
            <Col sm={{size: inputWidth, offset: labelWidth}}>
              <Button color="primary" onClick={handleClickedSave}>
                Opslaan
              </Button>
            </Col>
          </FormGroup>
        </div>
      )}
    </Form>
  );

  return (
    <Modal isOpen={isOpen} toggle={handleClose} size="xl">
      <ModalHeader toggle={handleClose}>{existing ? 'Meetcampagne bekijken' : 'Meetcampagne aanmaken'}</ModalHeader>
      <ModalBody>{form}</ModalBody>
    </Modal>
  );
};
