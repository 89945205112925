import {SearchFieldSpecies} from './Organization';

export interface ObservedBessData {
  name: string;
  displayName: string;
  value: number | null;
  species: string; // SearchFieldSpecies
}

export interface IBessReading {
  timestamp: number; // in milliseconds
  values: ObservedBessData[];
}

export interface IBatteryEnergyData {
  timestamp: number;
  G2BData: number;
  B2GData: number;
  S2BData: number;
  B2LData: number;
  G2LData: number;
  S2GData: number;
  SoCData: number; // Current State of Charge
  // DesiredSoC?: number; // @wait until notice from Siebe
}

export enum BessEnergyColors {
  GridToBattery = '#FFDD80',
  BatteryToGrid = '#9E9693',
  SolarToBattery = '#7AB3A4',
  BatteryToLoad = '#3B568C',
  GridToLoad = '#6C8AC1',
  SolarToGrid = '#417264',
  StateOfCharge = '#D6C9C2',
  PrimaryText = '333334'
}
