import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React from 'react';

/* Tryout/Test PieChart */

interface HighPieChartProps {
  title?: string;
  seriesName?: string;
  data: {name: string; y: number}[];
  tooltipFormat?: string;
  showDataLabels?: boolean;
  colors?: string[];
}

const HighPieChart = ({
  title = 'Pie Chart',
  seriesName = 'Data',
  data,
  tooltipFormat = '{series.name}: <b>{point.percentage:.1f}%</b>',
  showDataLabels = false,
  colors = ['#FF5733', '#33FF57']
}: HighPieChartProps) => {
  const options: Highcharts.Options = {
    chart: {
      type: 'pie',
      height: '220px',
      spacing: [10, 10, 10, 10]
    },
    colors,
    credits: {enabled: false},
    title: {
      text: title,
      align: 'left',
      style: {
        fontSize: '0.8125rem'
      }
    },
    tooltip: {
      pointFormat: tooltipFormat
    },
    accessibility: {
      point: {
        valueSuffix: '%'
      },
      enabled: false
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: showDataLabels,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %'
        }
      },
      series: {
        animation: false
      }
    },
    series: [
      {
        name: seriesName,
        type: 'pie',
        data
      }
    ]
  };
  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default HighPieChart;
