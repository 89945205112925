import {deepEqual} from 'fast-equals';
import React, {useEffect, useMemo, useReducer} from 'react';

import {NotificationManager} from 'react-notifications';

import {useAppContext} from '../../app/context';
import {BessStatusIndicator} from '../../components/BessStatusIndicator';
import {
  getPeriodRangeForTimezone,
  getRangeForPeriod,
  Period,
  PeriodRoundingMode,
  PeriodSettings
} from '../../components/PeriodSelector';
import SelectLocationButton from '../../components/SelectLocationButton';
import {migrateTableSettings, SortOrder} from '../../components/Table';
import {Alert, AlertDescription} from '../../components/ui/alert';
import {Button} from '../../components/ui/button';
import {TabsList, Tabs, TabsContent, TabsTrigger} from '../../components/ui/tabs';
import {useLiveBessPower} from '../../livedata/LiveBessStatus';
import {UserRights} from '../../models/AuthUser';
import {IBatteryEnergyData} from '../../models/BessReading';
import {BessUnit} from '../../models/BessUnit';
import {ICardSettingsWithTable} from '../../models/CardSettings';
import {DeviceType} from '../../models/DeviceType';
import {ILocation, isReadOnly} from '../../models/Location';
import {IOverloadProtectionConfiguration} from '../../models/OverloadProtection';
import {
  IBaseSmartDevice,
  ICapacityProtectionConfiguration,
  IConfigurationPropertyValue
} from '../../models/SmartDevice';
import {Interval} from '../../models/UsageValue';
import {None} from '../../utils/Arrays';
import {dayjs} from '../../utils/date-util';
import {useChildLocations, useLocation, useLocationUsingAPI, useSmartDevices} from '../../utils/FunctionalData';
import {useCardLoader} from '../../utils/Hooks';
import {T} from '../../utils/Internationalization';
import {TranslationKey} from '../../utils/TranslationTerms';
import {CardCategory, CardLocationAwareness, CardTypeKey, ICardProps, ICardType} from '../CardType';
import {useCardLocation, useUser} from '../CardUtils';
import {CardActions} from '../components';
import {CardView, cardViewProps, CustomActions} from '../components/CardView';

import {BessAccessContext} from './BessAccessContext';
import {getSpecificationData} from './data/specificationData';
import {getModesAndTreshold} from './data/usageConfigurationData';
import {
  bessCapacity,
  BessData,
  BessHealthLimit,
  bessMaxChargeSpeed,
  bessMaxDischargeSpeed,
  bessMaxInverterPower,
  bessMaxStateOfCharge,
  bessMaxSurplusReserveStateOfCharge,
  bessMinPeakShavingStateOfCharge,
  bessMinStateOfCharge,
  BessModeType,
  bessModi,
  BessSpecificationData,
  BessTreshold,
  BessUsageConfigurationData,
  DEFAULT_BESS_DATA_STATE
} from './models/BessUnitConfiguration.model';
import AuditTrailSection from './sections/AuditTrailSection';
import HistoricSection from './sections/HistoricSection';
import LiveSection from './sections/LiveSection';
import LogbookSection from './sections/LogbookSection';
import PerformanceSection from './sections/PerformanceSection';
import SlaSection from './sections/SlaSection';
import SpecificationSection from './sections/SpecificationSection';
import UsageConfigurationSection from './sections/UsageConfigurationSection';

interface SmappeeBessSettings extends ICardSettingsWithTable {
  period: PeriodSettings;
}

const bessTabs = [
  // 'performance', // wait with this tab
  'historic', // wait with this tab
  'live',
  'usageConfiguration',
  // 'logbook', // wait with this tab
  // 'auditTrail', // wait with this tab
  // 'sla', // wait with this tab, phrase key: T('bess.tab.sla')
  'specification' // @todo: rename this later to 'Commissioning'
];

const capacityProtectionConfigReducer = (
  state: ICapacityProtectionConfiguration,
  newState: Partial<ICapacityProtectionConfiguration>
) => {
  return {...state, ...newState};
};

const initialCapacityProtectionConfiguration: ICapacityProtectionConfiguration = {
  active: false,
  capacityMaximumPower: 0,
  capacitySuggestedPower: 0
};

export const noop = () => {};

const SmappeeBess = (props: ICardProps<SmappeeBessSettings>) => {
  const {fetch, settings, updateSettings} = props;
  const [loadingState, setLoadingState] = React.useState<'saving' | 'idle'>('idle');
  const [saveError, setSaveError] = React.useState<string | undefined>();
  const [isDirty, setIsDirty] = React.useState(false);
  const user = useUser();

  // Data loading
  const locationSummary = useCardLocation(settings);
  const locationId = locationSummary?.id;
  const {api} = useAppContext();
  const [location] = useLocation(fetch, locationSummary?.id);
  const isReadOnlyUser = !user.isServiceDesk();
  const parentId = location?.parentId;
  const [loadedLocation] = useLocationUsingAPI(api, parentId);
  const [parentLocation, setParentLocation] = React.useState<ILocation | undefined>();
  const timeZoneId = parentLocation?.timeZoneId || 'Europe/Brussels';
  const deviceType = locationSummary?.deviceType as DeviceType;
  const uuid = locationSummary?.uuid as string;
  const serialNumber = locationSummary?.serialNumber as string;
  const [smartDevices, refreshSmartDevices] = useSmartDevices(fetch, locationId);
  const bessDevice = smartDevices?.find(d => d.type.name === 'smappee-bess');
  const deviceUUID = bessDevice?.uuid;
  const [childLocations] = useChildLocations(fetch, location);
  const bessChildren = useMemo(() => childLocations.filter(l => l.deviceType === DeviceType.Bess), [childLocations]);

  const markFormDirty = React.useCallback(() => {
    setIsDirty(true);
  }, []);

  const [highLevelConfiguration, refreshHighLevelConfig] = useCardLoader(
    api => {
      if (parentId === undefined) {
        return Promise.resolve(undefined);
      } else {
        return api.getHighLevelConfiguration(parentId);
      }
    },
    [parentId],
    T('phasorDisplay.loading.configuration'),
    undefined
  );

  const [maximumLoadConfiguration, refreshMaximumLoadConfiguration] = useCardLoader(
    api => {
      if (parentId === undefined) {
        return Promise.resolve(undefined);
      } else {
        return api.getOverloadProtection(parentId);
      }
    },
    [parentId],
    'maxLoadConfiguration',
    undefined
  );

  const isLocalProductionAvailable = useMemo(() => {
    return highLevelConfiguration?.measurements.find(m => m.type === 'PRODUCTION')?.type === 'PRODUCTION';
  }, [highLevelConfiguration?.measurements]);

  const actualUsageConfigurationData = useMemo(() => {
    if (!bessDevice) return;
    return getModesAndTreshold(bessDevice);
  }, [bessDevice]);

  const [bessFormData, setBessFormData] = React.useState<BessData>(DEFAULT_BESS_DATA_STATE);
  useEffect(() => setParentLocation(loadedLocation), [loadedLocation]);

  useEffect(() => {
    if (!actualUsageConfigurationData) return;
    if (!actualUsageConfigurationData.modes && !actualUsageConfigurationData.treshold) return;
    const upperHealthValue = actualUsageConfigurationData.treshold.upperHealthStatus
      ? +actualUsageConfigurationData.treshold.upperHealthStatus
      : 0;
    const lowerHealthValue = actualUsageConfigurationData.treshold.lowerHealthStatus
      ? +actualUsageConfigurationData.treshold.lowerHealthStatus
      : 0;
    const surplusReserveValue = actualUsageConfigurationData.treshold.surplusReserve
      ? +actualUsageConfigurationData.treshold.surplusReserve
      : 0;
    const peakShavingSafetyValue = actualUsageConfigurationData.treshold.peakShavingSafety
      ? +actualUsageConfigurationData.treshold.peakShavingSafety
      : 0;
    setBessFormData(prevData => ({
      ...prevData,
      usageConfiguration: {
        ...prevData.usageConfiguration,
        modes: (actualUsageConfigurationData.modes as BessModeType[]) || ([] as unknown as BessModeType),
        localProductionAvailable: isLocalProductionAvailable,
        treshold: {
          upperHealthStatus: upperHealthValue,
          lowerHealthStatus: lowerHealthValue,
          surplusReserve: surplusReserveValue,
          peakShavingSafety: peakShavingSafetyValue
        }
      }
    }));
  }, [actualUsageConfigurationData, isLocalProductionAvailable]);

  const bessUnit: BessUnit = {
    data: {type: deviceType, supportsMID: false, serialNumber: locationSummary?.serialNumber}
  };

  const [liveData, status] = useLiveBessPower(uuid, bessUnit);
  const [{active, capacityMaximumPower, capacitySuggestedPower}, setCapacityConfiguration] = useReducer(
    capacityProtectionConfigReducer,
    initialCapacityProtectionConfiguration
  );

  useEffect(() => {
    async function fetchCapacitySettings() {
      if (!parentId) return;
      const configuration = await api.getCapacityProtection(parentId);

      setCapacityConfiguration(configuration);
    }

    fetchCapacitySettings();
  }, [api, parentId]);

  const specificationData = useMemo<BessSpecificationData | undefined>(() => {
    if (!bessDevice || !locationSummary) {
      return undefined;
    }
    return getSpecificationData(bessDevice, locationSummary, maximumLoadConfiguration, capacityMaximumPower);
  }, [bessDevice, locationSummary, maximumLoadConfiguration, capacityMaximumPower]);

  // Update bessFormData.specification when specificationData changes
  useEffect(() => {
    if (specificationData) {
      setBessFormData(prevData => {
        if (!deepEqual(prevData.specification, specificationData)) {
          return {
            ...prevData,
            specification: specificationData
          };
        }
        return prevData;
      });
    }
  }, [specificationData]);

  useEffect(() => {
    if (isDirty) {
      NotificationManager.warning(
        T('bess.notification.unsavedChanges.body'),
        T('bess.notification.unsavedChanges.title'),
        5000
      );
    }
  }, [isDirty]);

  // load charts data for HistoricSection
  const [historicData] = useCardLoader(
    async api => {
      if (!locationId) return;
      if (!timeZoneId) return;
      if (!deviceUUID) return;
      updateSettings({period: settings.period});

      try {
        const retention = await api.getRetentionPolicy(locationId);
        const activePeriod = getPeriodRangeForTimezone(
          settings.period,
          timeZoneId,
          retention,
          PeriodRoundingMode.EXCLUSIVE
        );
        const bessItems = await api.bess.getPeriodicalBessEnergyData(locationId, deviceUUID, activePeriod);
        const parsedData: IBatteryEnergyData[] = bessItems.map(item => ({
          timestamp: item.timestamp,
          G2BData: item.values.find(val => val.name === 'G2BEnergy')?.value || 0,
          B2GData: item.values.find(val => val.name === 'B2GEnergy')?.value || 0,
          S2BData: item.values.find(val => val.name === 'S2BEnergy')?.value || 0,
          B2LData: item.values.find(val => val.name === 'B2LEnergy')?.value || 0,
          G2LData: item.values.find(val => val.name === 'G2LEnergy')?.value || 0,
          S2GData: item.values.find(val => val.name === 'S2GEnergy')?.value || 0,
          SoCData: item.values.find(val => val.name === 'SoC')?.value || 0
        }));

        return parsedData;
      } catch (error) {
        console.error('Error fetching historic data', error);
        return None;
      }
    },
    [settings.period, deviceUUID],
    'historic data',
    None
  );

  // update a whole section (form data from specific tabs)
  const handleSectionUpdate = <T extends keyof BessData>(section: T, updatedData: BessData[T]) => {
    setBessFormData(prev => ({
      ...prev,
      [section]: updatedData
    }));
  };

  // Property Saving functions
  const saveCapacityConfig = async (parentId: number, capacityConfig: ICapacityProtectionConfiguration) => {
    if (!parentId || !capacityConfig.capacityMaximumPower) return;
    await api.updateCapacityProtection(parentId, capacityConfig);
  };

  const saveMaximumCurrent = async (parentId: number, overloadConfig: IOverloadProtectionConfiguration) => {
    if (!parentId || !overloadConfig) return;
    await api.updateOverloadProtection(parentId, overloadConfig);
  };

  const savePropertiesInBatch = async (
    locationId: number,
    smartDevice: IBaseSmartDevice,
    properties: Record<string, IConfigurationPropertyValue>
  ) => {
    // eslint-disable-next-line prettier/prettier
    if (!locationId || !smartDevice || !serialNumber || serialNumber.length === 0 || Object.keys(properties).length === 0) return;
    try {
      await api.smartDevices.updateBessInBatch(locationId, smartDevice, serialNumber, properties);
      return true;
    } catch (error) {
      console.error('Failed to save properties in batch', error);
      throw error;
    }
  };

  const handleSave = async (data: BessData) => {
    if (!locationId || !parentId || !bessDevice) return;

    setLoadingState('saving');
    setSaveError(undefined);

    // GRID
    const capacityConfig: ICapacityProtectionConfiguration = {
      active: true, // this needs to be set to true for updating!
      capacityMaximumPower: data?.specification?.capacityMaximumPower || undefined
    };

    const overloadConfig: IOverloadProtectionConfiguration = {
      active: true,
      maximumLoad: data?.specification?.maxCurrent || undefined
    };

    const propertiesToSave: Record<string, IConfigurationPropertyValue> = {
      [bessCapacity]: {BigDecimal: data?.specification?.maxCapacity || 0},
      [bessMaxChargeSpeed]: {BigDecimal: data?.specification?.maxChargeSpeed || 0},
      [bessMaxDischargeSpeed]: {BigDecimal: data?.specification?.maxDischargeSpeed || 0},
      [bessMaxInverterPower]: {BigDecimal: data?.specification?.maxInverterPower || 0},
      [bessMaxStateOfCharge]: {BigDecimal: data?.usageConfiguration?.treshold?.upperHealthStatus || 0},
      [bessMinStateOfCharge]: {BigDecimal: data?.usageConfiguration?.treshold?.lowerHealthStatus || 0},
      [bessMaxSurplusReserveStateOfCharge]: {BigDecimal: data?.usageConfiguration?.treshold?.surplusReserve || 0},
      [bessMinPeakShavingStateOfCharge]: {BigDecimal: data?.usageConfiguration?.treshold?.peakShavingSafety || 0},
      [bessModi]: {List: data?.usageConfiguration?.modes?.map(mode => ({String: mode}))}
    };

    try {
      const gridSavingFunctions = [
        // Grid saving properties:
        () => saveCapacityConfig(parentId, capacityConfig),
        () => saveMaximumCurrent(parentId, overloadConfig)
      ];
      for (const saveFunction of gridSavingFunctions) {
        await saveFunction(); // Execute each save function sequentially
      }
      await savePropertiesInBatch(locationId, bessDevice, propertiesToSave);
      NotificationManager.success(
        T('bess.notification.save.success.body'),
        T('bess.notification.save.success.title'),
        5000
      );
      setIsDirty(false);
    } catch (error) {
      const errMsg = error as unknown as Error;
      setSaveError(errMsg.message || T('bess.errors.save'));
    } finally {
      setLoadingState('idle');
    }
  };

  const handleSectionChange = <T extends keyof BessData, K extends keyof BessData[T]>(
    section: T,
    field: K,
    value: BessData[T][K]
  ) => {
    // Deal with nested (object) fields (e.g. healthLimits in SpecificationSection)
    // and regular fields
    setBessFormData(prev => {
      const updatedSection = {...prev[section]};

      if (typeof updatedSection[field] === 'object' && !Array.isArray(value) && updatedSection[field] !== null) {
        updatedSection[field] = {...(updatedSection[field] as object), ...value};
      } else {
        updatedSection[field] = value;
      }

      if (section === 'specification' && field === 'healthLimits') {
        return {
          ...prev,
          specification: updatedSection as BessSpecificationData,
          usageConfiguration: {
            ...prev.usageConfiguration,
            treshold: {
              ...prev.usageConfiguration.treshold,
              upperHealthStatus: (value as BessHealthLimit).upperHealthStatus,
              lowerHealthStatus: (value as BessHealthLimit).lowerHealthStatus
            }
          }
        };
      } else if (section === 'usageConfiguration' && field === 'treshold') {
        return {
          ...prev,
          usageConfiguration: updatedSection as BessUsageConfigurationData,
          specification: {
            ...prev.specification,
            healthLimits: {
              upperHealthStatus: (value as BessTreshold).upperHealthStatus,
              lowerHealthStatus: (value as BessTreshold).lowerHealthStatus
            }
          }
        };
      }

      return {
        ...prev,
        [section]: updatedSection
      };
    });
  };

  const actions: CustomActions = state => (
    <CardActions>
      {state.ready && status !== undefined && <BessStatusIndicator status={status} />}
      <span className="tw-flex-1" />
      {isDirty && (
        <Alert variant="warning" className="!tw-w-auto !tw-max-w-[20rem] !tw-max-h-[2.5rem] !tw-py-2">
          <AlertDescription>{T('bess.notification.unsavedChanges.body')}</AlertDescription>
        </Alert>
      )}
      <div className="tw-flex tw-justify-end tw-items-center">
        <Button variant="primary_default" onClick={() => handleSave(bessFormData)}>
          {T('generic.save')}
        </Button>
      </div>
    </CardActions>
  );

  let error: string | JSX.Element | undefined;
  if (location && location.deviceType !== DeviceType.Bess) {
    error = (
      <span>
        {T(bessChildren.length > 0 ? 'bess.errors.invalidLocation.hasChildren' : 'bess.errors.invalidLocation')}
        <br />
        {bessChildren.map((location, index) => (
          <span key={location.id}>
            {index > 0 && <>&middot;</>}
            <SelectLocationButton location={location} />
          </span>
        ))}
      </span>
    );
  }

  return (
    <CardView error={error} actions={actions} {...cardViewProps(props)}>
      <BessAccessContext.Provider value={isReadOnlyUser}>
        <Tabs defaultValue="live" className="tw-w-full">
          <TabsList className="!tw-w-full !tw-max-w-full !tw-overflow-x-auto !tw-whitespace-nowrap !tw-p-0 !tw-justify-start">
            {bessTabs.map(title => (
              <TabsTrigger key={title} value={title}>
                {T(`bess.tab.${title}` as TranslationKey)}
              </TabsTrigger>
            ))}
          </TabsList>
          {bessTabs.map(tab => (
            <TabsContent key={tab} value={tab} className="tw-mt-6">
              {tab === 'live' && (
                <LiveSection
                  liveData={liveData}
                  maxCapacity={bessFormData.specification.maxCapacity}
                  data={bessFormData.live}
                  markFormDirty={markFormDirty}
                  onChange={(field, value) => handleSectionChange('live', field, value)}
                />
              )}
              {tab === 'historic' && (
                <HistoricSection
                  data={historicData}
                  period={settings.period}
                  onPeriodChange={period => updateSettings({period})}
                />
              )}
              {tab === 'logbook' && <LogbookSection />}
              {tab === 'usageConfiguration' && (
                <UsageConfigurationSection
                  data={bessFormData.usageConfiguration}
                  markFormDirty={markFormDirty}
                  onChange={(field, value) => handleSectionChange('usageConfiguration', field, value)}
                />
              )}
              {tab === 'specification' && (
                <SpecificationSection
                  data={bessFormData.specification}
                  markFormDirty={markFormDirty}
                  onChange={(field, value) => handleSectionChange('specification', field, value)}
                />
              )}
              {tab === 'auditTrail' && <AuditTrailSection />}
              {tab === 'sla' && <SlaSection />}
              {tab === 'performance' && <PerformanceSection />}
            </TabsContent>
          ))}
        </Tabs>
      </BessAccessContext.Provider>
    </CardView>
  );
};

const DEFAULT_CARD_SETTINGS: SmappeeBessSettings = {
  table: {
    pageSize: 20,
    sortColumn: 'order',
    sortOrder: SortOrder.ASCENDING,
    columns: [
      {name: 'order', visible: false},
      {name: 'key', visible: true}
    ]
  },
  period: {
    interval: Interval.MINUTES_5,
    period: Period.TODAY
  }
};

const CARD: ICardType<SmappeeBessSettings> = {
  type: CardTypeKey.SmappeeBess,
  title: 'smappeeBess.title',
  description: 'smappeeBess.description',
  categories: [CardCategory.CONFIGURATION, CardCategory.BESS],
  rights: UserRights.User,
  width: 4,
  height: 3,
  defaultSettings: DEFAULT_CARD_SETTINGS,
  locationAware: CardLocationAwareness.Unaware,
  upgradeSettings: migrateTableSettings('table', DEFAULT_CARD_SETTINGS.table),
  cardClass: SmappeeBess
};
export default CARD;
