import React, {useState} from 'react';

import {Button as RsButton} from '../../components/bootstrap';
import {Icons} from '../../components/Icon';
import {SelectInput} from '../../components/ui/select';
import {useLiveSwitchStatus} from '../../livedata/LiveSwitchStatus';
import {IDevice, IDeviceOutputConfiguration, IDeviceOutputControl} from '../../models/Device';
import {ISmartDevice, SmartDeviceConnectionStatus, SmartDeviceTypeCategory} from '../../models/SmartDevice';
import {ISwitch, SwitchStatus, PlugType} from '../../models/Switch';
import {T} from '../../utils/Internationalization';

import styles from './AnySmartDevice.module.scss';

export interface AnySmartDevice {
  key: string;
  name: string;
  connectionStatus: SmartDeviceConnectionStatus;
  isEditable: boolean;
  canBeInitializing: boolean;

  renderRowActions: (readOnly: boolean) => JSX.Element;
  hasConnectionStatus: () => boolean;
}

export class SmartDeviceWrapper implements AnySmartDevice {
  key: string;
  name: string;
  connectionStatus: SmartDeviceConnectionStatus;
  isEditable: boolean = true;
  canBeInitializing: boolean;

  device: ISmartDevice;
  onClickedEdit: (device: ISmartDevice) => void;
  onClickedDelete: (device: ISmartDevice) => void;

  constructor(
    device: ISmartDevice,
    onClickedEdit: (device: ISmartDevice) => void,
    onClickedDelete: (device: ISmartDevice) => void
  ) {
    this.key = `SD${device.id}`;
    this.name = device.name;
    this.connectionStatus = device.connectionStatus;
    this.canBeInitializing =
      device.type.category === SmartDeviceTypeCategory.CarCharger && device.type.name !== 'acchargingcontroller';

    this.device = device;
    this.onClickedEdit = onClickedEdit;
    this.onClickedDelete = onClickedDelete;
  }

  handleClickedEdit = () => {
    this.onClickedEdit(this.device);
  };

  handleClickedDelete = () => {
    this.onClickedDelete(this.device);
  };

  renderRowActions = (readOnly: boolean) => {
    // forcing readonly because of this ticket: https://smappee.atlassian.net/browse/SW-11841

    return <div />;
  };

  hasConnectionStatus() {
    return this.device.type.category === SmartDeviceTypeCategory.CarCharger;
  }
}

function getSwitchStatus(device: ISwitch) {
  if (device.status === undefined) return SmartDeviceConnectionStatus.Unknown;

  switch (device.status) {
    case SwitchStatus.Connected:
      return SmartDeviceConnectionStatus.Connected;
    case SwitchStatus.Disconnected:
      return SmartDeviceConnectionStatus.Disconnected;
    case SwitchStatus.Unreachable:
      return SmartDeviceConnectionStatus.Unreachable;
    default:
      return SmartDeviceConnectionStatus.Unknown;
  }
}

export const enum SmartDeviceAction {
  On = 'ON',
  Off = 'OFF'
}

export class SwitchDeviceWrapper implements AnySmartDevice {
  key: string;
  name: string;
  connectionStatus: SmartDeviceConnectionStatus;
  isEditable: boolean = false;
  canBeInitializing: boolean = false;

  gatewaySerial: string | undefined;
  device: ISwitch;
  onChange: (device: ISwitch, action: SmartDeviceAction) => void;

  constructor(
    gatewaySerial: string | undefined,
    device: ISwitch,
    onChange: (device: ISwitch, action: SmartDeviceAction) => void
  ) {
    this.key = `SW${device.id}`;
    this.name = device.name;
    this.connectionStatus = getSwitchStatus(device);
    this.gatewaySerial = gatewaySerial;
    this.device = device;
    this.onChange = onChange;
  }

  handleChangedControl = (e: React.SyntheticEvent<HTMLInputElement>) => {
    this.onChange(this.device, e.currentTarget.value as SmartDeviceAction);
  };

  handleClickedOff = () => {
    this.onChange(this.device, SmartDeviceAction.Off);
  };

  handleClickedOn = () => {
    this.onChange(this.device, SmartDeviceAction.On);
  };

  renderRowActions = (readOnly: boolean) => {
    if (this.device.type === PlugType.Switch) {
      return (
        <SwitchOnOff
          gatewaySerial={this.gatewaySerial}
          readOnly={readOnly}
          storedState={(this.device.lastAction || 0).toString()}
          device={this.device}
          onChange={this.onChange}
        />
      );
    } else {
      if (readOnly) return <div />;

      return (
        <>
          <RsButton onClick={this.handleClickedOff} style={{marginRight: 10}}>
            {T('switch.action.off')}
          </RsButton>
          <RsButton onClick={this.handleClickedOn}>{T('switch.action.on')}</RsButton>
        </>
      );
    }
  };

  hasConnectionStatus() {
    return true;
  }
}

interface SwitchOnOffProps {
  gatewaySerial: string | undefined;
  readOnly: boolean;
  storedState: string;
  device: ISwitch;
  onChange: (device: ISwitch, action: SmartDeviceAction) => void;
}

function SwitchOnOff(props: SwitchOnOffProps) {
  const {gatewaySerial, readOnly, storedState, device, onChange} = props;
  const liveStatus = useLiveSwitchStatus(gatewaySerial, device.stateUpdateChannel);
  const liveState = liveStatus && (liveStatus.value === 'ON' ? SmartDeviceAction.On : SmartDeviceAction.Off);
  const currentState = liveState || storedState;

  const [desiredState, setDesiredState] = useState<string>();
  const state = desiredState || currentState;

  const handleChangedControl = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const newState = e.currentTarget.value;
    setDesiredState(newState);
    onChange(device, newState as SmartDeviceAction);
  };

  /*useEffect(() => {
    if (desiredState === undefined)
      return;
    
    const timeout = setTimeout(() => setDesiredState(undefined), 10000);
    return () => clearTimeout(timeout);
  }, [desiredState]);*/

  return (
    <>
      <RsButton
        onClick={() => onChange(device, SmartDeviceAction.Off)}
        active={desiredState === SmartDeviceAction.Off}
        color={liveState === SmartDeviceAction.Off ? 'primary' : undefined}
        style={{marginRight: 10}}
      >
        {T('switch.action.off')}
      </RsButton>
      <RsButton
        onClick={() => onChange(device, SmartDeviceAction.On)}
        active={desiredState === SmartDeviceAction.On}
        color={liveState === SmartDeviceAction.On ? 'primary' : undefined}
      >
        {T('switch.action.on')}
      </RsButton>
    </>
  );
  /*  <Input type='select' value={state} onChange={handleChangedControl} disabled={readOnly}>
      <option value={SmartDeviceAction.Off}>{T('switch.action.off')}</option>
      <option value={SmartDeviceAction.On}>{T('switch.action.on')}</option>
    </Input>
  </>;*/
}

export type SmartDeviceChangeHandler = (module: IDevice, control: IDeviceOutputControl, option: string) => void;
export class OutputModuleSmartDevice implements AnySmartDevice {
  key: string;
  name: string;
  connectionStatus: SmartDeviceConnectionStatus;
  isEditable: boolean = false;
  updatedState?: string;
  canBeInitializing: boolean = false;

  module: IDevice;
  control: IDeviceOutputControl;
  onChange: SmartDeviceChangeHandler;

  constructor(
    module: IDevice,
    control: IDeviceOutputControl,
    onChange: SmartDeviceChangeHandler,
    updatedState?: string
  ) {
    this.key = `OM${module.serialNumber}C${control.id}`;
    this.name = control.name;
    this.connectionStatus = module.active
      ? SmartDeviceConnectionStatus.Connected
      : SmartDeviceConnectionStatus.Disconnected;
    this.updatedState = updatedState;

    this.module = module;
    this.control = control;
    this.onChange = onChange;
  }

  handleChangedControl = (value: string) => {
    this.onChange(this.module, this.control, value);
  };

  handleChange = (configuration: IDeviceOutputConfiguration) => {
    this.onChange(this.module, this.control, configuration.id);
  };

  renderRowActions = (readOnly: boolean) => {
    const currentOption = this.updatedState
      ? this.control.configurations.find(c => c.id === this.updatedState)
      : this.control.configurations.find(c => c.current);

    if (this.control.configurations.length === 2) {
      const a = this.control.configurations[0];
      const b = this.control.configurations[1];
      const nameA = a.name;
      const nameB = b.name;

      if (nameA === 'UP' && nameB === 'DOWN') {
        return (
          <>
            <RsButton
              size="sm"
              outline
              className={styles.smallButton}
              active={currentOption === a}
              onClick={() => this.handleChange(a)}
            >
              {Icons.Up}
            </RsButton>
            <RsButton
              size="sm"
              outline
              className={styles.smallButton}
              active={currentOption === b}
              onClick={() => this.handleChange(b)}
            >
              {Icons.Down}
            </RsButton>
          </>
        );
      } else if (nameA === 'LEFT' && nameB === 'RIGHT') {
        return (
          <>
            <RsButton
              size="sm"
              outline
              className={styles.smallButton}
              active={currentOption === a}
              onClick={() => this.handleChange(a)}
            >
              {Icons.Left}
            </RsButton>
            <RsButton
              size="sm"
              outline
              className={styles.smallButton}
              active={currentOption === b}
              onClick={() => this.handleChange(b)}
            >
              {Icons.Right}
            </RsButton>
          </>
        );
      } else if (nameA === 'START' && nameB === 'STOP') {
        return (
          <>
            <RsButton
              size="sm"
              outline
              className={styles.smallButton2}
              active={currentOption === a}
              onClick={() => this.handleChange(a)}
            >
              {Icons.Play}
            </RsButton>
            <RsButton
              size="sm"
              outline
              className={styles.smallButton2}
              active={currentOption === b}
              onClick={() => this.handleChange(b)}
            >
              {Icons.Stop}
            </RsButton>
          </>
        );
      } else if (nameA === 'ON' && nameB === 'OFF') {
        return (
          <>
            <RsButton
              size="sm"
              outline
              className={styles.smallButton2}
              active={currentOption === a}
              onClick={() => this.handleChange(a)}
            >
              On
            </RsButton>
            <RsButton
              size="sm"
              outline
              className={styles.smallButton2}
              active={currentOption === b}
              onClick={() => this.handleChange(b)}
            >
              Off
            </RsButton>
          </>
        );
      }
    } else if (this.control.configurations.length === 4) {
      const a = this.control.configurations[0];
      const b = this.control.configurations[1];
      const c = this.control.configurations[2];
      const d = this.control.configurations[3];
      const nameA = a.name;
      const nameB = b.name;
      const nameC = c.name;
      const nameD = d.name;
      if (nameA === 'ONE' && nameB === 'TWO' && nameC === 'THREE' && nameD === 'FOUR') {
        return (
          <>
            <RsButton
              size="sm"
              outline
              className={styles.smallButton2}
              active={currentOption === a}
              onClick={() => this.handleChange(a)}
            >
              1
            </RsButton>
            <RsButton
              size="sm"
              outline
              className={styles.smallButton2}
              active={currentOption === b}
              onClick={() => this.handleChange(b)}
            >
              2
            </RsButton>
            <RsButton
              size="sm"
              outline
              className={styles.smallButton2}
              active={currentOption === c}
              onClick={() => this.handleChange(c)}
            >
              3
            </RsButton>
            <RsButton
              size="sm"
              outline
              className={styles.smallButton2}
              active={currentOption === d}
              onClick={() => this.handleChange(d)}
            >
              4
            </RsButton>
          </>
        );
      }
    }

    return (
      <SelectInput
        value={this.updatedState || (currentOption ? currentOption.id : '')}
        onChange={this.handleChangedControl}
        disabled={readOnly}
        options={this.control.configurations.map(option => ({
          label: option.name,
          value: option.id
        }))}
      />
    );
  };

  hasConnectionStatus() {
    return true;
  }
}
