import React, {useMemo, useState} from 'react';

import {NotificationManager} from 'react-notifications';

import {useAppContext} from '../../app/context';
import {Alert, FormGroup, FormText, Label, SingleActionModal} from '../../components/bootstrap';

import {CurrencyInput} from '../../components/CurrencyInput';
import {useTextInput} from '../../components/inputs/TextInput';
import {Schedule} from '../../components/Schedule';
import {TimezoneInput} from '../../components/TimezoneInput';
import {SelectInput} from '../../components/ui/select';
import {ICurrency} from '../../core/currencies';
import {IPromiseModalProps, usePromiseModal} from '../../modals/PromiseModal';
import {
  getLocationTypeName,
  ILocationSummary,
  isChargingParent,
  isEVWallLocation,
  LOCATION_TYPES,
  LocationType
} from '../../models/Location';
import {IOrganization} from '../../models/Organization';
import {ITimezone} from '../../models/Timezone';
import {useLocation} from '../../utils/FunctionalData';
import {T} from '../../utils/Internationalization';
import {TimeRange} from '../../utils/OpeningHours';
import {validateNumber, validateRequired} from '../../utils/Validation';

interface EditLocationProps extends IPromiseModalProps<boolean> {
  location: ILocationSummary;
  operator?: IOrganization;
}

const EditLocation = (props: EditLocationProps) => {
  const {
    location: {id}
  } = props;
  const {location} = props;
  const [openingHoursError, setOpeningHoursError] = React.useState<string | undefined>();
  const [detailedLocation] = useLocation(props.fetch, id);
  const canSetOpeningHours =
    detailedLocation && (isChargingParent(detailedLocation?.functionType) || isEVWallLocation(detailedLocation));

  const [isOpen, resolve] = usePromiseModal(props);
  const {api} = useAppContext();

  const [nameInput, name] = useTextInput(
    'name',
    T('locations.field.name'),
    location.name || '',
    validateRequired,
    undefined,
    {autoFocus: true}
  );
  const [type, setType] = useState(LocationType.Residential);
  const [currency, setCurrency] = useState(location.electricityCurrency);
  const [openingsHours, setOpeningsHours] = React.useState<TimeRange[]>(detailedLocation?.openingHours || []);

  const [latitudeInput, latitude] = useTextInput(
    'latitude',
    T('locations.field.latitude'),
    location.latitude === undefined ? '' : location.latitude.toFixed(6),
    validateNumber,
    undefined,
    {placeholder: T('locations.field.notSpecified'), optional: true}
  );
  const [longitudeInput, longitude] = useTextInput(
    'longitude',
    T('locations.field.longitude'),
    location.longitude === undefined ? '' : location.longitude.toFixed(6),
    validateNumber,
    undefined,
    {placeholder: T('locations.field.notSpecified'), optional: true}
  );
  const [timeZone, setTimeZone] = useState(location.timeZoneId);
  const timeZoneChanged = timeZone !== location.timeZoneId;

  const handleClickSave = async () => {
    // Create location object
    const location = {
      name,
      type,
      electricityCurrency: currency,
      openingsHours,
      timeZone,
      latitude: latitude === '' ? undefined : parseFloat(latitude),
      longitude: longitude === '' ? undefined : parseFloat(longitude)
    };

    // Merge location object

    try {
      setOpeningHoursError(undefined);

      await Promise.all([
        api.updateLocation(props.location.id, location),
        ...(canSetOpeningHours ? [api.updateLocationOpeningHours(props.location.id, openingsHours)] : [])
      ]);

      resolve(true);
      NotificationManager.success(T('locationConfiguration.update.success'));
    } catch (e: any) {
      if (e?.code === 'invalid.openinghours') {
        return setOpeningHoursError(T('invalid.openinghours'));
      }

      NotificationManager.error(T('locationConfiguration.update.failed'));
    }
  };

  const handleTypeChanged = (value: string) => setType(value as LocationType);

  const handleCurrencyChanged = (currency: ICurrency) => setCurrency(currency.code);

  const handleTimezoneSelected = (timezone: ITimezone) => setTimeZone(timezone.id);

  const canModifyTimeZone = true;
  const typeOptions = useMemo(() => {
    return LOCATION_TYPES.map(type => ({
      label: getLocationTypeName(type),
      value: type
    }));
  }, []);

  React.useEffect(() => {
    if (detailedLocation) {
      setType(detailedLocation.type);
      setCurrency(detailedLocation.electricityCurrency);
      setTimeZone(detailedLocation.timeZoneId);
      setOpeningsHours(detailedLocation.openingHours ?? []);
    }
  }, [detailedLocation]);

  const locationForm = (
    <div>
      {nameInput}

      <FormGroup>
        <Label>Type</Label>
        <SelectInput name="type" value={type} onChange={handleTypeChanged} options={typeOptions} />
      </FormGroup>

      <FormGroup>
        <Label>{T('locations.field.timezone')}</Label>
        <TimezoneInput
          api={api}
          value={timeZone || ''}
          onSelected={handleTimezoneSelected}
          disabled={!canModifyTimeZone}
          autoFocus={false}
        />
        <FormText>{T('locations.field.timezone.warning')}</FormText>
      </FormGroup>

      <FormGroup>
        <Label>{T('locations.field.currency')}</Label>
        <CurrencyInput value={currency || ''} onChange={handleCurrencyChanged} autoFocus={false} />
        <FormText __dangerouslySetInnerHTML={T('locations.field.currency.info')} />
      </FormGroup>

      {latitudeInput}
      {longitudeInput}

      {canSetOpeningHours ? (
        <>
          <h3 className="mt-4 mb-3">{T('locations.openinghours.title')}</h3>
          {openingHoursError && (
            <div className="mt-4 mb-2">
              <Alert color="danger" fixed={false}>
                {openingHoursError}
              </Alert>
            </div>
          )}
          <div className="border p-3">
            <Schedule name="openingHours" timeRange={openingsHours} onChange={setOpeningsHours} />
          </div>
        </>
      ) : null}

      {timeZoneChanged && (
        <Alert color="danger" fixed={false} style={{marginTop: '30px', marginBottom: '0'}}>
          {T('locations.field.timezone.warning2')}
        </Alert>
      )}
    </div>
  );

  return (
    <SingleActionModal
      isOpen={isOpen}
      onToggle={() => resolve(false)}
      title={T('locations.editLocation')}
      action={handleClickSave}
      actionText={T('locations.saveLocation')}
    >
      {locationForm}
    </SingleActionModal>
  );
};

export default EditLocation;
